import { Deserializable } from '../deserializable.model';

export class VatRate implements Deserializable {

  id: number;
  name: string;
  value: number;
  primary: boolean;
  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  //Implement tasks relationships here

  deserialize(input: any){
    Object.assign(this, input);

    return this;
  }
}
