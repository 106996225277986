import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {Site} from "../../../../shared/models/client/site.model";
import {SitesService} from "../../reactive/api/services/sites.service";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {SiteNotesService} from "../../reactive/api/services/site-notes.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegexPatterns} from "../../../../utility/helpers/regex-patterns";

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TenantComponent implements OnInit {
  _site: Site;

  form: FormGroup;

  isCollapsed = true;

  @Output() close = new EventEmitter<boolean>();

  constructor (private site_service: SitesService,
               private form_builder: FormBuilder,
               private site_note_service: SiteNotesService,
               private toastr_service: ToastrService,
               private regex_patterns: RegexPatterns,) {
  }

  ngOnInit () {
    this.site_service._site$.subscribe(data => {
      this._site = data;
    });
    this.setForm();
    this.onChanges();
  }

  setForm(){
    this.form = this.form_builder.group({
      tenant_name: [this._site.tenant_name],
      tenant_contact_number: [this._site.tenant_contact_number, Validators.pattern(this.regex_patterns.phone_number)],
      tenant_contact_email: [this._site.tenant_contact_email, Validators.pattern(this.regex_patterns.email)]
    });
  }

  update() {
    this.site_service.update(this._site).subscribe(data => {
      this.toastr_service.success('TENANT WAS UPDATED', 'Success');
    });
    this.onClose();
  }

  onChanges() {
    this.form.valueChanges.subscribe(values => {
      this._site.deserialize(values);
    });
  }

  onClose () {
    this.close.next(true);
  }
}
