import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ModalDirective } from 'ngx-bootstrap';
import { Subscription } from 'rxjs/Rx';
import { ClientAccount } from '../../../../shared/models/client/client-account.model';
import { Contract } from '../../../../shared/models/client/contract.model';
import { Site } from '../../../../shared/models/client/site.model';
import { Team } from '../../../../shared/models/teams/team.model';
import { Batch } from '../../../../shared/models/work-instruction/batch.model';
import { ToastrHelperService } from '../../../../utility/helpers/toastr-helper.service';
import { ClientsService } from '../../../admin/api/services/clients.service';
import { ContractsService } from '../../../admin/api/services/contracts.service';
import { TeamsService } from '../../../admin/api/services/teams.service';
import { SitesService } from '../../reactive/api/services/sites.service';
import { BatchService } from '../api/services/batch.service';

@Component({
  selector: 'app-batch-wizard',
  templateUrl: './batch-wizard.component.html',
  styleUrls: ['./batch-wizard.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BatchWizardComponent implements OnInit, OnDestroy {

  public scrollbarOptions = {axis: 'y', theme: 'minimal-dark', overflow: 'hidden'};

  public _is_create                        = true;
  public _teams: Team[]                    = [];
  public _client_accounts: ClientAccount[] = [];
  public _contracts: Contract[]            = [];
  public _cycle_lengths: number[]          = [];
  public _selected_teams: Team[]           = [];
  public _sites: Site[]                    = [];

  private _min_cycle_length = 2;
  private _max_cycle_length = 52;
  private _subscription: Subscription;

  public _form: FormGroup;
  public _batch: Batch;
  public _min_date: Date = new Date();

  @ViewChild('batchModal') modal: ModalDirective;
  @ViewChild('stepper') stepper: MatStepper;

  @Output() refreshTable = new EventEmitter<boolean>();

  constructor (private _fb: FormBuilder,
               private _batch_service: BatchService,
               private _client_account_service: ClientsService,
               private _contract_service: ContractsService,
               private _site_service: SitesService,
               private _team_service: TeamsService,
               private _toastr_helper_serivce: ToastrHelperService) {
    this._batch = new Batch();
    this._form  = this.setForm();
  }

  ngOnInit () {

    this._subscription = this._batch_service._batch$.subscribe(batch => this._batch = batch);
    this.setCycleLength();
  }

  ngOnDestroy () {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  show () {

    this._selected_teams = this._batch.teams || [];

    this._client_account_service.getClients().subscribe(data => this._client_accounts = data);

    if (this._batch.client_account) {
      this.onClientAccountSelect(this._batch.client_account);
    }

    if (this._batch.contract) {
      this.onContractSelect(this._batch.contract);
    }

    this._form = this.setForm();

    this.modal.show();
    this.onChanges();
  }

  hide () {
    this.reset();
    this.refreshTable.emit(true);
    this.modal.hide();
  }

  reset () {
    this._selected_teams  = [];
    this._contracts       = [];
    this._client_accounts = [];
    this._batch           = new Batch();
    this.stepper.reset();
  }

  store () {

    this.setBatchData();
    this._batch_service.store(this._batch).subscribe(data => this.onCreateOrUpdate(data));
    this.hide();
  }

  update () {

    this.setBatchData();
    this._batch_service.update(this._batch).subscribe(data => this.onCreateOrUpdate(data));
    this.hide();
  }

  onClientAccountSelect (client_account: ClientAccount) {
    this._contract_service.getContracts(client_account.account_id.toString()).subscribe(data => this._contracts = data);
    this._site_service.getSiteList(client_account.id).subscribe(data => this._sites = data);
  }

  onContractSelect (contract: Contract) {
    this._team_service.getTeamList(contract.id).subscribe(data => this._teams = data);
  }

  /**
   * @returns {FormGroup}
   */
  private setForm () {

    if (this._batch.start_date) {
      this._batch.start_date = new Date(this._batch.start_date);
    }
    return this._fb.group({
      name: [this._batch.name, [Validators.required, Validators.maxLength(255)]],
      cycle_length: [this._batch.cycle_length, [Validators.required]],
      start_date: [this._batch.start_date, [Validators.required]],
      client_account: [this._batch.client_account, [Validators.required]],
      contract: [this._batch.contract, [Validators.required]],
    });
  }

  private onChanges (): void {
    this._form.valueChanges.subscribe(values => this._batch.deserialize(values));
  }

  private setCycleLength () {

    for (let i = this._min_cycle_length; i <= this._max_cycle_length; i++) {
      this._cycle_lengths.push(i);
    }
  }

  /**
   * @param {Date} d
   * @returns {boolean}
   */
  startDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    return day === 1;
  };

  /**
   *
   * @param {string} date
   */
  setStartDateChange (date: string) {

    const tdate = new Date(date);

    return tdate.getFullYear() + '-' + (tdate.getMonth() + 1) + '-' + tdate.getDate();
  }

  /**
   *
   * @param {Date} date
   * @returns {string}
   */
  presentDate (date: string) {
    const tdate = new Date(date);
    return tdate.toDateString();
  }

  private setBatchData () {
    this._batch.client_account_id = this._batch.client_account.id;
    this._batch.contract_id       = this._batch.contract.id;
    this._batch.start_date        = this.setStartDateChange(this._batch.start_date);
    this._batch.team_ids          = this._selected_teams.map(a => a.id);
  }

  private onCreateOrUpdate (batch: any) {

    this._toastr_helper_serivce.setCreateUpdateMessage(this._is_create, batch.name);
    this.refreshTable.emit(true);
    this.hide();
  }

  handleFileInput (files: FileList) {
    Array.from(files).forEach(data => {
      this._batch.file = data;
    });
  }

  isValidForFile () {
    return !this._form.valid || this._selected_teams.length === 0;
  }
}
