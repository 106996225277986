import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WorkInstruction } from '../../../../../../../shared/models/work-instruction/work-instruction.model';
import { WorkInstructionService } from '../../../../api/services/work-instruction.service';
import {WorkInstructionStages} from '../../../../api/constants/work-instruction-statuses';
import {QuotesService} from '../../../../api/services/quotes.service';
import {EnquiriesServices} from '../../../../api/services/enquiries.services';

@Component({
  selector: 'app-cancelled-modal',
  templateUrl: './cancelled-modal.component.html',
  styleUrls: ['./cancelled-modal.component.scss']
})
export class CancelledModalComponent implements OnInit {

  @ViewChild('cancelledInstructionModal') _modal: ModalDirective;

  @Output() refreshTable = new EventEmitter<boolean>();

  formGroup: FormGroup;
  work_instruction: WorkInstruction;
  reference_number = '';

  reasons = [
    {reason: 'TOO EXPENSIVE'},
    {reason: 'JOB NOT PROGRESSING'},
    {reason: 'DEADLINE MISSED'},
    {reason: 'QUOTE INCORRECT'},
    {reason: 'OTHER'},
  ];

  constructor(private _work_instruction_service: WorkInstructionService,
              private _quote_service: QuotesService,
              private _enquiry_service: EnquiriesServices,
              private _fb: FormBuilder,
              private _toastr_service: ToastrService) {
  }

  ngOnInit() {
    this.formGroup = this.setForm();
  }

  selectedReason() {
    if (this.formGroup.get('note').value === 'OTHER') {
      this.formGroup.setControl('text', new FormControl('', [Validators.required]))
    }
  }

  setForm() {
    return this._fb.group({
      note: ['', Validators.required],
      text: ['', []]
    });
  }

  /**
   * @param {WorkInstruction} work_instruction
   */
  show(work_instruction: WorkInstruction) {
    this.formGroup        = this.setForm();
    this.work_instruction = work_instruction;
    this.reference_number = this.work_instruction.reference_number;
    this._modal.show();
  }

  hide() {
    this.formGroup.reset();
    this.formGroup = this.setForm();
    this._modal.hide();
  }

  accept() {
    let full_note = 'CANCELLED: ' + this.formGroup.get('note').value;

    if (this.formGroup.get('text').value) {
      full_note += ' (' + this.formGroup.get('text').value + ')';
    }

    this.work_instruction.note = full_note;

    if (this.work_instruction.work_instruction_stage_id === WorkInstructionStages.ENQUIRY) {
      this.work_instruction.work_instruction_stage_id = WorkInstructionStages.CANCELLED;
      this._enquiry_service.update(this.work_instruction.id, this.work_instruction).subscribe(data => {
        (<any>window).gtag('event', 'Cancelled', {event_category: 'Enquiry', event_label: this.formGroup.get('note').value});
        this._toastr_service.success('ENQUIRY CANCELLED', 'Success');
        this.refreshTable.emit(true);
        this.hide();
      });
    } else {
      this.work_instruction.work_instruction_stage_id = WorkInstructionStages.CANCELLED;
      this._quote_service.updateStatus(this.work_instruction.id, this.work_instruction).subscribe(data => {
        (<any>window).gtag('event', 'Cancelled', {event_category: 'Quote', event_label: this.formGroup.get('note').value});
        this._toastr_service.success('QUOTE CANCELLED', 'Success');
        this.refreshTable.emit(true);
        this.hide();
      });
    }
  }
}
