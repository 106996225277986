import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {WorkInstruction} from '../../../../../../../shared/models/work-instruction/work-instruction.model';
import {TaskStatuses, WorkInstructionStages} from '../../../../api/constants/work-instruction-statuses';
import {AuthService} from '../../../../../../../shared/users/auth.service';
import {WorkInstructionService} from '../../../../api/services/work-instruction.service';
import {ToastrService} from 'ngx-toastr';
import {WorkInstructionNote} from '../../../../../../../shared/models/work-instruction/work-instruction-note.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-cancel-work-instruction-modal',
  templateUrl: './cancel-work-instruction-modal.component.html',
  styleUrls: ['./cancel-work-instruction-modal.component.scss']
})
export class CancelWorkInstructionModalComponent implements OnInit {
  @ViewChild('cancelWorkInstructionModalComp') modal: ModalDirective;

  @Output() refreshData = new EventEmitter<boolean>();

  formGroup: FormGroup;

  account_id;

  completed_date_holder: Date;
  completed_date;

  work_instruction = new WorkInstruction();

  _is_actioning = false;

  reasons = [
    {reason:'TOO EXPENSIVE'},
    {reason:'JOB NOT PROGRESSING'},
    {reason:'DEADLINE MISSED'},
    {reason:'QUOTE INCORRECT'},
    {reason:'DUPLICATE JOB'},
    {reason:'OTHER'},
  ];

  public returnedAction = new Subject<boolean>();

  constructor(private work_instruction_service: WorkInstructionService,
              private form_builder: FormBuilder,
              private auth_service: AuthService,
              private toastr_service: ToastrService) {
  }

  public ngOnInit() {
    this.formGroup = this.setForm();
  }

  public show(workInstruction: WorkInstruction) {
    this.work_instruction = workInstruction;
    this.completed_date_holder = new Date();
    this.formGroup = this.setForm();
    this.modal.show();
    return this.returnedAction.asObservable();
  }

  public hide() {
    this.work_instruction = new WorkInstruction();
    this.formGroup.reset();
    this.formGroup = this.setForm();
    this.modal.hide();
  }

  public selectedReason(){
    if (this.formGroup.get('note').value === 'OTHER'){
      this.formGroup.setControl('text', new FormControl('', [Validators.required]))
    }
  }

  public setForm(){
    return this.form_builder.group({
      completed_date: ['', Validators.required],
      note: ['', Validators.required],
      text: ['',[]]
    });
  }

  public cancel(){
    this._is_actioning = true;

    this.work_instruction.work_instruction_stage_id = WorkInstructionStages.CANCELLED;
    this.work_instruction.updated_by_user_id = AuthService.getUserId();
    this.work_instruction.to_sync = true;

    this.completed_date = this.completed_date_holder.getFullYear() + '-' + (this.completed_date_holder.getMonth() + 1) + '-' + this.completed_date_holder.getDate();

    this.work_instruction.completed_at = this.completed_date.toString();

    this.work_instruction.tasks.forEach(task => {
        task.task_status_id = TaskStatuses.CANCELLED;
        task.completed_date = this.work_instruction.completed_at;
        task.to_sync        = this.work_instruction.to_sync
    });

    this.work_instruction.note = 'CANCELLED: ' + this.formGroup.get('note').value;

    if (this.formGroup.get('text').value){
      this.work_instruction.note += ' (' + this.formGroup.get('text').value + ')';
    }

    const auditNote = new WorkInstructionNote();
    auditNote.note = 'CANCELLED WORK INSTRUCTION';
    auditNote.created_by =  AuthService.getLoggedInUserFullName() + ' (' + AuthService.getLoggedUserName() + ')';
    this.work_instruction.notes = [];
    this.work_instruction.notes.push(auditNote);

    this.work_instruction_service.update(this.work_instruction.id, this.work_instruction).subscribe( data => {
      if (!data) return;
      (<any>window).gtag('event', 'Cancelled' , {event_category: 'Work Instruction', event_label: this.formGroup.get('note').value});
      this.refreshData.emit(true);
      this.toastr_service.success('UPDATED STATUS');
      this._is_actioning = false;
      this.returnedAction.next(true);
      this.hide();
      
    }, error1 => this._is_actioning = false);
  }
}
