import {Component, EventEmitter, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {DepartmentsService} from '../../../api/services/departments.service';
import {ModalDirective} from 'ngx-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {CategoryService} from '../../../api/services/category.service';
import {Observable} from 'rxjs';
import {ModalEvent} from '../../../api/interfaces/modal.interface';
import {Department} from '../../../../../shared/models/departments/department.model';
import {TaskCategory} from '../../../../../shared/models/work-instruction/task-category.model';

@Component({
  selector: 'app-category-wizard',
  templateUrl: './category-wizard.component.html',
  styleUrls: ['./category-wizard.component.scss']
})
export class CategoryWizardComponent implements OnInit{

  public scrollbarOptions = {axis: 'y', theme: 'minimal-dark'};

  @Output() refreshTable = new EventEmitter<boolean>();

  @ViewChild('categoryWizardModal') category_modal: ModalDirective;
  @ViewChild('stepper') stepper: MatStepper;

  _is_create = true;
  _is_deactivated = false;
  _is_contract_builder = false;

  _category_form: FormGroup;
  _category: TaskCategory;

  _departments;

  _selected_departments: Department[] = [];

  /**
   *
   * @param {CategoryService} category_service
   * @param {DepartmentsService} departments_service
   * @param {FormBuilder} form_builder
   * @param {ToastrService} toastr_service
   */
  constructor(
    private category_service:         CategoryService,
    private departments_service:      DepartmentsService,
    private form_builder:             FormBuilder,
    private toastr_service:           ToastrService
  ) {

    this._category = new TaskCategory();
    this._category_form = this.setForm();
  }

  ngOnInit() {
   this.category_service._category$.subscribe( data => {
     this._category = data;
   })
  }

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  getDepartmentList() {
    this.departments_service.getDepartmentList().subscribe(data => {
      this._departments = data;
    })
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  createCategory() {
    this.selectedDepartments();

    this.category_service.create(this._category).subscribe(data => {
      this.success('CATEGORY CREATED');
      this.hide();
    });
  }

  updateCategory() {
    this.selectedDepartments();

    this.category_service.update(this._category.id, this._category).subscribe(data => {
      this.success('CATEGORY UPDATED');
      this.hide();
    });
  }

  /* +++++++++++++++++++++++++++++++ MODAL METHODS ++++++++++++++++++++++++++++++++ */

  show(is_deactivated: boolean, @Optional() is_contract_builder: boolean = false) {

    this._is_deactivated        = is_deactivated;
    this._is_contract_builder   = is_contract_builder;
    this._category_form         = this.setForm();
    this._selected_departments  = this._category.departments || [];

    this.getDepartmentList();

    if (this._category.id > 0){
      this._is_create = false;
    } else {
      this._is_create = true;
    }

    this.category_modal.show();

    this.onChanges();
  }

  hide() {
    this.reset();
    this.category_modal.hide();
  }

  reset() {
    this.refreshTable.emit(true);
    this._is_create            = true;
    this._selected_departments = [];

    if (!this._is_deactivated) {
      this.stepper.reset();
    }
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  checkForDuplicate(field: string){
    if (this._category_form.get(field).valid) {
      this.category_service.checkForDuplicate(field, this._category_form.get(field).value, this._category.id).subscribe(data => {
        if (data > 0) {
          this._category_form.get(field).setErrors({'notUnique': true});
        }
      });
    }
  }

  selectedDepartments(){
    this._category.department_ids = this._selected_departments.map(item => item.id)
  }

  setForm(){
    return this.form_builder.group({
      name: [this._category.name, Validators.required],
    });
  }

  onChanges(){
    this._category_form.valueChanges.subscribe( values => {
      this._category.deserialize(values);
    })
  }

  success(message: string) {
    this.toastr_service.success(message);
    this._is_create = true;
  }
}
