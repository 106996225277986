import {Injectable, Optional} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import 'rxjs/';
import {WorkInstruction} from '../shared/models/work-instruction/work-instruction.model';

@Injectable()
export class LayoutService {

  private showWorkInstructionModal = new BehaviorSubject<{work_instruction: WorkInstruction, show:boolean, type: number}>({work_instruction: new WorkInstruction(), show:false, type: 0});
  private refresh_table = new BehaviorSubject<boolean>(false);

  showWorkInstructionModal$ = this.showWorkInstructionModal.asObservable();
  refresh_table$ = this.refresh_table.asObservable();

  constructor(){}

  setShowWorkInstructionModal(work_instruction: WorkInstruction, show:boolean, type: number){
    this.showWorkInstructionModal.next({work_instruction: work_instruction, show:show, type: type});
  }

  setRefreshTable(bool: boolean){
    this.refresh_table.next(bool);
  }
}
