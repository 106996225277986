import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss']
})
export class SpinnerButtonComponent implements OnInit, OnChanges{

  button_text = '';
  @Input('text') text: string;
  @Input('loading_text') loading_text: string;
  @Input('is_actioning') is_actioning: boolean;
  @Input('disabled') disabled: boolean = false;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(){
    this.button_text = this.is_actioning ? this.loading_text : this.text;
  }
}
