import {DateHelper} from '../../../utility/dates/date.helper';
import {BillingCompany} from '../billing/billing-company.model';
import {InvoiceStatus} from '../billing/invoice-status.model';
import {Invoice} from '../billing/invoice.model';
import {ClientAccount} from '../client/client-account.model';
import {Contract} from '../client/contract.model';
import {Site} from '../client/site.model';
import {Department} from '../departments/department.model';
import {Deserializable} from '../deserializable.model';
import {User} from '../user/user.model';
import {Cycle} from './cycle.model';
import {WorkInstructionPriority} from './work-instruction-priority.model';
import {WorkInstructionProgress} from './work-instruction-progress.model';
import {WorkInstructionStage} from './work-instruction-stage.model';
import {WorkInstructionNote} from './work-instruction-note.model';
import {AuthService} from '../../users/auth.service';
import {Task} from './task.model';
import {WorkInstructionPhoto} from './work-instruction-photo.model';
import {Document} from '../documents/document.model';
import {CycleWeek} from './cycle-week.model';
import {RequirementType} from './work-instruction-requirement-type.model';
import {RecurringTemplate} from "../recurring/recurring-template.model";

export class WorkInstruction implements Deserializable {

  /***************************** END ***************************************************/
  billing_company_id?: number;
  client_account_id?: number;
  client_reference_number?: string;
  closed?:boolean;
  completed_at?: string;
  completion?: number;
  contact_email?: string;
  contact_name?: string;
  contact_number?: string;
  contract_id?: number;
  created_at?: string;
  created_by_user_id?: number = AuthService.getAccountId();
  cycle_id?: number;
  cycle_week_id?:number;
  deactivated_at?: string;
  deleted_at?: string;
  department_id?: number;
  description?: string;
  due_at?: string;
  estimated_time?: number;
  flagged_for_review?: number;
  gross_invoice_value?: number = 0;
  has_special_requirements?: boolean;
  id: number = 0;
  invoice_id?: number;
  invoice_status_id?: number;
  is_high_risk?: boolean;
  is_issued?: boolean;
  is_routed?: boolean;
  issued_at?: string;
  net_invoice_value?: number = 0;
  note?: string;
  notes?: WorkInstructionNote[] = [];
  po_number?: string;
  quote_requested?: boolean;
  recurring_template_id?: number;
  reference_number?: string;
  released_at?: string;
  released_by_user_id?: number;
  requested_by_user_id?: number;
  requirement_type_ids?: number[] = [];
  route_id?: number;
  route_order?: number;
  scheduled_group_id?: number;
  site_id?: number;
  stage_updated_at?: string;
  status_age?: number;
  summary?: string;
  team_name?: string;
  to_sync?: boolean;
  updated_at?: string;
  updated_by_user_id?: number;
  visit_confirmed?: number;
  work_instruction_priority_id?: number;
  work_instruction_stage_id?: number;
  reactive_route_order?: number;
  start_date?: string;
  calendar_order?: number;

  wip_objects?: {value: number, person: string, date: string, job_value: number, total_value:number}[] = [];

  /***** Relationships *******/
  billing_company?: BillingCompany;
  client_account?: ClientAccount;
  contract?: Contract;
  created_by_user?: User;
  cycle_week?: CycleWeek;
  cycle?: Cycle;
  department?: Department;
  documents?: Document[] = [];
  file?: File;
  invoice_status?: InvoiceStatus;
  invoice?: Invoice;
  recurring_template?: RecurringTemplate[];
  requirement_types?: RequirementType[] = [];
  site?: Site = new Site();
  tasks?: Task[] = [];
  work_instruction_notes?: WorkInstructionNote[];
  work_instruction_photos?: WorkInstructionPhoto[] = [];
  work_instruction_priority?: WorkInstructionPriority;
  work_instruction_progresses?: WorkInstructionProgress[];
  work_instruction_stage?: WorkInstructionStage;

  public workInstructionProgresses?
  public statusAge?: number

  constructor(properties?) {
    for (let prop in properties) {
      if (!properties.hasOwnProperty(prop)) return;
      this[prop] = properties[prop];
    }

    Object.defineProperty(this,'workInstructionProgresses',{
      get() {
        return this.work_instruction_progresses
          .map((progress: WorkInstructionProgress) => new WorkInstructionProgress().deserialize(progress))
      }
    });

    Object.defineProperty(this,'statusAge',{
      get() { return DateHelper.getDiffInDays(this.stage_updated_at); }
    });

    return this;
  }

  public pushWorkInstructionProgress? (work_instruction_progress: WorkInstructionProgress) {
    this.work_instruction_progresses.unshift(work_instruction_progress);
    return this.work_instruction_progresses;
  }

  public calculateCurrentWipTotal? (): number {
    if(this.work_instruction_progresses.length <= 0) return 0;
    //Get the last progress that was entered because that will have the latest total
    return this.work_instruction_progresses[0].total / this.net_invoice_value;
  }

  public getLastWipTotal? (): number {

    if(this.work_instruction_progresses.length > 0){
      return this.work_instruction_progresses[0].total;
    }
    return 0;
  }

  public deserialize? (input: any) {
    Object.assign(this, input);
    return this;
  }
}
