import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs/Rx';
import {Contract} from '../../../../shared/models/client/contract.model';
import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../../shared/users/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class ContractsService {

  /**
   * @type {BehaviorSubject<Contract>}
   */
  private _contract = new BehaviorSubject<Contract>(new Contract());

  /**
   * @type {Observable<Contract>}
   */
  public _contract$ = this._contract.asObservable();

  /**
   *
   * @param {HttpClient} httpClient
   */
  constructor (private httpClient: HttpClient) {
  }

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  /**
   * get a list of contracts
   */
  getContracts (account_id: string): Observable<Contract[]> {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'account_id': account_id
      }
    };

    return this.httpClient.get<Contract[]>(environment.base_url + '/contracts/get-contracts', httpOptions)
      .map((contracts: Contract[]) => contracts.map((contract) => new Contract().deserialize(contract)));
  }

  /**
   * get a list of contracts
   */
  getAllContracts (account_id: string): Observable<Contract[]> {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'account_id': account_id
      }
    };

    return this.httpClient.get<Contract[]>(environment.base_url + '/contracts/get-all-contracts', httpOptions)
      .map((contracts: Contract[]) => contracts.map((contract) => new Contract().deserialize(contract)));
  }

  /**
   *
   * @returns {Observable<Contract[]>}
   */
  getContractsDatatable () {
    return this.httpClient.get<Contract[]>(environment.base_url + '/contracts/contracts-datatable', {params: {'auth_token': AuthService.getAuthToken()}})
      .map((contracts: Contract[]) => contracts.map((contract) => new Contract().deserialize(contract)));
  }

  /**
   *
   * @returns {Observable<Contract[]>}
   */
  getScheduledContractsDatatable(){
    return this.httpClient.get<Contract[]>(environment.base_url + '/contracts/scheduled-contracts-datatable', {params: {'auth_token': AuthService.getAuthToken()}})
      .map((contracts: Contract[]) => contracts.map((contract) => new Contract().deserialize(contract)));
  }

  /**
   *
   * @returns {Observable<Contract[]>}
   */
  getContractList () {
    return this.httpClient.get<Contract[]>(environment.base_url + '/contracts/get-contract-list', {params: {'auth_token': AuthService.getAuthToken()}})
      .map((contracts: Contract[]) => contracts.map((contract) => new Contract().deserialize(contract)));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<Contract>}
   */
  getContract (id: number) {
    return this.httpClient.get<Contract>(environment.base_url + '/contracts/get-contract', {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} data
   * @returns {Observable<Object>}
   */
  create (data: object) {
    return this.httpClient.post<Contract>(environment.base_url + '/contracts/create-contract', data, {params: {'auth_token': AuthService.getAuthToken()}})
      .map((contract) => new Contract().deserialize(contract));
  }

  /**
   *
   * @param {number} id
   * @param {object} data
   * @returns {Observable<Object>}
   */
  update (id: number, data: object) {
    return this.httpClient.post(environment.base_url + '/contracts/update-contract', data, {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ ARCHIVE METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archive (ids: object) {
    return this.httpClient.post(environment.base_url + '/contracts/archive-contracts', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activate (ids: object) {
    return this.httpClient.post(environment.base_url + '/contracts/activate-contracts', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {Contract} contract
   */
  setContract(contract: Contract){
    if (contract === null){
      contract = new Contract();
    }

    this._contract.next(contract);
  }

  /**
   *
   * @param {string} field
   * @param {string} value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate (field: string, value: string, id: number) {
    return this.httpClient.post(
      environment.base_url + '/contracts/check-for-duplicate',

      {field: field, value: value},

      {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}}
    );
  }
}
