import {Deserializable} from '../deserializable.model';
import {Site} from "../client/site.model";
import {InvoiceStatus} from "../billing/invoice-status.model";
import {Invoice} from "../billing/invoice.model";
import {ClientAccount} from "../client/client-account.model";
import {Department} from "../departments/department.model";
import {BillingCompany} from "../billing/billing-company.model";
import {Cycle} from "../work-instruction/cycle.model";
import {CycleWeek} from "../work-instruction/cycle-week.model";
import {WorkInstructionPriority} from "../work-instruction/work-instruction-priority.model";
import {WorkInstructionProgress} from "../work-instruction/work-instruction-progress.model";
import {WorkInstructionStage} from "../work-instruction/work-instruction-stage.model";
import {Document} from "../documents/document.model";
import {User} from "../user/user.model";
import {RecurringDate} from "../work-instruction/recurring_date.model";
import {RecurringTaskTemplate} from "./recurring-task-template.model";
import {TimetableDataHolder} from '../work-instruction/recurring-time-range.model';
import {WorkInstruction} from "../work-instruction/work-instruction.model";
import {Team} from '../teams/team.model';

export class RecurringTemplate implements Deserializable {

  id: number;
  client_account_id: number;
  contract_id: number;
  department_id: number;
  billing_company_id: number;
  work_instruction_priority_id: number;
  site_id: number;
  team_id: number;
  summary: string;
  description: string;
  po_number: string;
  contact_number: string;
  contact_name: string;
  contact_email: string;
  quote_requested: boolean;
  closed: boolean;
  due_at: string;
  task_type_id: number;
  recurring_type_id: number;
  raise_before_days: string;
  start_date: string;
  end_date: string;
  deactivated_at: string;
  deleted_at: string;
  is_high_risk: boolean;
  created_at: string;
  updated_at: string;
  created_by_user_id: number;
  updated_by_user_id: number;
  client_reference_number: string;
  net_invoice_value: number;
  gross_invoice_value: number;
  flagged_for_review: number;
  estimated_time?: number;

  timetable_data: TimetableDataHolder;


  /********* Relationships ***********/

  invoice_status: InvoiceStatus;
  invoice: Invoice;
  client_account: ClientAccount;
  department: Department;
  billing_company: BillingCompany;
  cycle: Cycle;
  cycle_week: CycleWeek;
  work_instruction_priority: WorkInstructionPriority;
  work_instruction_progresses: WorkInstructionProgress[];
  work_instruction_stage: WorkInstructionStage;
  documents: Document[];
  created_by_user: User;
  team: Team;

  site: Site;
  recurring_tasks: RecurringTaskTemplate[];
  recurring_task_templates: RecurringTaskTemplate[]; // TODO - change
  recurring_dates: RecurringDate[];
  work_instructions: WorkInstruction[];

  /****************************************** GETTERS *******************************/

  get recurringDates(){
    return this.recurring_dates.map((date: RecurringDate) => new RecurringDate().deserialize(date));
  }


  deserialize(input: any) {
    Object.assign(this, input);
    this.recurring_dates.map((date: RecurringDate) => new RecurringDate().deserialize(date));
    return this;
  }

  constructor() {
    this.id = 0;
    this.summary = '';
    this.description = '';
    this.po_number = '';
    this.contact_number = '';
    this.contact_name = '';
    this.contact_email = '';
    this.due_at = '';
    this.raise_before_days = '';
    this.start_date = '';
    this.end_date = '';
    this.deactivated_at = '';

    this.recurring_tasks = [];
    this.recurring_dates = [];
    this.work_instructions = [];
    this.site = new Site();
  }
}
