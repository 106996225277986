import * as moment from 'moment';

export class DateHelper {

  /**
   *
   * @param {string} date
   * @returns {number}
   */
  public static getDiffInDays(date: string){

    const tdate = moment(date);
    const now  = moment();

    return now.diff(tdate, 'days');
  }

  getDisplayFormattedDateFromString(date: string){
    const dateObj = moment(date);
    return dateObj.format('ddd MMM Do, YYYY');
  }

  public static getFormattedDate(date: Date){
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }
}
