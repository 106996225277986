import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import { WorkInstruction } from '../../../../shared/models/work-instruction/work-instruction.model';
import { AuthService } from '../../../../shared/users/auth.service';
import { environment } from '../../../../../environments/environment';
import { InvoiceSummaryInterface } from '../interfaces/invoice-summary.interface';
import { InvoicedConfirmInterface } from '../interfaces/invoiced-confirm.interface';
import { WorkInProgressDatatableInterface } from '../interfaces/work-in-progress-datatable.interface';
import { InvoiceStatusInterface } from '../interfaces/invoice-status.interface';

@Injectable()
export class FinanceService {

  private selected_work_instructions  = new BehaviorSubject<WorkInstruction[]>([]);
          selected_work_instructions$ = this.selected_work_instructions.asObservable();

  constructor (private http: HttpClient) {
  }

  getWorkInstructionFinanceDatatable (invoice_status_id: number) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'invoice_status_id': invoice_status_id.toString(),
      }
    };

    return this.http.get<WorkInstruction[]>(environment.base_url + '/finance/get-work-instruction-finance', httpOptions);
  }

  setInvoiceStatus (ids: number[], invoice_status_id: number) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
      }
    };

    return this.http.post(environment.base_url + '/finance/set-invoice-status', {
      ids: ids,
      invoice_status_id: invoice_status_id
    }, httpOptions);
  }

  setSelectedWorkInstructions (selected: any) {
    this.selected_work_instructions.next(selected);
  }

  getInvoiceSummary (ids: number[]) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
      }
    };
    return this.http.post<InvoiceSummaryInterface>(environment.base_url + '/invoices/get-invoice-summary',
      {work_instruction_ids: ids},
      httpOptions);
  }

  submitForInvoicing (ids: number[]) {
    return this.http.post<InvoicedConfirmInterface>(environment.base_url + '/invoices/invoice', {work_instruction_ids: ids}, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  getWorkInProgress (contract_id: number, team_id: number, department_id: number, invoice_status_id: number) {

    contract_id       = !contract_id ? 0 : contract_id;
    team_id           = !team_id ? 0 : team_id;
    department_id     = !department_id ? 0 : department_id;
    invoice_status_id = !invoice_status_id ? 0 : invoice_status_id;
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'contract_id': contract_id.toString(),
        'team_id': team_id.toString(),
        'department_id': department_id.toString(),
        'invoice_status_id': invoice_status_id.toString()
      }
    };
    return this.http.get<WorkInProgressDatatableInterface[]>(environment.base_url + '/finance/get-work-in-progress', httpOptions);
  }

  getInvoiceStatuses () {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
      }
    };

    return this.http.get<InvoiceStatusInterface[]>(environment.base_url + '/finance/get-invoice-statuses', httpOptions);
  }
}
