import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppErrorHandler extends ErrorHandler {

  constructor(@Inject(Injector) private injector: Injector){
    super();
  }

  private get toastrService(): ToastrService{
    return this.injector.get(ToastrService);
  }

  public handleError(error:any): void{

    if(error && error.error && error.error.errors) {
      Object.keys(error.error.errors).forEach(value => {
        this.toastrService.error(
          error.error.errors[value], 'ERROR', { onActivateTick: true }
        );
      });
    }
    super.handleError(error);
  }
}
