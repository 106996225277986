import {Deserializable} from '../deserializable.model';
import {ClientAccount} from '../client/client-account.model';
import {Contract} from '../client/contract.model';
import { Import } from '../documents/import.model';
import { Team } from '../teams/team.model';
import { User } from '../user/user.model';
import {Cycle} from './cycle.model';

export class Batch implements Deserializable {

  id: number;
  archived: number;
  client_account_id: number;
  contract_id: number;
  import_id: number;
  created_by_user_id: number;
  updated_by_user_id: number;
  cycle_length: number;
  start_date: any;
  end_date: string;
  name: string;
  published: string;
  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;
  imported_at: string;
  completed: boolean;

  completed_count: number;
  issued_progress: number;
  issued_count: number;
  total_progress: number;
  total_count: number;

  client_account: ClientAccount;
  contract: Contract;
  cycles: Cycle[];
  import: Import;
  createdByUser: User;
  updatedByUser: User;
  teams: Team[];

  donut_data: {name: string, value:number}[];
  chart_data: {name: string, value:number}[];

  team_ids: number[];
  file: File;

  deserialize(input: any){
    Object.assign(this, input);

    return this;
  }

}
