import {Injectable} from '@angular/core';
import {RecurringDate} from '../../../../../shared/models/work-instruction/recurring_date.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {AuthService} from '../../../../../shared/users/auth.service';

@Injectable()
export class RecurringDateService {

  constructor (private httpClient: HttpClient){}

  update(id:number, date:RecurringDate){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'id': id.toString(),
      }
    };
    return this.httpClient.post<RecurringDate>(environment.base_url + '/recurring-dates/update', date, httpOptions).map(instruction => new RecurringDate().deserialize(instruction));
  }
}
