import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { Import } from '../../../../shared/models/documents/import.model';
import { AuthService } from '../../../../shared/users/auth.service';

@Injectable()
export class ImportsService {

  /**
   * @param {HttpClient} httpClient
   */
  constructor (private httpClient: HttpClient) {
  }

  /**
   *
   * @param {number} import_type_id
   * @param {number} from
   * @param {number} page_limit
   * @param {string} sort_prop
   * @param {string} sort_dir
   * @returns {Observable<Import[]>}
   */
  getImports (import_type_id: number, from: number, page_limit: number, sort_prop: string, sort_dir: string): Observable<Import[]> {

    if(!import_type_id){
      import_type_id = 0;
    }

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'import_type_id': import_type_id.toString(),
        'from': from.toString(),
        'page_limit': page_limit.toString(),
        'sort_prop': sort_prop,
        'sort_dir': sort_dir
      }
    };

    return this.httpClient.get<Import[]>(environment.base_url + '/documents/get-imports', httpOptions)
      .map((imports: Import[]) => imports.map((importObject) => new Import().deserialize(importObject)));
  }

  getSchedulingExport(account_id: number, contract_id: number){

    const headers = new HttpHeaders();
    headers.set('Accept', 'text/xlsx');
    headers.set('Content-type', 'text/xlsx');
    const params = {
      'auth_token': AuthService.getAuthToken(),
      'account_id': account_id.toString(),
      'contract_id': contract_id.toString(),
    };

    return this.httpClient.get(environment.base_url + '/scheduling/get-export', {
      responseType: 'blob',
      params: params,
      headers: headers
    });

  }

  /**
   * @param account_id
   * @param contract_id
   */
  getBulkUpload(account_id: number, contract_id: number){

    const headers = new HttpHeaders();
    headers.set('Accept', 'text/xlsx');
    headers.set('content-type', 'text/xlsx');
    const params = {
      'auth_token': AuthService.getAuthToken(),
      'account_id': account_id.toString(),
      'contract_id': contract_id.toString(),
    };

    return this.httpClient.get(environment.base_url + '/documents/get-bulk-upload', {
      responseType: 'blob',
      params: params,
      headers: headers
    });
  }
}
