import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientsService} from '../../../../pages/admin/api/services/clients.service';
import {ClientListItem} from '../../../../pages/admin/api/interfaces/clients.interface';
import {TasksService} from '../../../../pages/work/reactive/api/services/tasks.service';
import {saveAs} from 'file-saver';
import {WorkInstructionStagesService} from '../../../../pages/work/reactive/api/services/work-instruction-stages.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContractsService} from '../../../../pages/admin/api/services/contracts.service';
import {FinanceService} from '../../../../pages/finance/api/services/finance.service';
import {TeamsService} from '../../../../pages/admin/api/services/teams.service';
import {InvoiceStatusInterface} from '../../../../pages/finance/api/interfaces/invoice-status.interface';
import {DepartmentsService} from '../../../../pages/admin/api/services/departments.service';
import {ModalDirective} from 'ngx-bootstrap';
import {Department} from '../../../models/departments/department.model';
import {Contract} from '../../../models/client/contract.model';
import {Team} from '../../../models/teams/team.model';
import {WorkInstructionStagesInterface} from '../../../../pages/work/reactive/api/interfaces/work-instruction-stages.interface';

@Component({
  selector: 'app-task-export-modal',
  templateUrl: './task-export-modal.component.html',
  styleUrls: ['./task-export-modal.component.scss']
})
export class TaskExportModalComponent implements OnInit {

  date_types                                 = [];
  job_types                                  = [];
  clients: ClientListItem[]                  = [];
  contracts: Contract[]              = [];
  departments: Department[]                  = [];
  teams: Team[]                      = [];
  stages: WorkInstructionStagesInterface[]            = [];
  invoice_statuses: InvoiceStatusInterface[] = [];

  form: FormGroup;
  public exporting: boolean = false;

  min_date_to   = null;
  max_date_from = null;

  @ViewChild('exportModal') modal: ModalDirective;

  constructor (private form_builder: FormBuilder,
               private task_service: TasksService,
               private client_service: ClientsService,
               private contract_service: ContractsService,
               private department_service: DepartmentsService,
               private stage_service: WorkInstructionStagesService,
               private team_service: TeamsService,
               private finance_service: FinanceService) {
  }

  ngOnInit () {

    this.getData();

    this.form = this.form_builder.group({
      date_type: [null, Validators.required],
      date_from: [null, Validators.required],
      date_to: [null, Validators.required],
      client_account_id: [null],
      contract_id: [null],
      department_id: [null],
      team_id: [null],
      stage_id: [null],
      invoice_status_id: [null],
      job_type: [null]
    });
  }

  setMinDate(date){
    this.min_date_to = date;
  }

  setMaxDate(date){
    this.max_date_from = date;
  }

  getData () {

    this.date_types = [{id: 'due_date', name: 'Due Date'}, {id: 'completed_date', name: 'Completed Date'}];
    this.job_types  = [{id: 'any', name: 'Any'}, {id: 'reactive', name: 'Reactive'}, {id: 'scheduled', name: 'Scheduled'}];

    this.client_service.getClientList().subscribe(data => {
      this.clients = data;
    });

    this.contract_service.getContractList().subscribe(data => {
      this.contracts = data;
    });

    this.department_service.getDepartmentList().subscribe(data => {
      this.departments = data;
    });

    this.team_service.getTeamList().subscribe(data => {
      this.teams = data;
    });

    this.stage_service.getWorkInstructionStages().subscribe(data => {
      this.stages = data;
    });

    this.finance_service.getInvoiceStatuses().subscribe(data => {
      this.invoice_statuses = data;
    });
  }

  onExport () {

    this.exporting = true;
    this.task_service.getExport(this.form.value).toPromise().then(response => this.downloadFile(response));
  }

  downloadFile (response) {

    const date     = new Date();
    const filename = 'data_export_' + date.toLocaleDateString() + '_' + date.toLocaleTimeString();

    const blob = new Blob([response], {type: 'text/xlsx'});
    saveAs(blob, filename + '.xlsx');
    this.exporting = false;
  }

  show () {
    this.modal.show();
  }

  hide () {

    this.form.reset();
    this.modal.hide();
  }
}
