import {HttpClient} from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {AuthService} from '../../../../../shared/users/auth.service';
import {Cycle} from '../../../../../shared/models/work-instruction/cycle.model';
import {environment} from '../../../../../../environments/environment';
import {BehaviorSubject} from 'rxjs/index';
import {Contract} from '../../../../../shared/models/client/contract.model';
import {ScheduledTeamData} from '../models/scheduled-team-data.model';
import {CycleWeek} from '../../../../../shared/models/work-instruction/cycle-week.model';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';

@Injectable()
export class CycleService {

  /**
   * @type {BehaviorSubject<Cycle>}
   */
  private _cycle = new BehaviorSubject<Cycle>(new Cycle());

  /**
   * @type {Observable<Cycle>}
   */
  public _cycle$ = this._cycle.asObservable();

  /**
   *
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient) {}

  /**
   *
   * @returns {Observable<Cycle[]>}
   */
  getCycles(@Optional() batch_id:number = 0){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'batch_id': batch_id.toString()
      }
    };

    return this.httpClient.get<Cycle[]>(environment.base_url + '/cycles/get-cycles', httpOptions)
      .map((cycles: Cycle[]) => cycles.map((cycle) => new Cycle().deserialize(cycle)));
  }

  /**
   *
   * @param {string} cycle_id
   * @returns {Observable<Cycle>}
   */
  getBatchCycle(cycle_id: string){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'cycle_id': cycle_id,
      }
    };

    return this.httpClient.get<Cycle>(environment.base_url + '/cycles/get-batch-cycle', httpOptions)
      .map((cycle: Cycle) => new Cycle().deserialize(cycle));
  }

  /**
   *
   * @param {number} cycle_id
   * @param {number} type_showing
   * @param {number} team_id
   * @returns {Observable<WorkInstruction[]>}
   */
  getCycleJobList(cycle_id: number, type_showing: number, @Optional() team_id: number = 0){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'cycle_id': cycle_id.toString(),
        'team_id': team_id.toString(),
        'work_instruction_stage_id': type_showing.toString()
      }
    };

    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/cycles/get-cycle-job-list', httpOptions)
      .map((work_instructions: WorkInstruction[]) => work_instructions.map((work_instruction) => new WorkInstruction().deserialize(work_instruction)));
  }

  /**
   *
   * @param {number} team_id
   * @param {number} cycle_id
   * @returns {Observable<ScheduledTeamData>}
   */
  getTeamCycleWeekDetails(team_id:number, cycle_id:number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id.toString(),
        'cycle_id': cycle_id.toString()
      }
    };

    return this.httpClient.get<CycleWeek[]>(environment.base_url + '/teams/get-scheduled-team-weeks', httpOptions)
      .map((cycle_weeks: CycleWeek[]) => cycle_weeks.map((cycle_week) => new CycleWeek().deserialize(cycle_week)));
  }

  /**
   *
   * @param {Cycle} cycle
   */
  setCycle(cycle: Cycle){
    if (cycle === null){
      cycle = new Cycle();
    }

    this._cycle.next(cycle);
  }
}
