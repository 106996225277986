import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { BillingCompany } from '../../../../shared/models/billing/billing-company.model';
import { AuthService } from '../../../../shared/users/auth.service';

@Injectable()
export class BillingCompanyService {

  /**
   * @type {BehaviorSubject<BillingCompany>}
   * @private
   */
  private _billing_company = new BehaviorSubject<BillingCompany>(new BillingCompany());

  /**
   * @type {Observable<BillingCompany>}
   * @private
   */
  public _billing_company$ = this._billing_company.asObservable();

  /**
   * constructor
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient){}

  /******************* Behaviour Subjects ***************/

  /**
   * @param {BillingCompany} billing_company
   */
  setBillingCompany(billing_company: BillingCompany){
    this._billing_company.next(billing_company);
  }

  /******************* API CALLS ******************/

  /**
   * @returns {Observable<BillingCompany[]>}
   */
  getBillingCompaniesDatatable(){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.httpClient.get<BillingCompany[]>(environment.base_url + '/billing-company/get-billing-companies', httpOptions)
      .map((billing_companies: BillingCompany[]) => billing_companies.map((billing_company) => new BillingCompany().deserialize(billing_company)));
  }

  /**
   * @returns {Observable<Object>}
   */
  getBillingCompanySelectList(){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.get<BillingCompany[]>(environment.base_url + '/billing-company/get-billing-company-select-list', httpOptions);
  }

  /**
   * @param {string} field
   * @param value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate(field: string, value: any, id: number){

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    id = id || 0;

    return this.httpClient.post(
      environment.base_url + '/billing-company/check-for-duplicate',
      {field: field, value: value, id: id},
      httpOptions);
  }

  /**
   * @param {BillingCompany} billing_company
   * @returns {Observable<Object>}
   */
  updateBillingCompany(billing_company: BillingCompany){

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.httpClient.post<BillingCompany>(environment.base_url + '/billing-company/' + billing_company.id, billing_company, httpOptions);
  }

  /**
   * @param {BillingCompany} billing_company
   * @returns {Observable<Object>}
   */
  storeBillingCompany(billing_company: BillingCompany){

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.httpClient.post<BillingCompany>(environment.base_url + '/billing-company', billing_company, httpOptions);
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archiveCompanies(ids: object){

    return this.httpClient.post(environment.base_url + '/billing-company/archive-companies', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activateCompanies(ids: object){

    return this.httpClient.post(environment.base_url + '/billing-company/activate-companies', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }
}
