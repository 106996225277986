import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../shared/models/user/user.model';
import { AuthService } from '../../../../shared/users/auth.service';

@Injectable()

export class UsersService {

  /**
   * @type {BehaviorSubject<User>}
   */
  private _user = new BehaviorSubject<User>(new User());

  /**
   * @type {Observable<User>}
   */
  public _user$ = this._user.asObservable();

  /**
   * constructor
   * @param {HttpClient} http
   */
  constructor (private http: HttpClient) {
  }

  /***************** Behaviour Subjects ***********/

  /**
   * Set a new User
   * @param {User} user
   */
  setUser(user: User){
    this._user.next(user);
  }

  /***************** API CALLS ************/

  /**
   * Get a list of users
   * @returns {Observable<User[]>}
   */
  getUsers (){
    return this.http.get<User[]>(environment.base_url + '/user/get-users', {params: {'auth_token': AuthService.getAuthToken()}})
      .map((users: User[]) => users.map((user) => new User().deserialize(user)));

  }

  /**
   * Get a list of users
   * @returns {Observable<User[]>}
   */
  getUser (id){

    return this.http.get<User[]>(environment.base_url + '/user/get-user', {params: {'auth_token': AuthService.getAuthToken(), id}})
      .map((users: User[]) => users.map((user) => new User().deserialize(user)));
  }

  /**
   * Archive a list of users
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archiveUsers (ids: object) {

    return this.http.post(environment.base_url + '/user/archive-users', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   * Activate a list of users
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activateUsers (ids: object) {

    return this.http.post(environment.base_url + '/user/activate-users', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   * Store a user in db
   * @param {User} user
   * @returns {Observable<User>}
   */
  store (user: User){
    user.auth_token = AuthService.getAuthToken();
    return this.http.post(environment.base_url + '/user/create', user);
  }

  /**
   * Update a user in the db
   * @param {User} user
   * @returns {Observable<User>}
   */
  update (user: User) {

    user.auth_token = AuthService.getAuthToken();
    return this.http.post(environment.base_url + /user/ + user.id + '/update', user);
  }


  /***** NEED SORTING ******/
  checkForDuplicateUsername (value: string, id: number) {

    id = id || 0;

    return this.http.post(
      environment.base_url + '/user/check-for-duplicate',

      {field: 'username', value: value, id: id},

      {params: {'auth_token': AuthService.getAuthToken()}});
  }
}
