import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {RecurringTemplate} from '../../../../../../shared/models/recurring/recurring-template.model';
import {RecurringTemplateService} from '../../../api/services/recurring-template.service';
import {RecurringDate} from '../../../../../../shared/models/work-instruction/recurring_date.model';
import {ToastrService} from 'ngx-toastr';
import {WorkInstruction} from '../../../../../../shared/models/work-instruction/work-instruction.model';
import {WorkInstructionStages} from '../../../api/constants/work-instruction-statuses';
import {InvoiceStatuses} from '../../../../../../shared/constants/invoice-statuses';
import swal from 'sweetalert2';

@Component({
  selector: 'app-recurring-sidebar-details',
  templateUrl: './recurring-sidebar-details.component.html',
  styleUrls: ['./recurring-sidebar-details.component.scss']
})

export class RecurringSidebarDetailsComponent implements OnInit, OnChanges {
  recurring_template: RecurringTemplate = new RecurringTemplate();

  @Input() template_id = 0;
  @Output() close = new EventEmitter<boolean>();
  @Output() showModal = new EventEmitter<{work_instruction:WorkInstruction}>();
  @Output() showEditModal = new EventEmitter<{date:RecurringDate, contract_id: number}>();

  work_instruction_stages = new WorkInstructionStages();
  invoice_status_names = new InvoiceStatuses();

  _is_loading = false;

  constructor(private recurring_template_service: RecurringTemplateService, private toastr_service: ToastrService) {}

  ngOnInit() {  }

  ngOnChanges(){
    this.recurring_template = new RecurringTemplate();
    this.getData();
  }

  getData(){
    this._is_loading = true;
    this.recurring_template_service.getRecurringTemplate(this.template_id).subscribe(data => {
      this.recurring_template = data;
      this._is_loading = false;
    }, error1 => this._is_loading = false);
  }

  cancelWorkInstruction(work_instruction: WorkInstruction){
    this.showModal.next({work_instruction: work_instruction});
  }

  cancelDate(date: RecurringDate){
    swal({
      title: 'CANCEL RECURRING DATE?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'YES',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result) {
        this.toastr_service.info('CANCELLING...');
        this.recurring_template_service.cancelDate(date.id).subscribe(data => {
          this.getData();
          this.toastr_service.success('CANCELLED SUCCESSFULLY')
        });
      }
    }).catch(swal.noop);
  }

  editDate(date: RecurringDate){
    this.showEditModal.emit({date: date, contract_id: this.recurring_template.contract_id});
  }
}
