import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { WorkInstruction } from '../../../../../../shared/models/work-instruction/work-instruction.model';
import { WorkInstructionService } from '../../../api/services/work-instruction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WorkInstructionNotesService } from '../../../api/services/work-instruction-note.service';
import { RequirementType } from '../../../../../../shared/models/work-instruction/work-instruction-requirement-type.model';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-work-instruction-note-feed',
  templateUrl: './work-instruction-note-feed.component.html',
  styleUrls: ['./work-instruction-note-feed.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class WorkInstructionNoteFeedComponent implements OnInit {
  @ViewChild('requirementTypeSelect') requirement_type_select: NgSelectComponent;
  @Input() has_requirements = false;
  @Input() requirements: RequirementType[] = [];
  @Input('workInstruction') public work_instruction: WorkInstruction;

  _selected_requirement_type: RequirementType;

  formGroup: FormGroup;

  _is_required = false;
  _is_actioning = false;

  isCollapsed = true;

  @Output() close = new EventEmitter<boolean>();
  @Output() updated = new EventEmitter<boolean>();

  constructor(
    private work_instruction_service: WorkInstructionService,
    private work_instruction_note_service: WorkInstructionNotesService,
    private form_builder: FormBuilder,
    private toastr_service: ToastrService
  ) {
    this.formGroup = this.form_builder.group({
      note: [null, [Validators.maxLength(255), Validators.required]],
      is_required: [this._is_required],
      requirement_type_id: [{ value: null, disabled: true }]
    });
  }

  boxChecked() {
    if (this.formGroup.get('is_required').value) {
      this.formGroup
        .get('requirement_type_id')
        .setValidators(Validators.required);
      this.formGroup.get('requirement_type_id').enable();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
    } else {
      this._selected_requirement_type = null;
      this.formGroup.get('requirement_type_id').reset();
      this.formGroup.get('requirement_type_id').clearValidators();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
      this.formGroup.get('requirement_type_id').disable();
    }
  }

  selectedRequirementType() {
    if (this.requirement_type_select.selectedItems[0]) {
      this._selected_requirement_type = this.requirement_type_select
        .selectedItems[0].value as RequirementType;
    } else {
      this.formGroup.patchValue({ is_required: false });
      this.boxChecked();
      this._selected_requirement_type = null;
    }
  }

  ngOnInit() {
    // Temporary
    // added the binding to get work instructions
    // so this should be made reddundant once
    // every other component that uses it is 
    // updated

    // Even better, we don't need tho whole model
    // create an input to get the notes list and the
    // work instruction id, unless its a pain

    if(!this.work_instruction) {
      this.work_instruction_service.work_instruction$
      .subscribe(data => {
        this.work_instruction = data;
      });
    }
    
  }

  onClose() {
    this.close.next(true);
  }

  onSubmit() {
    this.massStore();
  }

  massStore() {
    this._is_actioning = true;
    const tempnote = {
      ids: [this.work_instruction.id],
      note: this.formGroup.get('is_required').value
        ? '(' +
          this._selected_requirement_type.name +
          ') ' +
          this.formGroup.get('note').value
        : this.formGroup.get('note').value,
      is_special_requirement:
        this.formGroup.get('is_required').value === null
          ? false
          : this.formGroup.get('is_required').value,
      requirement_type_id: this._selected_requirement_type
        ? this._selected_requirement_type.id
        : null,
      requirement_type: this._selected_requirement_type
    };

    this.work_instruction_note_service
      .massStore(tempnote)
      .subscribe( data => {
        data = data === 1 ? data + ' work instruction' : data + ' work instructions';
        this.updateNotes();
        this.toastr_service.success(
          'Work Instruction note was added to ' + data,
          'Success'
        );
        this.reset();

        this.formGroup.reset();
        this.formGroup.patchValue({ is_required: false });
        this.boxChecked();

        this._is_actioning = false;

        this.updated.emit();
      },
      error1 => (this._is_actioning = false)
    );
  }

  updateNotes() {
    this.work_instruction_note_service
      .getNotes(this.work_instruction.id)
      .subscribe(data => {
        this.work_instruction.work_instruction_notes = data;
      });
  }

  reset() {
    this.formGroup.reset();
    this.isCollapsed = true;
  }

  closeNotes() {
    this.close.next(true);
    this.reset();
  }
}
