import {Deserializable} from '../deserializable.model';
import {Department} from '../departments/department.model';

export class TaskCategory implements Deserializable {

  id: number;
  name: string;
  code: string;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  department_ids: number[];

  departments: Department[];

  // tasks: Task[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  constructor() {
    this.id   = 0;
    this.name = '';
    this.code = '';

    this.created_at     = '';
    this.updated_at     = '';
    this.deactivated_at = '';
    this.deleted_at     = '';

    //this.department_ids = [];

    //this.departments = [];
  }
}
