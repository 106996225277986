import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import { VatRate } from '../../../../shared/models/billing/vat-rate.model';
import { AuthService } from '../../../../shared/users/auth.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class VatRatesService {

  /**
   * @type {BehaviorSubject<VatRate>}
   * @private
   */
  private _vat_rate = new BehaviorSubject<VatRate>(new VatRate());

  /**
   * @type {Observable<VatRate>}
   * @private
   */
  public _vat_rate$ = this._vat_rate.asObservable();

  /**
   * constructor
   * @param {HttpClient} http
   */
  constructor (private http: HttpClient) {}

  /****************************** Behaviour Subject ************************/

  /**
   * @param {VatRate} vat_rate
   */
  setVatRate(vat_rate: VatRate): void {
    this._vat_rate.next(vat_rate);
  }

  /******************************** API CALLS ******************************/

  /**
   * @param {boolean} with_deactivated
   * @returns {Observable<VatRate[]>}
   */
  getVatRates (with_deactivated: boolean) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'with_deactivated': with_deactivated.toString()
      }
    };

    return this.http.get<VatRate[]>(environment.base_url + '/vat-rate/get-vat-rates', httpOptions)
      .map((vat_rates: VatRate[]) => vat_rates.map((vat_rate) => new VatRate().deserialize(vat_rate)));
  }

  /**
   * @param {object} vat_rate
   * @param {number} id
   * @returns {Observable<Object>}
   */
  update (vat_rate: object, id: number) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.http.post(environment.base_url + '/vat-rate/' + id, vat_rate, httpOptions);
  }

  /**
   * @param {object} vat_rate
   * @returns {Observable<Object>}
   */
  store (vat_rate: object) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.http.post(environment.base_url + '/vat-rate', vat_rate, httpOptions);
  }

  /**
   *
   * @param {string} field
   * @param value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate (field: string, value: any, id: number) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    id = id || 0;

    return this.http.post(
      environment.base_url + '/vat-rate/check-for-duplicate',
      {field: field, value: value, id: id},
      httpOptions);
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archiveVatRates (ids: object) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.http.post(environment.base_url + '/vat-rate/archive-vat-rates', ids, httpOptions);
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activateVatRates (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.http.post(environment.base_url + '/vat-rate/activate-vat-rates', ids, httpOptions);
  }

  /**
   * @param {number} id
   * @returns {Observable<Object>}
   */
  makePrimary (id: number) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
      }
    };
    return this.http.post<VatRate>(environment.base_url + '/vat-rate/make-primary', {id: id.toString()}, httpOptions);
  }


  /************************** Deprecated ************************/
  /**
   * @deprecated
   * @returns {Observable<Object>}
   */
  getVatRatesSelectList () {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.http.get<VatRate[]>(environment.base_url + '/vat-rate/get-vat-rates-select-list', httpOptions);
  }

}
