import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../../../../../../environments/environment';
import { WorkInstruction } from '../../../../../shared/models/work-instruction/work-instruction.model';
import { AuthService } from '../../../../../shared/users/auth.service';

@Injectable()
export class EnquiriesServices {

  /**
   * @type {BehaviorSubject<WorkInstruction>}
   */
  private _enquiry = new BehaviorSubject<WorkInstruction>(new WorkInstruction());

  /**
   * @type {Observable<WorkInstruction>}
   */
  public _enquiry$ = this._enquiry.asObservable();

  constructor(private httpClient: HttpClient) {}

  /******************************************* GET METHODS *****************************************/
  getEnquiries (client_account_id: number, contract_id: number, department_id: number, quote_requested: any, closed:any,
                from:number, page_limit:number, search_term:string) {

    client_account_id = !client_account_id ? 0 : client_account_id;
    contract_id       = !contract_id ? 0 : contract_id;
    department_id = !department_id ? 0 : department_id;
    search_term = !search_term ? 'null': search_term;

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString(),
        'contract_id': contract_id.toString(),
        'department_id': department_id.toString(),
        'quote_requested': null,
        'closed': closed,
        'from': from.toString(),
        'page_limit': page_limit.toString(),
        'search_term': search_term
      }
    };

    if(quote_requested !== null){
      httpOptions.params.quote_requested = quote_requested.toString();
    }

    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/enquiries/get-enquiries', httpOptions)
      .map((enquiries: WorkInstruction[]) => enquiries.map((enquiry) => new WorkInstruction().deserialize(enquiry)));
  }

  /******************************************* POST METHODS *****************************************/
  create (data: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/enquiries/create-enquiry', data, httpOptions);
  }

  /**
   *
   * @param {number} id
   * @param {object} data
   * @returns {Observable<Object>}
   */
  update (id: number, data: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'id': id.toString()
      }
    };

    return this.httpClient.post(environment.base_url + '/enquiries/update-enquiry', data, httpOptions);
  }

  /******************************************** ARCHIVE METHODS ********************************************/
  archive (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/enquiries/archive', ids, httpOptions);
  }

  activate (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/enquiries/activate', ids, httpOptions);
  }

  /********************************************* MISC METHODS ***********************************************/

  /**
   *
   * @param {WorkInstruction} enquiry
   */
  setEnquiry(enquiry: WorkInstruction){
    if (enquiry === null){
      enquiry = new WorkInstruction();
    }

    this._enquiry.next(enquiry);
  }
}
