import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { WorkInstructionProgress } from '../../../../../shared/models/work-instruction/work-instruction-progress.model';
import { AuthService } from '../../../../../shared/users/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class WorkInstructionProgressesService {

  /**
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient){}

  public store (value: number, date: string, work_instruction_id: number): Observable<WorkInstructionProgress> {

    const payload = {
      value: value,
      date: date,
      work_instruction_id: work_instruction_id
    };

    return this.httpClient.post<WorkInstructionProgress>(
      environment.base_url + '/work-instruction-progress',
      payload,
      {params: {'auth_token': AuthService.getAuthToken()}}
    );
  }
}
