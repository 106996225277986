import {Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../../shared/users/auth.service';
import {Department} from '../../../../shared/models/departments/department.model';

@Injectable()
export class DepartmentsService {

  /**
   * @type {BehaviorSubject<Department>}
   */
  private _department = new BehaviorSubject<Department>(new Department());

  /**
   * @type {Observable<Department>}
   */
  public _department$ = this._department.asObservable();

  /**
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient){}


  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @returns {Observable<Department[]>}
   */
  getDepartmentsDatatable(){
    return this.httpClient.get<Department[]>(environment.base_url + '/departments/departments-datatable', {params: {'auth_token': AuthService.getAuthToken()}})
      .map((departments: Department[]) => departments.map((department) => new Department().deserialize(department)));
  }

  /**
   *
   * @returns {Observable<Department[]>}
   */
  getDepartmentList(@Optional() contract_id : number = 0){
    return this.httpClient.get<Department[]>(environment.base_url + '/departments/get-department-list', {params: {'auth_token': AuthService.getAuthToken(), 'contract_id': contract_id.toString()}})
      .map((departments: Department[]) => departments.map((department) => new Department().deserialize(department)));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<Department>}
   */
  getDepartment(id: number){
    return this.httpClient.get<Department>(environment.base_url + '/departments/get-department', {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} data
   * @returns {Observable<Object>}
   */
  create(data: object){
    return this.httpClient.post(environment.base_url + '/departments/create-department', data, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {number} id
   * @param {object} data
   * @returns {Observable<Object>}
   */
  update(id: number, data: object){
    return this.httpClient.post(environment.base_url + '/departments/update-department', data, {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ ARCHIVE METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archive (ids: object) {
    return this.httpClient.post(environment.base_url + '/departments/archive', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activate (ids: object) {
    return this.httpClient.post(environment.base_url + '/departments/activate', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }


  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {Department} department
   */
  setDepartment(department: Department){
    if (department === null){
      department = new Department();
    }

    this._department.next(department);
  }

  /**
   *
   * @param {string} field
   * @param {string} value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate (field: string, value: string, id: number) {
    return this.httpClient.post(
      environment.base_url + '/departments/check-for-duplicate',

      {field: field, value: value},

      {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}}
      );
  }

  checkForDuplicateDepartment(name: string, contract_id: number){

    return this.httpClient.post(
      environment.base_url + '/departments/check-for-duplicate-department',
      { name: name, contract_id: contract_id.toString() },
      {params: {'auth_token': AuthService.getAuthToken()}}
    )
  }
}
