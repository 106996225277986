import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../../shared/users/auth.service';
import { WorkInstructionStagesInterface } from '../interfaces/work-instruction-stages.interface';
import { environment } from '../../../../../../environments/environment';

@Injectable()
export class WorkInstructionStagesService {

  constructor(private http: HttpClient){}

  getWorkInstructionStages(){
    return this.http.get<WorkInstructionStagesInterface[]>(environment.base_url + '/work-instructions/get-stages', {params: {'auth_token': AuthService.getAuthToken()}});
  }
}
