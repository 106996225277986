import {Deserializable} from '../deserializable.model';
import {Contract} from '../client/contract.model';
import {TaskCategory} from '../work-instruction/task-category.model';

export class Department implements Deserializable{

  id: number;
  name: string;
  code: string;
  contract_id: number;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  task_category_ids: number[];

  contract: Contract;
  task_categories: TaskCategory[];

  //For Contract Builder
  is_collapsed = true;

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
      this.id = 0;
      this.name = '';
      this.code = '';
      //this.contract_id = 0;

      this.created_at = '';
      this.updated_at = '';
      this.deactivated_at = '';
      this.deleted_at = '';

      this.task_category_ids = [];

      this.is_collapsed = true;

      //this.contract = new Contract();
      //this.task_categories = [];
  }

}
