import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {SitesService} from '../../reactive/api/services/sites.service';
import {Site} from '../../../../shared/models/client/site.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SiteNotesService} from '../../reactive/api/services/site-notes.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-site-notes-feed',
  templateUrl: './site-notes-feed.component.html',
  styleUrls: ['./site-notes-feed.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SiteNotesFeedComponent implements OnInit {

  site: Site;

  form: FormGroup;

  isCollapsed = true;

  @Output() close = new EventEmitter<boolean>();

  constructor (private site_service: SitesService,
               private form_builder: FormBuilder,
               private site_note_service: SiteNotesService,
               private toastr_service: ToastrService) {

    this.form = this.form_builder.group({
      note: ['', Validators.required]
    });
  }

  ngOnInit () {
    this.site_service._site$.subscribe(data => {
      this.site = data;
      this.reverseNotes();
    });
  }

  onClose () {
    this.close.next(true);
  }

  onSubmit () {
    this.massStore();
  }

  massStore () {
    this.site_note_service.massStore({site_ids: [this.site.id], note: this.form.get('note').value}).subscribe(data => {
      data = data === 1 ? data + ' LOCATION' : data + ' LOCATIONs';
      this.updateSiteNotes();
      this.toastr_service.success('LOCATION NOTE WAS ADDED TO' + data, 'Success');
      this.form.reset();
    });
  }

  updateSiteNotes(){
    this.site_note_service.getSiteNotes(this.site.id).subscribe(data => {
      this.site.site_notes = data;
      this.reverseNotes();
    })
  }

  reverseNotes(){
    if (this.site.site_notes){
      this.site.site_notes.reverse();
    }
  }
}
