import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-filter-loader',
  templateUrl: './filter-loader.component.html',
  styleUrls: ['./filter-loader.component.scss']
})
export class FilterLoaderComponent implements OnInit{

  @Input() is_loading: boolean;
  @Input() height: number;
  @Input() margin_top: number;

  constructor() { }

  ngOnInit() { }
}
