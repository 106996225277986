import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import { AuthService } from '../../../../../shared/users/auth.service';
import { environment } from '../../../../../../environments/environment';
import { SiteNote } from '../../../../../shared/models/client/site-note.model';
import { Site } from '../../../../../shared/models/client/site.model';

@Injectable()
export class SiteNotesService {

  private site  = new BehaviorSubject<Site>(new Site());
          site$ = this.site.asObservable();

  constructor (private http: HttpClient) {
  }

  getSiteNotes (site_id: number) {
    return this.http.get<SiteNote[]>(environment.base_url + '/sites/' + site_id + '/get-site-notes', {params: {'auth_token': AuthService.getAuthToken(), 'site_id': site_id.toString()}});
  }

  massStore (site_note: object) {
    return this.http.post(environment.base_url + '/sites/create-site-notes', site_note, {params: {'auth_token': AuthService.getAuthToken()}});
  }
}
