import { ClientAccount } from '../client/client-account.model';
import { Deserializable } from '../deserializable.model';
import { BillingCompany } from './billing-company.model';

export class Invoice implements Deserializable {

  id: number;
  billing_company_id: number;
  client_account_id: number;
  filepath: string;
  date: string;
  net: number;
  gross: number;
  vat: number;
  created_at: string;
  updated_at: string;
  emailed_at: string;
  exported_at: string;

  billing_company: BillingCompany;
  client_account: ClientAccount;

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }
}
