import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from '../../../../../shared/users/auth.service';
import { Contract } from '../../../../admin/api/interfaces/contracts.interface';
import { PhotoUpload} from '../interfaces/photos.interface';
import {WorkInstructionPhotoType} from '../../../../../shared/models/work-instruction/work-instruction-photo-type.model';

@Injectable()
export class PhotosService {

  constructor(private httpClient: HttpClient, private auth_service: AuthService){}

  /******************************************* GET METHODS *****************************************/
  getPhotoTypes(){
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };

    return this.httpClient.get<WorkInstructionPhotoType[]>(environment.base_url + '/photo-types/get-photo-types', httpOptions);
  }


  uploadPhoto(data: PhotoUpload[]){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    const formData = new FormData();

    data.forEach(item => {
      formData.append('photos[]', item.photo);
    });
    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(environment.base_url + '/uploads/upload-photo', formData, httpOptions);
  }

  getQuote(id: number){
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'id': id.toString()
      }
    };

    return this.httpClient.get<Contract>(environment.base_url + '/quotes/get-quote', httpOptions);
  }

  /******************************************* POST METHODS *****************************************/
  create(data: object){
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };

    return this.httpClient.post(environment.base_url + '/quotes/create-quote', data, httpOptions);
  }

  update(id: number, data: object){
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'id': id.toString()
      }
    };

    return this.httpClient.post(environment.base_url + '/quotes/update-quote', data, httpOptions);
  }

  /******************************************** ARCHIVE METHODS ********************************************/
  archive (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/archive', ids, httpOptions);
  }

  activate (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/activate', ids, httpOptions);
  }

  /********************************************* MISC METHODS ***********************************************/

}
