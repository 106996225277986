import {Component} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: '[workrate-toast-component]',
  styleUrls: ['./workrate.toast.scss'],
  templateUrl: './workrate.toast.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('1000ms ease', keyframes([
        style({
          opacity: 0,
          'margin-top' : '-40px',
        }),
        style({
          opacity: 0.25,
          'margin-top' : '-30px',
        }),
        style({
          opacity: 0.5,
          'margin-top' : '-20px',
        }),
        style({
          opacity: 0.75,
          'margin-top' : '-10px',
        }),
        style({
          opacity: 1,
        }),
      ]))),
      state('active', style({
        bottom: '0',
        'max-height': '200px',
        'margin-top': '12px',
        'max-width': '400px',
      })),
      transition('active => removed', animate('300ms ease-out', keyframes([
        style({
          opacity: 0.6,
          bottom: 0,
        }),
        style({
          opacity: 0.1,
          bottom: '-3px',
        }),
        style({
          opacity: 0,
          bottom: '-15px',
        }),
      ]))),
    ]),
  ],
})
export class WorkrateToast extends Toast {
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}
