import { AgmMap } from '@agm/core';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Contract } from '../../../../../../shared/models/client/contract.model';
import { Site } from '../../../../../../shared/models/client/site.model';
import { Department } from '../../../../../../shared/models/departments/department.model';
import { WorkInstruction } from '../../../../../../shared/models/work-instruction/work-instruction.model';
import { AuthService } from '../../../../../../shared/users/auth.service';
import { InputMasks } from '../../../../../../utility/helpers/input-masks';
import { RegexPatterns } from '../../../../../../utility/helpers/regex-patterns';
import { ContractsService } from '../../../../../admin/api/services/contracts.service';
import { DepartmentsService } from '../../../../../admin/api/services/departments.service';
import { TeamsService } from '../../../../../admin/api/services/teams.service';
import { VatRatesService } from '../../../../../admin/api/services/vat-rates.service';
import {
  TransitionStages,
  WorkInstructionStages
} from '../../../api/constants/work-instruction-statuses';
import { EnquiriesServices } from '../../../api/services/enquiries.services';
import { QuotesService } from '../../../api/services/quotes.service';
import { SitesService } from '../../../api/services/sites.service';
import { WorkInstructionService } from '../../../api/services/work-instruction.service';
import { DocumentUploadComponent } from '../../documents/document-upload.component';
import { NoteUploadComponent } from '../../notes/note-upload.component';
import { PhotoUploadComponent } from '../../photos/photo-upload.component';
import { Team } from '../../../../../../shared/models/teams/team.model';
import { VatRate } from '../../../../../../shared/models/billing/vat-rate.model';
import { ClientsService } from '../../../../../admin/api/services/clients.service';
import { ClientAccount } from '../../../../../../shared/models/client/client-account.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TaskCreatorComponent } from '../../tasks/task-creator.component';
import { WorkInstructionPriority } from '../../../../../../shared/models/work-instruction/work-instruction-priority.model';
import { LayoutService } from '../../../../../../layout/layout.service';
import { RequirementType } from '../../../../../../shared/models/work-instruction/work-instruction-requirement-type.model';
import { RequirementTypesService } from '../../../../../admin/api/services/requirement-types.service';
import { RequiredInfoModalComponent } from '../required-info-modal/required-info-modal.component';
import { WorkInstructionNote } from '../../../../../../shared/models/work-instruction/work-instruction-note.model';

@Component({
  selector: 'app-work-instructions-form-modal',
  templateUrl: './work-instructions-form-modal.component.html',
  styleUrls: ['./work-instructions-form-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class WorkInstructionsFormModalComponent implements OnInit {
  @Output() refreshTable = new EventEmitter<boolean>();

  @ViewChild('siteMap') map: AgmMap;
  @ViewChild('workInstructionWizardModal') modal: ModalDirective;
  @ViewChild('requiredInfo') required_modal: RequiredInfoModalComponent;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('clientSelect') client_select: NgSelectComponent;
  @ViewChild('contractSelect') contract_select: NgSelectComponent;
  @ViewChild('departmentSelect') department_select: NgSelectComponent;
  @ViewChild('prioritySelect') priority_select: NgSelectComponent;
  @ViewChild('siteSelect') site_select: NgSelectComponent;
  @ViewChild('task_creator') task_creator: TaskCreatorComponent;
  @ViewChild('note_upload') note_upload: NoteUploadComponent;
  @ViewChild('doc_upload') doc_upload: DocumentUploadComponent;
  @ViewChild('photo_upload') photo_upload: PhotoUploadComponent;

  public scrollbarOptions = {
    axis: 'y',
    theme: 'minimal-dark',
    overflow: 'hidden'
  };

  _work_instruction_details: FormGroup;
  _site_details: FormGroup;
  _work_instruction: WorkInstruction;
  _estimated_time_details: FormGroup;
  _type = 0;
  _creating_site = false;
  transition_stages = new TransitionStages();
  _current_stage;
  _clients: ClientAccount[] = [];
  _contracts: Contract[] = [];
  _departments: Department[] = [];
  // _billing_companies: BillingCompany[] = [];
  _priorities = [
    { id: 3, name: 'NON-URGENT' },
    { id: 2, name: 'URGENT' },
    { id: 1, name: 'EMERGENCY' }
  ];
  _high_risk = [{ value: true, name: 'YES' }, { value: false, name: 'NO' }];
  _has_requirements = [
    { value: true, name: 'YES' },
    { value: false, name: 'NO' }
  ];
  _flagged_for_review = [{ value: 1, name: 'YES' }, { value: 0, name: 'NO' }];
  _keys_needed = [{ value: 1, name: 'YES' }, { value: 0, name: 'NO' }];
  _requirement_types: RequirementType[] = [];
  _teams: Team[] = [];
  _vat_rates: VatRate[] = [];
  _sites: Site[] = [];
  _potential_duplicates: Site[] = [];
  _exact_duplicate = false;
  _selected_client: ClientAccount;
  _selected_contract: Contract;
  _selected_department: Department;
  _selected_priority: WorkInstructionPriority;
  _selected_team_id: number = null;
  _selected_due_date = null;
  _selected_vat_rate_id: number = null;
  _selected_high_risk: boolean = null;
  _selected_has_requirements: boolean = null;
  _selected_flagged_for_review: boolean = null;
  _selected_requirements: RequirementType[] = [];
  _selected_requirement_ids: number[] = [];
  _selected_keys_needed: boolean = null;
  _disable_buttons = false;
  disabled_site_creation = false;
  _is_actioning = false;
  hours = null;
  mins = null;
  estimated_time = null;

  form = {
    photos: <any>[],
    notes: <any>[],
    docs: <any>[]
  };

  constructor(
    private team_service: TeamsService,
    private site_service: SitesService,
    private work_instruction_service: WorkInstructionService,
    private department_service: DepartmentsService,
    private contract_service: ContractsService,
    private enquiry_service: EnquiriesServices,
    private quote_service: QuotesService,
    private form_builder: FormBuilder,
    private auth_service: AuthService,
    private toastr_service: ToastrService,
    private regex_patterns: RegexPatterns,
    private vat_rate_service: VatRatesService,
    private client_service: ClientsService,
    public input_masks: InputMasks,
    private layout_service: LayoutService,
    private requirement_service: RequirementTypesService
  ) {
    this._work_instruction = new WorkInstruction();
    this._work_instruction_details = this.setBaseWorkInstructionForm();
    this._site_details = this.setBaseSiteForm();
    this._estimated_time_details = this.setEstimateForm();
    this.resetSelected();
  }

  ngOnInit() {
    this.work_instruction_service.work_instruction$.subscribe(data => {
      this._work_instruction = data;
    });

    // on close update
    this.modal.onHide
    .subscribe( event => {
      this.hours = null;
      this.mins = null;
    });
  }

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  getClients() {
    this.client_service.getClients().subscribe(data => {
      this._clients = data;
    });
  }

  getVatRates() {
    this.vat_rate_service.getVatRates(false).subscribe(data => {
      this._vat_rates = data;
      this.setPrimaryVat();
    });
  }

  setPrimaryVat() {
    this._vat_rates.forEach(vat_rate => {
      if (vat_rate.primary) {
        this._work_instruction_details.patchValue({ vat_rate_id: vat_rate.id });
        this._selected_vat_rate_id = vat_rate.id;
      }
    });
  }

  getRequirementTypes() {
    this.requirement_service.getRequirementTypes(false).subscribe(data => {
      this._requirement_types = data;
    });
  }

  /* +++++++++++++++++++++++++++++++ FORM METHODS ++++++++++++++++++++++++++++++++ */

  setBaseWorkInstructionForm() {
    return this.form_builder.group({
      client_account_id: [
        this._work_instruction.client_account_id,
        Validators.required
      ],
      contract_id: [this._work_instruction.contract_id, Validators.required],
      department_id: [
        this._work_instruction.department_id,
        Validators.required
      ],
      recurring_template_id: [this._work_instruction.recurring_template_id],
      summary: [this._work_instruction.summary, Validators.required],
      description: [
        this._work_instruction.description,
        [Validators.required, Validators.maxLength(3000)]
      ],
      contact_name: [this._work_instruction.contact_name, Validators.required],
      contact_email: [
        this._work_instruction.contact_email,
        [Validators.required, Validators.pattern(this.regex_patterns.email)]
      ],
      contact_number: [
        this._work_instruction.contact_number,
        [Validators.pattern(this.regex_patterns.phone_number)]
      ],
      client_reference_number: [
        this._work_instruction.client_reference_number,
        []
      ],
      po_number: [this._work_instruction.po_number, Validators.maxLength(20)],
      has_special_requirements: [
        this._work_instruction.has_special_requirements,
        Validators.required
      ],
      estimated_time: [this._work_instruction.estimated_time],
    });
  }

  setBaseSiteForm() {
    return this.form_builder.group({
      id: [this._work_instruction.site.id, Validators.required],
      name: [this._work_instruction.site.name, []],
      address_1: [this._work_instruction.site.address_1, []],
      address_2: [this._work_instruction.site.address_2, []],
      address_3: [this._work_instruction.site.address_3, []],
      address_4: [this._work_instruction.site.address_4, []],
      postcode: [this._work_instruction.site.postcode, []],
      latitude: [this._work_instruction.site.latitude, []],
      longitude: [this._work_instruction.site.longitude, []],
      tenant_name: [this._work_instruction.site.tenant_name, []],
      tenant_contact_email: [
        this._work_instruction.site.tenant_contact_email,
        [Validators.pattern(this.regex_patterns.email)]
      ],
      tenant_contact_number: [
        this._work_instruction.site.tenant_contact_number,
        [Validators.pattern(this.regex_patterns.phone_number)]
      ],
      note: [this._work_instruction.site.note, []]
    });
  }

  setEstimateForm() {
    if (this._type === 1) {
      return this.form_builder.group({
        hours: [0, []],
        mins: [0, []]
      });
    } else {
      return this.form_builder.group({
        hours: [1, []],
        mins: [1, []]
      });
    }
  }

  addWorkInstructionFormControl() {
    this._work_instruction_details.addControl(
      'work_instruction_priority_id',
      new FormControl(
        this._work_instruction.work_instruction_priority_id,
        Validators.required
      )
    );
    this._work_instruction_details.addControl(
      'team_id',
      new FormControl(this._selected_team_id)
    );
    this._work_instruction_details.addControl(
      'due_date',
      new FormControl(this._selected_due_date)
    );
    this._work_instruction_details.addControl(
      'vat_rate_id',
      new FormControl(this._selected_vat_rate_id, Validators.required)
    );
    this._work_instruction_details.addControl(
      'is_high_risk',
      new FormControl(this._work_instruction.is_high_risk, Validators.required)
    );
    this._work_instruction_details.addControl(
      'flagged_for_review',
      new FormControl(
        this._work_instruction.flagged_for_review,
        Validators.required
      )
    );
    this._work_instruction_details.addControl(
      'estimated_time',
      new FormControl(
        this._work_instruction.estimated_time,
        Validators.required
      )
    );
  }

  addSiteFormControl() {
    this._site_details.setControl(
      'id',
      new FormControl(this._work_instruction.site.id, Validators.required)
    );
    this._site_details.setControl(
      'name',
      new FormControl(this._work_instruction.site.name, Validators.required)
    );
    this._site_details.setControl(
      'address_1',
      new FormControl(
        this._work_instruction.site.address_1,
        Validators.required
      )
    );
    this._site_details.setControl(
      'postcode',
      new FormControl(
        this._work_instruction.site.postcode,
        Validators.pattern(this.regex_patterns.uk_post_code)
      )
    );
    this._site_details.setControl(
      'tenant_name',
      new FormControl(this._work_instruction.site.tenant_name)
    );
    this._site_details.setControl(
      'contact_number',
      new FormControl(
        this._work_instruction.site.tenant_contact_number,
        Validators.pattern(this.regex_patterns.phone_number)
      )
    );
    this._site_details.setControl(
      'contact_email',
      new FormControl(
        this._work_instruction.site.tenant_contact_email,
        Validators.pattern(this.regex_patterns.email)
      )
    );
    this._site_details.setControl(
      'note',
      new FormControl(
        this._work_instruction.site.note,
        Validators.maxLength(500)
      )
    );
  }

  /* +++++++++++++++++++++++++++++++ SELECTED METHODS ++++++++++++++++++++++++++++++++ */

  selectedClient() {
    this._work_instruction_details.patchValue({ contract_id: null });
    this._work_instruction_details.patchValue({ department_id: null });
    this._site_details.reset();

    if (this.client_select.selectedItems.length > 0) {
      this._selected_client = this.client_select.selectedItems[0]
        .value as ClientAccount;

      this._contracts = this._selected_client.contracts;

      this._sites = this._selected_client.sites;

      this._work_instruction_details.patchValue({
        contact_name: this._selected_client.primary_contact_name
      });

      this._work_instruction_details.patchValue({
        contact_email: this._selected_client.primary_contact_email
      });

      this._work_instruction_details.patchValue({
        contact_number: this._selected_client.primary_contact_number
      });
    }
  }

  selectedContract() {
    this._work_instruction_details.patchValue({ department_id: null });

    if (this.contract_select.selectedItems.length > 0) {
      this._selected_contract = this.contract_select.selectedItems[0]
        .value as Contract;
      this._departments = this._selected_contract.departments;

      if (this._type === TransitionStages.WORK_INSTRUCTION) {
        this._work_instruction_details.patchValue({ team_id: null });
        this._teams = this._selected_contract.teams;
      }
    }
  }

  selectedDepartment() {
    if (this.department_select.selectedItems.length > 0) {
      this._selected_department = this.department_select.selectedItems[0]
        .value as Department;
    }
  }

  selectedPriority() {
    if (this.priority_select.selectedItems.length > 0) {
      this._selected_priority = this.priority_select.selectedItems[0]
        .value as WorkInstructionPriority;
    }
  }

  selectedTeam() {
    this._selected_team_id = this._work_instruction_details.get(
      'team_id'
    ).value;
  }

  selectedDate() {
    this._selected_due_date = this._work_instruction_details.get(
      'due_date'
    ).value;
  }

  selectedVatRate() {
    this._selected_vat_rate_id = this._work_instruction_details.get(
      'vat_rate_id'
    ).value;
  }

  selectedRequirements() {
    this._selected_requirements = [];
    const requirements_copy = this._requirement_types.slice(0);

    this._selected_requirement_ids.forEach(item => {
      requirements_copy.forEach(object => {
        if (object.id === item) {
          this._selected_requirements.push(object);
        }
      });
    });
  }

  selectedSite() {
    if (this.site_select.selectedItems.length > 0) {
      const selected_site = this.site_select.selectedItems[0].value as Site;

      this._work_instruction.site = selected_site;
      this._work_instruction.site_id = selected_site.id;

      this._site_details = this.setBaseSiteForm();

      this.map.triggerResize();

      this.cancelCreation();
    }
  }

  resetSelected() {
    this._selected_client = new ClientAccount();
    this._selected_contract = new Contract();
    this._selected_department = new Department();
    // this._selected_billing_company = new BillingCompany();
    this._selected_priority = new WorkInstructionPriority();
  }

  /* +++++++++++++++++++++++++++++++ SITE METHODS ++++++++++++++++++++++++++++++++ */

  addNewSite() {
    this._site_details.reset();

    this._work_instruction.site = new Site();
    this._work_instruction.site.client_account_id = this._work_instruction.client_account_id;
    this._creating_site = true;

    this._exact_duplicate = false;
    this._potential_duplicates = [];

    this.addSiteFormControl();
    this.siteOnChanges();
  }

  cancelCreation() {
    this._creating_site = false;
    this._site_details.reset();
    this._site_details = this.setBaseSiteForm();

    this._exact_duplicate = false;
    this._potential_duplicates = [];
  }

  createSite() {
    this.disabled_site_creation = true;

    if (
      !this._site_details.get('latitude').value &&
      !this._site_details.get('longitude').value
    ) {
      if (
        this._site_details.controls.postcode.valid &&
        this._site_details.get('postcode').value
      ) {
        this.site_service
          .getGpsDetails(this._site_details.get('postcode').value)
          .subscribe(data => {
            this._site_details.patchValue({
              latitude: data.lat,
              longitude: data.long
            });

            this.checkForDuplicateSite();
            this.storeSite();
          });
      } else {
        this._site_details.patchValue({
          latitude: null,
          longitude: null
        });

        this.checkForDuplicateSite();
        this.storeSite();
      }
    } else {
      this.storeSite();
    }
  }

  storeSite() {
    this.site_service.store(this._work_instruction.site).subscribe(data => {
      if (data) {
        this.site_service
          .getSiteList(this._selected_client.id)
          .subscribe(sites => {
            this._sites = sites;
            this._selected_client.sites = sites;
          });

        this._creating_site = false;
        this._work_instruction.site = data;
        this._work_instruction.site_id = data.id;

        this._site_details = this.setBaseSiteForm();

        this.toastr_service.success('LOCATION CREATED');

        (<any>window).gtag('event', 'Location Created On The Fly', {
          event_category: 'Location',
          event_label: this._selected_client.name,
          value: AuthService.getUserId()
        });

        this.disabled_site_creation = false;
      }
    });
  }

  getGpsDetails() {
    if (
      this._site_details.controls.postcode.valid &&
      this._site_details.get('postcode').value
    ) {
      this.site_service
        .getGpsDetails(this._site_details.get('postcode').value)
        .subscribe(data => {
          this._site_details.patchValue({
            latitude: data.lat,
            longitude: data.long
          });

          this.checkForDuplicateSite();
        });
    } else {
      this._site_details.patchValue({
        latitude: null,
        longitude: null
      });

      this.checkForDuplicateSite();
    }
  }

  checkForDuplicateSite() {
    this._exact_duplicate = false;
    this._potential_duplicates = [];

    this.site_service
      .checkForDuplicateSite(
        this._selected_client.id,
        this._work_instruction.site
      )
      .subscribe(data => {
        if (data === true) {
          this._exact_duplicate = data;
        } else {
          this._potential_duplicates = data;
        }
      });
  }

  /* +++++++++++++++++++++++++++++++ TASK METHODS ++++++++++++++++++++++++++++++++ */

  addTaskData() {
    this._work_instruction.tasks = [];
    this._work_instruction.net_invoice_value = 0;
    this._work_instruction.gross_invoice_value = 0;

    this.task_creator.tasks.forEach(task => {
      task.to_sync = this._work_instruction.to_sync;
      if (task.due_date_holder) {
        const due_date = new Date(task.due_date_holder);

        const formatted_date =
          due_date.getFullYear() +
          '-' +
          (due_date.getMonth() + 1) +
          '-' +
          due_date.getDate();

        task.due_date = formatted_date.toString();
      }

      this.task_creator.selectedCategory(task);
      this.task_creator.selectedTeam(task);
      this.task_creator.selectedVatRate(task);

      this._work_instruction.net_invoice_value =
        this._work_instruction.net_invoice_value +
        +(task.unit_value * task.quantity);

      if (this._type === TransitionStages.WORK_INSTRUCTION) {
        this._work_instruction.gross_invoice_value =
          this._work_instruction.gross_invoice_value +
          +(task.unit_value * task.quantity * (1 + task.vat_rate.value / 100));
      } else {
        this._work_instruction.gross_invoice_value = this._work_instruction.net_invoice_value;
      }

      this._work_instruction.tasks.push(task);
    });
  }

  latestDueDate(): string {
    if (this.task_creator.tasks[0].due_date_holder === null) {
      this._work_instruction.due_at = null;
    } else {
      const due_at = new Date(
        Math.max.apply(
          null,
          this.task_creator.tasks.map(function(task) {
            return new Date(task.due_date_holder);
          })
        )
      );
      const formatted_date =
        due_at.getFullYear() +
        '-' +
        (due_at.getMonth() + 1) +
        '-' +
        due_at.getDate();
      return formatted_date.toString();
    }
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  createWorkInstruction() {
    this._is_actioning = true;
    this._work_instruction.to_sync = true;
    this.addTaskData();
    this.calculateTimeTaken();
    this._work_instruction.estimated_time = this.estimated_time;
    this._work_instruction.requirement_type_ids = this._selected_requirement_ids;
    this._work_instruction.work_instruction_photos = this.photo_upload._photos;
    this._work_instruction.documents = this.doc_upload._documents;
    this._work_instruction.notes = this.note_upload._notes;

    const auditNote = new WorkInstructionNote();
    const keyNote   = new WorkInstructionNote();

    if (this._work_instruction.id === 0) {
      this._work_instruction.created_by_user_id = AuthService.getUserId();

      if (this._type === TransitionStages.QUOTE) {
        this._work_instruction.work_instruction_stage_id =
          WorkInstructionStages.QUOTE_SUPPLIED;

        auditNote.note = 'CREATED QUOTE';
        auditNote.created_by =
          AuthService.getLoggedInUserFullName() +
          ' (' +
          AuthService.getLoggedUserName() +
          ')';
        this._work_instruction.notes.push(auditNote);

        this.quote_service.create(this._work_instruction).subscribe(
          data => {
            if (data) {
              (<any>window).gtag('event', 'Quote Supplied', {
                event_category: 'Quote',
                event_label: this._selected_client.name,
                value: AuthService.getUserId()
              });
              this.toastr_service.success('QUOTE CREATED');
              this._is_actioning = false;
              this.hide();
              this.layout_service.setRefreshTable(true);
              this.refreshTable.emit(true);
            }
          },
          error1 => (this._is_actioning = false)
        );
      } else {
        if (this.task_creator.due_date === null) {
        } else {
          this._work_instruction.due_at = this.latestDueDate();
        }
        this._work_instruction.work_instruction_stage_id =
          WorkInstructionStages.PENDING;

        auditNote.note = 'CREATED WORK INSTRUCTION';
        auditNote.created_by =
          AuthService.getLoggedInUserFullName() +
          ' (' +
          AuthService.getLoggedUserName() +
          ')';
        this._work_instruction.notes.push(auditNote);
        this._work_instruction.start_date = this._work_instruction.due_at;
        this.work_instruction_service.create(this._work_instruction).subscribe(
          data => {
            if (data) {
              (<any>window).gtag('event', 'Work Instruction Created', {
                event_category: 'Work Instruction',
                event_label: this._selected_client.name,
                value: AuthService.getUserId()
              });
              this.toastr_service.success('WORK INSTRUCTION CREATED');
              this._is_actioning = false;
              this.hide();
              this.layout_service.setRefreshTable(true);
              this.refreshTable.emit(true);
            }
          },
          error1 => (this._is_actioning = false)
        );
      }
    } else {
      this._work_instruction.updated_by_user_id = AuthService.getUserId();

      if (this._type === TransitionStages.QUOTE) {
        this._work_instruction.work_instruction_stage_id =
          WorkInstructionStages.QUOTE_SUPPLIED;

        auditNote.note = 'CONVERTED TO QUOTE';
        auditNote.created_by =
          AuthService.getLoggedInUserFullName() +
          ' (' +
          AuthService.getLoggedUserName() +
          ')';
        this._work_instruction.notes.push(auditNote);

        this.quote_service
          .update(this._work_instruction.id, this._work_instruction)
          .subscribe(
            data => {
              if (data) {
                (<any>window).gtag('event', 'Quote Supplied From Enquiry', {
                  event_category: 'Quote',
                  event_label: this._selected_client.name,
                  value: AuthService.getUserId()
                });
                this.toastr_service.success('QUOTE CREATED');
                this._is_actioning = false;
                this.hide();
                this.layout_service.setRefreshTable(true);
                this.refreshTable.emit(true);
              }
            },
            error1 => (this._is_actioning = false)
          );
      } else {
        this._work_instruction.due_at = this.latestDueDate();
        this._work_instruction.work_instruction_stage_id =
          WorkInstructionStages.PENDING;

        auditNote.note = 'CONVERTED TO WORK INSTRUCTION';
        auditNote.created_by =
          AuthService.getLoggedInUserFullName() +
          ' (' +
          AuthService.getLoggedUserName() +
          ')';

        this._work_instruction.notes.push(keyNote);
        this._work_instruction.notes.push(auditNote);

        this.work_instruction_service
          .update(this._work_instruction.id, this._work_instruction)
          .subscribe(
            data => {
              if (data) {
                if (this._current_stage === WorkInstructionStages.ENQUIRY) {
                  (<any>window).gtag(
                    'event',
                    'Work Instruction Created From Enquiry',
                    {
                      event_category: 'Work Instruction',
                      event_label: this._selected_client.name,
                      value: AuthService.getUserId()
                    }
                  );
                } else {
                  (<any>window).gtag(
                    'event',
                    'Work Instruction Created From Quote',
                    {
                      event_category: 'Work Instruction',
                      event_label: this._selected_client.name,
                      value: AuthService.getUserId()
                    }
                  );
                }
                this.toastr_service.success('WORK INSTRUCTION CREATED');
                this._is_actioning = false;
                this.hide();
                this.layout_service.setRefreshTable(true);
                this.refreshTable.emit(true);
              }
            },
            error1 => (this._is_actioning = false)
          );
      }
    }
  }

  /* +++++++++++++++++++++++++++++++ MODAL METHODS +++++++++++++++++++++++++++++++ */

  show(work_instruction: WorkInstruction, type: number) {
    this._type = type;
    this._work_instruction = work_instruction;
    if (
      this._work_instruction.work_instruction_stage_id ===
      WorkInstructionStages.ENQUIRY
    ) {
      this._work_instruction.has_special_requirements = null;
    } else {
      this._work_instruction.has_special_requirements = this._work_instruction
        .has_special_requirements
        ? true
        : false;
    }
    this._work_instruction_details = this.setBaseWorkInstructionForm();
    this._site_details = this.setBaseSiteForm();
    this._current_stage = work_instruction.work_instruction_stage_id;
    this.getRequirementTypes();

    if (this._type === TransitionStages.WORK_INSTRUCTION) {
      this.getVatRates();
      this.addWorkInstructionFormControl();
    }

    this.getClients();

    if (this._work_instruction.id > 0) {
      if (
        this._work_instruction.work_instruction_stage_id ===
        WorkInstructionStages.ENQUIRY
      ) {
        this._work_instruction.site = new Site();
      }

      this._selected_client = this._work_instruction.client_account;
      this._contracts = this._work_instruction.client_account.contracts;
      // this._billing_companies           = this._work_instruction.client_account.billing_companies;
      this._sites = this._work_instruction.client_account.sites;
      this._selected_contract = this._work_instruction.contract;
      this._departments = this._selected_contract.departments;

      if (this._work_instruction.department) {
        this.task_creator.selected_department = this._work_instruction.department;
      }

      if (this._type === TransitionStages.WORK_INSTRUCTION) {
        this._teams = this._selected_contract.teams;

        if (
          this._work_instruction.work_instruction_stage_id ===
            WorkInstructionStages.QUOTE_SUPPLIED ||
          this._work_instruction.work_instruction_stage_id ===
            WorkInstructionStages.QUOTE_ACCEPTED ||
          this._work_instruction.work_instruction_stage_id ===
            WorkInstructionStages.QUOTE_REJECTED
        ) {
          this._selected_department = this._work_instruction.department;
          this._selected_requirement_ids = this._work_instruction.requirement_types
            .slice(0)
            .map(item => item.id);
          this._selected_requirements = this._work_instruction.requirement_types;
          // this._selected_billing_company  = this._work_instruction.billing_company;
          this.task_creator.selected_contract = this._work_instruction.contract;
          this.task_creator.selected_department = this._work_instruction.department;
          this.photo_upload._existing_photos = this._work_instruction.work_instruction_photos;
          this.doc_upload._existing_documents = this._work_instruction.documents;
          this.note_upload._exisiting_notes = this._work_instruction.work_instruction_notes;
        }
      }

      this.modal.show();
    } else {
      this.modal.show();
    }

    this.workInstructionOnChanges();
  }

  reset() {
    this.task_creator.reset();

    this.photo_upload._photos = [];
    this.photo_upload.formGroup.reset();
    this.doc_upload._documents = [];
    this.doc_upload.formGroup.reset();
    this.note_upload._notes = [];
    this.note_upload.formGroup.reset();

    this.resetSelected();
    this._selected_team_id = null;
    this._selected_due_date = null;
    this._selected_vat_rate_id = null;
    this._selected_high_risk = null;
    this._selected_has_requirements = null;
    this._selected_flagged_for_review = null;
    this._selected_requirements = [];
    this._selected_requirement_ids = [];
    this._selected_keys_needed = null;

    this._work_instruction = new WorkInstruction();

    this._disable_buttons = false;

    this.stepper.reset();
    this._type = 0;
  }

  hide() {
    this.reset();
    this.modal.hide();
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  workInstructionOnChanges() {
    this._work_instruction_details.valueChanges.subscribe(values => {
      this._work_instruction.deserialize(values);
    });
  }

  siteOnChanges() {
    this._site_details.valueChanges.subscribe(values => {
      this._work_instruction.site.deserialize(values);
    });
  }

  calculateTimeTaken() {
    let total = 0;

    total += this.hours * 60;
    total += this.mins;

    this.estimated_time = total;
  }
}
