import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {RequirementType} from '../../../../../../shared/models/work-instruction/work-instruction-requirement-type.model';
import {RequirementTypesService} from '../../../../../admin/api/services/requirement-types.service';
import {WorkInstructionService} from '../../../api/services/work-instruction.service';
import {WorkInstruction} from '../../../../../../shared/models/work-instruction/work-instruction.model';
import {ModalDirective} from 'ngx-bootstrap';
import {TransitionStages, WorkInstructionStages} from '../../../api/constants/work-instruction-statuses';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../../shared/users/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({ selector: 'app-required-info-modal', templateUrl: './required-info-modal.component.html', styleUrls: ['./required-info-modal.component.scss'] })
export class RequiredInfoModalComponent implements OnInit {
  @ViewChild('requiredInfoModal') modal: ModalDirective;
  @Output() refreshData = new EventEmitter<boolean>();

  _work_instruction: WorkInstruction;
  _required_form: FormGroup;
  _requirement_types: RequirementType[]     = [];
  _selected_requirements: RequirementType[] = [];
  _selected_requirement_ids: number[]       = [];

  _is_actioning = false;

  constructor(private requirement_service: RequirementTypesService, private work_instruction_service: WorkInstructionService, private form_builder: FormBuilder, private toastr_service: ToastrService,) {
    this._required_form = this.setForm();
    this._work_instruction = new WorkInstruction();
  }

  ngOnInit() {}

  getRequirementTypes(){
    this.requirement_service.getRequirementTypes(false).subscribe(data => {
      this._requirement_types = data.filter(item => !this._work_instruction.requirement_type_ids.includes(item.id));
    });
  }

  /* +++++++++++++++++++++++++++++++ MODAL METHODS ++++++++++++++++++++++++++++++++ */

  show(work_instruction: WorkInstruction) {
    this._work_instruction = work_instruction;
    this._work_instruction.requirement_type_ids = this._work_instruction.requirement_types.map(item => item.id);
    this._required_form = this.setForm();
    this.getRequirementTypes();
    this.modal.show();
  }

  hide() {
    this.reset();
    this.modal.hide();
  }

  reset(){
    this._required_form.reset();
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  update(){
    this._is_actioning = true;
    this._work_instruction.updated_by_user_id = AuthService.getUserId();

      this._work_instruction.has_special_requirements = true;
      this._work_instruction.requirement_type_ids = this._selected_requirement_ids;
      this.work_instruction_service.update(this._work_instruction.id, this._work_instruction).subscribe(data => {
        if (data) {
          this.refreshData.emit(true);
          this.toastr_service.success('UPDATED');
          this.hide();
          this._is_actioning = false;
        }
      }, error1 => this._is_actioning = false);
  }

  setForm(){
    return this.form_builder.group({
      required_type_ids: [this._selected_requirement_ids, Validators.required],
    });
  }
}
