import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocumentType} from '../../../../../shared/models/documents/document-type.model';
import {Document} from '../../../../../shared/models/documents/document.model';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ToastrService} from 'ngx-toastr';
import {DocumentsService} from '../../api/services/documents.service';
import {RequirementType} from '../../../../../shared/models/work-instruction/work-instruction-requirement-type.model';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  @ViewChild('documentTypeSelect') type_select: NgSelectComponent;
  @ViewChild('requirementTypeSelect') requirement_type_select: NgSelectComponent;

  @Input() has_requirements                = false;
  @Input() requirements: RequirementType[] = [];

  _types: DocumentType[] = [];
  _selected_document_type: DocumentType;
  _selected_requirement_type:RequirementType;

  _existing_documents: Document[] = [];
  _documents: Document[] = [];

  formGroup: FormGroup;

  _is_required = false;

  constructor(private document_service: DocumentsService, private form_builder: FormBuilder, private toastr_service: ToastrService) {
    this.formGroup = this.form_builder.group({
      'type_select': [null, Validators.required],
      'is_required': [this._is_required],
      'requirement_type_id': [{value:null, disabled:true}],
      'file_input': []
    })
  }

  ngOnInit() {
    this.getDocumentTypes();
  }

  boxChecked(){
    if (this.formGroup.get('is_required').value){
      this.formGroup.get('requirement_type_id').setValidators(Validators.required);
      this.formGroup.get('requirement_type_id').enable();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
    } else{
      this._selected_requirement_type = null;
      this.formGroup.get('requirement_type_id').reset();
      this.formGroup.get('requirement_type_id').clearValidators();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
      this.formGroup.get('requirement_type_id').disable();
    }
  }

  getDocumentTypes(){
    this.document_service.getDocumentsTypes().subscribe(data => {
      this._types = data;
    });
  }

  selectedDocumentType(){
    if(this.type_select.selectedItems.length > 0){
      this._selected_document_type = this.type_select.selectedItems[0].value as DocumentType;
    }
  }

  selectedRequirementType(){
    if (this.requirement_type_select.selectedItems[0]){
      this._selected_requirement_type = this.requirement_type_select.selectedItems[0].value as RequirementType;
    } else{
      this.formGroup.patchValue({'is_required': false});
      this.boxChecked();
      this._selected_requirement_type = null;
    }
  }

  handleFileInput(files: FileList) {
    Array.from(files).forEach(data => {
      if (data.size > 10000000) {
        this.toastr_service.error('FILE ' + data.name + ' EXCEEDS FILE SIZE LIMIT (10MB)');
      }else{
        const document = new Document();

        document.name                   = this.formGroup.get('is_required').value ? '(' + this._selected_requirement_type.name + ') ' + data.name : data.name;
        document.file                   = data;
        document.document_type          = this._selected_document_type;
        document.document_type_id       = this._selected_document_type.id;
        document.is_special_requirement = !!this.formGroup.get('is_required').value;
        document.requirement_type_id    = this._selected_requirement_type ? this._selected_requirement_type.id : null;
        document.requirement_type       = this._selected_requirement_type;

        this._documents.push(document);

        this.formGroup.reset();
        this.formGroup.patchValue({'is_required': false});
        this.boxChecked();
      }
    });
  }

  removeDocument(index: number){
    this._documents.splice(index, 1);
  }
}
