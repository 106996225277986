
import { BehaviorSubject } from 'rxjs/Rx';
import {environment} from '../../../../../../environments/environment';
import {AuthService} from '../../../../../shared/users/auth.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';
import {WorkInstructionNote} from '../../../../../shared/models/work-instruction/work-instruction-note.model';

@Injectable()
export class WorkInstructionNotesService {

  private work_instruction  = new BehaviorSubject<WorkInstruction>(new WorkInstruction());

  work_instruction$ = this.work_instruction.asObservable();

  constructor (private http: HttpClient) {
  }

  getNotes (work_instruction_id: number) {
    return this.http.get<WorkInstructionNote[]>(environment.base_url + '/work-instructions/' + work_instruction_id + '/get-work-instruction-notes', {params: {'auth_token': AuthService.getAuthToken(), 'work_instruction_id': work_instruction_id.toString()}});
  }

  massStore (work_instruction_note: object) {
    return this.http.post(environment.base_url + '/work-instructions/create-work-instruction-notes', work_instruction_note, {params: {'auth_token': AuthService.getAuthToken()}});
  }
}
