import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import { environment } from '../../../../../../../../environments/environment';
import {WorkInstructionPhoto} from '../../../../../../../shared/models/work-instruction/work-instruction-photo.model';

@Component({
  selector: 'app-image-modal-component',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements OnInit {

  @ViewChild('imageModal') modal: ModalDirective;

  _photo = new WorkInstructionPhoto();

  bucket_url = environment.bucket;

  constructor () {
  }

  ngOnInit(){
  }

  /* +++++++++++++++++++++++++++++++ MODAL METHODS ++++++++++++++++++++++++++++++++ */

  show(photo: WorkInstructionPhoto){
    this._photo = photo;
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

}
