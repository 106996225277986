import {Team} from '../teams/team.model';
import {Deserializable} from '../deserializable.model';
import {Cycle} from './cycle.model';
import {RouteModel} from "./route.model";

export class CycleWeek implements Deserializable {

  id: number;
  cycle_id: number;
  start_date: string;
  end_date: string;
  week_number: number;
  published: string;
  routed: boolean;
  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  cycle: Cycle;
  teams: Team[];
  routes: RouteModel[];

  work_instruction_count: number;
  location_count: number;
  task_count: number;

  deserialize(input: any){
    Object.assign(this, input);

    return this;
  }

  constructor(){
    this.id = 0;
    this.week_number = 0;
    this.start_date = '';
    this.end_date = '';
    this.created_at = '';
    this.updated_at = '';
    this.deactivated_at = '';
    this.deleted_at = '';
    this.routed = false;

    this.teams = [];
  }
}
