import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap';
import {TasksService} from 'app/pages/work/reactive/api/services/tasks.service';
import {VatRatesService} from 'app/pages/admin/api/services/vat-rates.service';
import {WorkInstructionService} from 'app/pages/work/reactive/api/services/work-instruction.service';
import {AuthService} from 'app/shared/users/auth.service';
import {DepartmentsService} from 'app/pages/admin/api/services/departments.service';
import {TeamsService} from 'app/pages/admin/api/services/teams.service';
import {ToastrService} from 'ngx-toastr';
import {SiteNotesService} from 'app/pages/work/reactive/api/services/site-notes.service';
import {CategoryService} from 'app/pages/admin/api/services/category.service';
import {Task} from 'app/shared/models/work-instruction/task.model';
import {TaskCategory} from 'app/shared/models/work-instruction/task-category.model';
import {Team} from 'app/shared/models/teams/team.model';
import {VatRate} from 'app/shared/models/billing/vat-rate.model';
import {WorkInstruction} from 'app/shared/models/work-instruction/work-instruction.model';
import * as moment from 'moment';

@Component({
  selector: 'app-task-form-modal-component',
  templateUrl: './task-form-modal.component.html',
  styleUrls: ['./task-form-modal.component.scss'],
})
export class TaskFormModalComponent implements OnInit {

  @ViewChild('taskFormModal') modal: ModalDirective;

  @Output() refreshData = new EventEmitter<boolean>();

  _is_create = true;

  _task_form: FormGroup;
  _task: Task;
  _work_instruction: WorkInstruction;

  _categories: TaskCategory[] = [];
  _teams: Team[] = [];
  _vat_rates: VatRate[] = [];

  _due_date_holder;

  constructor (
    private work_instruction_service: WorkInstructionService,
    private form_builder: FormBuilder,
    private toastr_service: ToastrService,
    private site_note_service: SiteNotesService,
    private auth_service: AuthService,
    private team_service: TeamsService,
    private vat_rate_service: VatRatesService,
    private department_service: DepartmentsService,
    private task_service: TasksService,
    private category_service: CategoryService
  ){
    this._task = new Task();
    this._task_form = this.setForm();
  }

  ngOnInit(){
    this.task_service._task$.subscribe( data => {
      this._task = data;
    })
  }

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  getVatRates(){
    this.vat_rate_service.getVatRates(false).subscribe(data => {
      this._vat_rates = data;
    })
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  createTask(){
    this.task_service.create(this._task).subscribe(data => {
      if(data){
        this.refreshData.emit(true);
        this.toastr_service.success('TASK CREATED');
        this.hide();
      }
    })
  }

  updateTask(){
    this.task_service.update(this._task.id, this._task).subscribe(data => {
      if(data){
        this.refreshData.emit(true);
        this.toastr_service.success('TASK UPDATED');
        this.hide();
      }
    })
  }

  onSubmit() {
    // if(this._task_form.get('due_date').value) {
    //   const date           = new Date(this._task_form.get('due_date').value);
    //   const formatted_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    //   this._task.due_date  = formatted_date.toString();
    // }

    // if (this._task.completed_date){
    //   const date2 = new Date(this._task.completed_date);
    //   const formatted_date2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
    //   this._task.completed_date = formatted_date2.toString();
    // }

    if (this._task.id > 0){
      this.updateTask();
    } else{
      this.createTask();
    }
  }

  /* +++++++++++++++++++++++++++++++ MODAL METHODS ++++++++++++++++++++++++++++++++ */

  show(work_instruction: WorkInstruction){

    this._work_instruction = work_instruction;
    console.log(this._work_instruction);

    this.getVatRates();

    this._categories = this._work_instruction.department.task_categories;
    this._teams = this._work_instruction.contract.teams;

    if (this._task.id > 0) {
      this._is_create = false;
      if(this._task.due_date){ this._due_date_holder = new Date(this._task.due_date); }

      this.modal.show();
    } else {
      this._is_create = true;
      this._due_date_holder = new Date();
      this._task.work_instruction_id = work_instruction.id;
      this.setPrimaryVat();

      this.modal.show();
    }

    this._task_form = this.setForm();
    this.onChanges();
  }

  hide() {
    this.reset();
    this.modal.hide();
  }

  reset() {
    this._is_create = true;
    this._task_form.reset();
  }

  /* +++++++++++++++++++++++++++++++ FORM METHODS ++++++++++++++++++++++++++++++++ */

  setForm(){
    return this.form_builder.group({
      'task_category_id': [this._task.task_category_id, [Validators.required]],
      'description':      [this._task.description,      [Validators.required]],
      'unit_value':       [this._task.unit_value,       [Validators.required]],
      'quantity':         [this._task.quantity,         [Validators.required]],
      'vat_rate_id':      [this._task.vat_rate_id,      [Validators.required]],
      'team_id':          [this._task.team_id],
      'due_date':         [this._due_date_holder],
    });
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  setPrimaryVat(){
    this._vat_rates.forEach(vat_rate => {
      if (vat_rate.primary){
        this._task_form.patchValue({vat_rate_id: vat_rate.id});
        this._task.vat_rate_id = vat_rate.id;
      }
    })
  }

  onChanges(){
    this._task_form.valueChanges.subscribe( values => {
      this._task.deserialize(values);
      if (this._task.due_date === null){
      } else {
        this._task.due_date = moment(this._task.due_date).format('YYYY-MM-DD');
      }
    })
  }
}


