import {Component, EventEmitter, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material';
import {Contract} from '../../../../shared/models/client/contract.model';
import {Team} from '../../../../shared/models/teams/team.model';
import {ToastrService} from 'ngx-toastr';
import {ContractsService} from '../../api/services/contracts.service';
import {TeamsService} from '../../api/services/teams.service';

@Component({
  selector: 'app-team-wizard',
  templateUrl: './team-wizard.component.html',
  styleUrls: ['./team-wizard.component.scss']
})
export class TeamWizardComponent implements OnInit {
  public scrollbarOptions = {axis: 'y', theme: 'minimal-dark'};

  @Output() refreshTable = new EventEmitter<boolean>();

  @ViewChild('teamWizardModal') team_modal: ModalDirective;
  @ViewChild('stepper') stepper: MatStepper;

  _is_create = true;
  _is_deactivated = false;
  _is_contract_builder = false;

  _is_actioning = false;

  _team_form: FormGroup;
  _team: Team;

  _contracts;

  _selected_contracts: Contract[] = [];

  /**
   *
   * @param {ContractsService} contract_service
   * @param {TeamsService} teams_service
   * @param {FormBuilder} form_builder
   * @param {ToastrService} toastr_service
   */
  constructor(
    private contract_service:         ContractsService,
    private teams_service:            TeamsService,
    private form_builder:             FormBuilder,
    private toastr_service:           ToastrService
  ) {

    this._team = new Team();
    this._team_form = this.setForm();
  }

  ngOnInit() {
    this.teams_service._team$.subscribe( data => {
      this._team = data;
    })
  }

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  getContractList() {
    this.contract_service.getContractList().subscribe( data => {
      this._contracts = data;
    });
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  createTeam() {
    this._is_actioning = true;
    this.selectedContracts();

    this.teams_service.create(this._team).subscribe(data => {
      this._is_actioning = false;
      this.success('TEAM CREATED');
      this.hide();
    }, (err) => {this._is_actioning = false});
  }

  updateTeam() {
    this._is_actioning = true;
    this.selectedContracts();

    this.teams_service.update(this._team.id, this._team).subscribe(data => {
      this._is_actioning = false;
      this.success('TEAM UPDATED');
      this.hide();
    }, (err) => {this._is_actioning = false});
  }

  /* +++++++++++++++++++++++++++++++ MODAL METHODS ++++++++++++++++++++++++++++++++ */

  show(is_deactivated: boolean, @Optional() is_contract_builder: boolean = false) {
    this._is_contract_builder  = is_contract_builder;
    this._is_deactivated       = is_deactivated;
    this._team_form            = this.setForm();
    this._selected_contracts   = this._team.contracts || [];

    this.getContractList();

    if (this._team.id > 0) { this._is_create = false; }
    else { this._is_create = true; }

    this.team_modal.show();

    this.onChanges();
  }

  hide() {
    this.reset();
    this.team_modal.hide();
  }

  reset() {
    this.refreshTable.emit(true);
    this._is_create           = true;
    this._selected_contracts  = [];

    if (!this._is_deactivated) { this.stepper.reset(); }
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  generatePin(){
    this._is_actioning = true;
    this.teams_service.generateUniquePin().subscribe(data => {
      this._team_form.patchValue({pin: data});
      this._is_actioning = false;
    }, (err) => {this._is_actioning = false})
  }

  checkForDuplicate(field: string){
    if (this._team_form.get(field).valid) {
      this.teams_service.checkForDuplicate(field, this._team_form.get(field).value, this._team.id).subscribe(data => {
        if (data > 0) {
          this._team_form.get(field).setErrors({'notUnique': true});
        }
      });
    }
  }

  selectedContracts(){
    this._team.contract_ids = this._selected_contracts.map(item => item.id)
  }

  setForm(){
    return this.form_builder.group({
      name: [this._team.name, Validators.required],
      pin: [this._team.pin, [Validators.required, Validators.minLength(4), Validators.maxLength(6), Validators.pattern('[0-9]{4,6}')]]
    });
  }

  onChanges(){
    this._team_form.valueChanges.subscribe( values => {
      this._team.deserialize(values);
    })
  }

  success(message: string) {
    this.toastr_service.success(message);
    this._is_create = true;
  }
}
