import {AgmCoreModule} from '@agm/core';
import {CommonModule} from '@angular/common';
// Angular
// https://angular.io/
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';

import {NgSelectModule} from '@ng-select/ng-select';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import {TextMaskModule} from 'angular2-text-mask';
import {FileUploadModule} from 'ng2-file-upload';
import {Ng2ImgToolsModule} from 'ng2-img-tools';
import {PasswordStrengthBarModule} from 'ng2-password-strength-bar';
import {AccordionModule, CollapseModule} from 'ngx-bootstrap';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
// Angular Material
// https://material.angular.io/
// ngx-bootstrap4
// http://valor-software.com/ngx-bootstrap/index-bs4.html#/
import {TabsModule} from 'ngx-bootstrap/tabs';
import {MalihuScrollbarModule} from 'ngx-malihu-scrollbar/dist/lib';
import {NgxPermissionsModule} from 'ngx-permissions';
import {NgxSelectModule} from 'ngx-select-ex';
// UI Shared Components
import {FooterComponent} from '../layout/footer/footer.component';
import {AccountsService} from '../pages/admin/api/services/accounts.service';
import {BillingCompanyService} from '../pages/admin/api/services/billing-company.service';
import {CategoryService} from '../pages/admin/api/services/category.service';
import {ClientsService} from '../pages/admin/api/services/clients.service';
import {ContractsService} from '../pages/admin/api/services/contracts.service';
import {DepartmentsService} from '../pages/admin/api/services/departments.service';
import {ImportTypeService} from '../pages/admin/api/services/import-type.service';
import {ImportsService} from '../pages/admin/api/services/imports.service';
import {TeamsService} from '../pages/admin/api/services/teams.service';
import {UsersService} from '../pages/admin/api/services/users.service';
import {VatRatesService} from '../pages/admin/api/services/vat-rates.service';
import {ClientWizardComponent} from '../pages/admin/clients/client-wizard/client-wizard.component';
import {CategoryWizardComponent} from '../pages/admin/departments/categories-table/category-wizard/category-wizard.component';
import {DepartmentWizardComponent} from '../pages/admin/departments/departments-table/department-wizard/department-wizard.component';
import {TeamWizardComponent} from '../pages/admin/teams/team-wizard/team-wizard.component';
import {FinanceService} from '../pages/finance/api/services/finance.service';
import {InvoiceService} from '../pages/finance/api/services/invoice.service';
import {UnauthorisedComponent} from '../pages/responses/unauthorised/unauthorised.component';
import {TaskStatuses} from '../pages/work/reactive/api/constants/work-instruction-statuses';
import {DocumentsService} from '../pages/work/reactive/api/services/documents.service';
import {EnquiriesServices} from '../pages/work/reactive/api/services/enquiries.services';
import {NotesService} from '../pages/work/reactive/api/services/notes.service';
import {PhotosService} from '../pages/work/reactive/api/services/photos.service';
import {QuotesService} from '../pages/work/reactive/api/services/quotes.service';
import {SiteNotesService} from '../pages/work/reactive/api/services/site-notes.service';
import {SitesService} from '../pages/work/reactive/api/services/sites.service';
import {TasksService} from '../pages/work/reactive/api/services/tasks.service';
import {WorkInProgressService} from '../pages/work/reactive/api/services/work-in-progress.service';
import {WorkInstructionNotesService} from '../pages/work/reactive/api/services/work-instruction-note.service';
import {WorkInstructionPhotosService} from '../pages/work/reactive/api/services/work-instruction-photos.service';
import {WorkInstructionProgressesService} from '../pages/work/reactive/api/services/work-instruction-progresses.service';
import {WorkInstructionStagesService} from '../pages/work/reactive/api/services/work-instruction-stages.service';
import {WorkInstructionService} from '../pages/work/reactive/api/services/work-instruction.service';
import {TaskFormModalComponent} from '../pages/work/reactive/work-instructions/modals/task-form-modal/task-form-modal.component';
import {CancelTaskModalComponent} from '../pages/work/reactive/work-instructions/tasks/modals/cancel-task-modal/cancel-task-modal.component';
import {CompleteTaskModalComponent} from '../pages/work/reactive/work-instructions/tasks/modals/complete-task-modal/complete-task-modal.component';
import {UnableCompleteTaskModalComponent} from '../pages/work/reactive/work-instructions/tasks/modals/unable-complete-task-modal/unable-complete-task-modal.component';
import {TaskCreatorComponent} from '../pages/work/reactive/work-instructions/tasks/task-creator.component';
import {DateCreatorComponent} from '../pages/work/reactive/recurring-work-instructions/dates/date-creator.component';
import {DocumentListComponent} from '../pages/work/reactive/work-instructions/work-instruction/document-list/document-list.component';
import {CancelWorkInstructionModalComponent} from '../pages/work/reactive/work-instructions/work-instruction/modals/cancel-work-instruction-modal/cancel-work-instruction-modal.component';
import {CancelledModalComponent} from '../pages/work/reactive/work-instructions/work-instruction/modals/cancelled-modal/cancelled-modal.component';
import {ClosedModalComponent} from '../pages/work/reactive/work-instructions/work-instruction/modals/closed-modal/closed-modal.component';
import {CompleteWorkInstructionModalComponent} from '../pages/work/reactive/work-instructions/work-instruction/modals/complete-work-instruction-modal/complete-work-instruction-modal.component';
import {UnableCompleteWorkInstructionModalComponent} from '../pages/work/reactive/work-instructions/work-instruction/modals/unable-complete-work-instruction-modal/unable-complete-work-instruction-modal.component';
import {ImageModalComponent} from '../pages/work/reactive/work-instructions/work-instruction/photo-list/image-modal/image-modal.component';
import {PhotoListComponent} from '../pages/work/reactive/work-instructions/work-instruction/photo-list/photo-list.component';
import {WorkInstructionNoteFeedComponent} from '../pages/work/reactive/work-instructions/work-instruction/work-instruction-note-feed/work-instruction-note-feed.component';
import {WorkInstructionSidebarDetailsComponent} from '../pages/work/reactive/work-instructions/work-instruction/work-instruction-sidebar-details/work-instruction-sidebar-details.component';
import {WorkInstructionSidebarListComponent} from '../pages/work/reactive/work-instructions/work-instruction/work-instruction-sidebar-list/work-instruction-sidebar-list.component';
import {BatchService} from '../pages/work/scheduled/api/services/batch.service';
import {CycleService} from '../pages/work/scheduled/api/services/cycle.service';
import {ScheduledWorkInstructionService} from '../pages/work/scheduled/api/services/scheduled-work-instruction.service';
import {BatchWizardComponent} from '../pages/work/scheduled/batch-wizard/batch-wizard.component';
import {SiteNotesFeedComponent} from '../pages/work/sites/site-notes-feed/site-notes-feed.component';
import {RecurringSidebarDetailsComponent} from '../pages/work/reactive/recurring-work-instructions/recurring-work-instruction/recurring-work-instruction-sidebar.details/recurring-sidebar-details.component';
import {DatatableService} from '../utility/datatable/datatable.service';
import {DateHelper} from '../utility/dates/date.helper';
import {MyDateAdapter} from '../utility/dates/my-date-adapter';
import {ArchiveService} from '../utility/helper-services/archive.service';
import {InputMasks} from '../utility/helpers/input-masks';
import {RegexPatterns} from '../utility/helpers/regex-patterns';
import {ToastrHelperService} from '../utility/helpers/toastr-helper.service';
import {AppBackdropComponent} from './components/app_backdrop/app_backdrop.component';
import {TaskExportModalComponent} from './components/modals/task-export-modal/task-export-modal.component';
import {CustomDualistboxComponent} from './custom-components/custom-dualistbox/custom-dualistbox.component';
import {SafeHtmlPipe} from './custom-components/custom-dualistbox/safe-html.pipe';
import {GAGuard} from './ga/GA.guard';
import {AgePipe} from './pipes/age.pipe';
import {GroupByPipe} from './pipes/groupby.pipe';
import {JiraAgePipe} from './pipes/jira-age.pipe';
import {KeysPipe} from './pipes/keys.pipe';
import {TimeConvertPipe} from './pipes/time-convert.pipe';
import {NumberCardChartComponent} from './components/number-card-chart/number-card-chart.component';
import {SchedulingExportWizardComponent} from '../pages/work/scheduled/view-schedules/scheduling-export-wizard/scheduling-export-wizard.component';
import {SpinnerButtonComponent} from './custom-components/spinner-button/spinner-button.component';
import {FilterLoaderComponent} from './custom-components/filter-loader/filter-loader.component';
import {RequirementTypesService} from '../pages/admin/api/services/requirement-types.service';
import {CycleJobListComponent} from '../pages/work/scheduled/batch-details/cycle-job-list/cycle-job-list.component';
import {RouterModule} from '@angular/router';
import {RecurringTemplateService} from '../pages/work/reactive/api/services/recurring-template.service';
import {RecurringDateService} from '../pages/work/reactive/api/services/recurring-date.service';
import {RecurringTaskCreatorComponent} from '../pages/work/reactive/recurring-work-instructions/modals/recurring-tasks/recurring-task-creator.component';
import {RecurringDateCreatorComponent} from '../pages/work/reactive/recurring-work-instructions/modals/recurring-dates/recurring-date-creator.component';
import {ImageCompressService, ResizeOptions} from 'ng2-image-compress';
import {BulkUploadService} from "../pages/work/reactive/api/services/bulk-upload-service";
import {SearchComponent} from '../layout/top-navbar/search/search.component';
import {TenantComponent} from "../pages/work/sites/tenant/tenant.component";
import {RouteService} from "../pages/work/scheduled/api/services/route.service";
import {MomentModule} from "angular2-moment";
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { WkrModalComponent } from './custom-components/wkt-modal/wkr-modal.component';
import { WkrModalService } from './custom-components/wkt-modal/wkr-modal.service';
import {WorkInstructionsFormModalComponent} from "../pages/work/reactive/work-instructions/modals/work-instructions-form-modal/work-instructions-form-modal.component";
import {PhotoUploadComponent} from "../pages/work/reactive/work-instructions/photos/photo-upload.component";
import {DocumentUploadComponent} from "../pages/work/reactive/work-instructions/documents/document-upload.component";
import {NoteUploadComponent} from "../pages/work/reactive/work-instructions/notes/note-upload.component";


const MY_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@NgModule({
  imports: [
    AccordionModule.forRoot(),
    AgmCoreModule,
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    CommonModule,
    FormsModule,
    MalihuScrollbarModule.forRoot(),
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRippleModule,
    MatSidenavModule,
    MatStepperModule,
    MatTabsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ModalModule.forRoot(),
    NgSelectModule,
    NgxSelectModule,
    NgxPermissionsModule.forRoot(),
    NgxDnDModule,
    PopoverModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    TabsModule.forRoot(),
    Ng2ImgToolsModule,
    TextMaskModule,
    NgxChartsModule,
    MomentModule,
  ],
  declarations: [
    AppBackdropComponent,
    CustomDualistboxComponent,
    FooterComponent,
    FooterComponent,
    GroupByPipe,
    AgePipe,
    KeysPipe,
    JiraAgePipe,
    SafeHtmlPipe,
    SiteNotesFeedComponent,
    RecurringSidebarDetailsComponent,
    TaskCreatorComponent,
    DateCreatorComponent,
    TaskExportModalComponent,
    WorkInstructionSidebarDetailsComponent,
    WorkInstructionSidebarListComponent,
    WorkInstructionNoteFeedComponent,
    TeamWizardComponent,
    TimeConvertPipe,
    ClientWizardComponent,
    DepartmentWizardComponent,
    CategoryWizardComponent,
    ClosedModalComponent,
    DocumentListComponent,
    PhotoListComponent,
    ImageModalComponent,
    UnauthorisedComponent,
    CancelledModalComponent,
    BatchWizardComponent,
    TaskFormModalComponent,
    CompleteWorkInstructionModalComponent,
    UnableCompleteWorkInstructionModalComponent,
    CancelWorkInstructionModalComponent,
    CompleteTaskModalComponent,
    UnableCompleteTaskModalComponent,
    CancelTaskModalComponent,
    NumberCardChartComponent,
    SpinnerButtonComponent,
    RecurringDateCreatorComponent,
    RecurringTaskCreatorComponent,
    NumberCardChartComponent,
    SchedulingExportWizardComponent,
    FilterLoaderComponent,
    CycleJobListComponent,
    SearchComponent,
    TenantComponent,
    ProgressBarComponent,
    WkrModalComponent,
    WorkInstructionsFormModalComponent,
    PhotoUploadComponent,
    DocumentUploadComponent,
    NoteUploadComponent,
  ],
  exports: [
    AccordionModule,
    AgmCoreModule,
    AlertModule,
    AngularDualListBoxModule,
    AppBackdropComponent,
    BsDropdownModule,
    CollapseModule,
    CommonModule,
    CustomDualistboxComponent,
    FormsModule,
    GroupByPipe,
    KeysPipe,
    MalihuScrollbarModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatOptionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ModalModule,
    NgSelectModule,
    NgxSelectModule,
    NgxDatatableModule,
    NgxDnDModule,
    NgxPermissionsModule,
    PasswordStrengthBarModule,
    PopoverModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    SiteNotesFeedComponent,
    RecurringSidebarDetailsComponent,
    TabsModule,
    TaskCreatorComponent,
    DateCreatorComponent,
    TaskExportModalComponent,
    TextMaskModule,
    TimeConvertPipe,
    AgePipe,
    JiraAgePipe,
    BatchWizardComponent,
    WorkInstructionSidebarDetailsComponent,
    WorkInstructionSidebarListComponent,
    WorkInstructionNoteFeedComponent,
    TeamWizardComponent,
    ClientWizardComponent,
    DepartmentWizardComponent,
    CategoryWizardComponent,
    ClosedModalComponent,
    DocumentListComponent,
    PhotoListComponent,
    ImageModalComponent,
    UnauthorisedComponent,
    CancelledModalComponent,
    NgxChartsModule,
    TaskFormModalComponent,
    CompleteWorkInstructionModalComponent,
    UnableCompleteWorkInstructionModalComponent,
    CancelWorkInstructionModalComponent,
    CompleteTaskModalComponent,
    UnableCompleteTaskModalComponent,
    CancelTaskModalComponent,
    FileUploadModule,
    NumberCardChartComponent,
    SpinnerButtonComponent,
    RecurringDateCreatorComponent,
    RecurringTaskCreatorComponent,
    SchedulingExportWizardComponent,
    FilterLoaderComponent,
    CycleJobListComponent,
    SearchComponent,
    TenantComponent,
    ProgressBarComponent,
    WkrModalComponent,
    WorkInstructionsFormModalComponent,
    PhotoUploadComponent,
    DocumentUploadComponent,
    NoteUploadComponent,
  ],
  providers: [
    AccountsService,
    ArchiveService,
    BillingCompanyService,
    CategoryService,
    ClientsService,
    ContractsService,
    DatatableService,
    DepartmentsService,
    DocumentsService,
    EnquiriesServices,
    FinanceService,
    ImportsService,
    ImportTypeService,
    InputMasks,
    InvoiceService,
    NotesService,
    PhotosService,
    QuotesService,
    RegexPatterns,
    SitesService,
    ScheduledWorkInstructionService,
    BatchService,
    CycleService,
    TasksService,
    TeamsService,
    ToastrHelperService,
    UsersService,
    VatRatesService,
    RecurringTemplateService,
    RecurringDateService,
    WorkInstructionService,
    WorkInProgressService,
    WorkInstructionStagesService,
    WorkInstructionNotesService,
    WorkInstructionProgressesService,
    WorkInstructionPhotosService,
    SiteNotesService,
    TaskStatuses,
    DateHelper,
    GAGuard,
    ImageCompressService,
    RequirementTypesService,
    ResizeOptions,
    BulkUploadService,
    WkrModalService,
    RouteService,
    {provide: DateAdapter, useClass: MyDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ],
  entryComponents: [
    WkrModalComponent
  ]
})
export class SharedModule {
  static forRoot (): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
