import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../../../../shared/users/auth.service';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {TasksService} from '../../../../api/services/tasks.service';
import {ModalDirective} from 'ngx-bootstrap';
import {TaskStatuses} from '../../../../api/constants/work-instruction-statuses';
import {Task} from '../../../../../../../shared/models/work-instruction/task.model';

@Component({
  selector: 'app-cancel-task-modal',
  templateUrl: './cancel-task-modal.component.html',
  styleUrls: ['./cancel-task-modal.component.scss']
})
export class CancelTaskModalComponent implements OnInit {
  @ViewChild('cancelTaskModalComp') modal: ModalDirective;

  @Output() refreshData = new EventEmitter<boolean>();

  formGroup: FormGroup;

  account_id;

  completed_date_holder: Date;
  completed_date;

  work_instruction_id;

  note = '';

  tasks = [];

  constructor(private task_service: TasksService,
              private form_builder: FormBuilder,
              private auth_service: AuthService,
              private toastr_service: ToastrService) {
  }

  ngOnInit() {
    this.formGroup = this.form_builder.group({
      completed_date: ['', Validators.required],
      note: ['', Validators.required],
    });
  }

  show(tasks: Task[], work_instruction_id: number) {
    this.tasks = tasks;
    this.work_instruction_id = work_instruction_id;
    this.completed_date_holder = new Date();

    this.modal.show();
  }

  hide() {
    this.tasks = [];
    this.formGroup.reset();
    this.modal.hide();
  }

  accept() {
    const ids = this.tasks.map(({id}) => id);

    this.note = 'CANCELLED TASK(S): '  + this.note;

    this.completed_date = this.completed_date_holder.getFullYear() + '-' + (this.completed_date_holder.getMonth() + 1) + '-' + this.completed_date_holder.getDate();

    this.task_service.updateStatuses(ids, this.completed_date.toString(), TaskStatuses.CANCELLED, 0, this.work_instruction_id, this.note).subscribe(data => {
      if(data){
        (<any>window).gtag('event', 'Task Cancelled' , {event_category: 'Work Instruction', event_label: AuthService.getLoggedInUserFullName(), value: AuthService.getUserId()});
        this.hide();
        this.toastr_service.success('UPDATE SUCCESSFUL');
        this.refreshData.emit(true);
      }
    });
  }
}
