export class WorkInstructionStages {
  public static readonly ENQUIRY            = 1;
  public static readonly QUOTE_SUPPLIED     = 2;
  public static readonly QUOTE_ACCEPTED     = 3;
  public static readonly QUOTE_REJECTED     = 4;
  public static readonly PENDING            = 5;
  public static readonly ISSUED             = 6;
  public static readonly COMPLETED          = 7;
  public static readonly UNABLE_TO_COMPLETE = 8;
  public static readonly CANCELLED          = 9;

  getEnquiry(){
    return WorkInstructionStages.ENQUIRY;
  }

  getPending(){
    return WorkInstructionStages.PENDING;
  }

  getIssued(){
    return WorkInstructionStages.ISSUED;
  }

  getCompleted(){
    return WorkInstructionStages.COMPLETED;
  }

  getUnableToComplete(){
    return WorkInstructionStages.UNABLE_TO_COMPLETE;
  }

  getCancelled(){
    return WorkInstructionStages.CANCELLED;
  }

  getQuoteSupplied(){
    return WorkInstructionStages.QUOTE_SUPPLIED;
  }

  getQuoteRejected(){
    return WorkInstructionStages.QUOTE_REJECTED;
  }

  getQuoteAccepted(){
    return WorkInstructionStages.QUOTE_ACCEPTED;
  }
}

export class TaskStatuses {
  public static readonly PENDING            = 1;
  public static readonly COMPLETED          = 2;
  public static readonly UNABLE_TO_COMPLETE = 3;
  public static readonly CANCELLED          = 4;

  getPending(){
    return TaskStatuses.PENDING;
  }

  getCompleted(){
    return TaskStatuses.COMPLETED;
  }

  getUnableToComplete(){
    return TaskStatuses.UNABLE_TO_COMPLETE;
  }

  getCancelled(){
    return TaskStatuses.CANCELLED;
  }
}

export class WorkInstructionPriorities {
  public static readonly EMERGENCY  = 1;
  public static readonly URGENT     = 2;
  public static readonly NON_URGENT = 3;

  getEmergency(){
    return WorkInstructionPriorities.EMERGENCY;
  }

  getUrgent(){
    return WorkInstructionPriorities.URGENT;
  }

  getNonUrgent(){
    return WorkInstructionPriorities.NON_URGENT;
  }

  public static readonly EMERGENCY_ICON  = 'emergency.png';
  public static readonly URGENT_ICON     = 'urgent.png';
  public static readonly NON_URGENT_ICON = 'non_urgent.png';
}

export class TransitionStages {
  public static readonly QUOTE  = 1;
  public static readonly WORK_INSTRUCTION = 2;

  getQuote(){
    return TransitionStages.QUOTE;
  }

  getWorkInstruction(){
    return TransitionStages.WORK_INSTRUCTION;
  }
}
