import { Contract } from '../client/contract.model';
import { Deserializable } from '../deserializable.model';
import { Role } from './role.model';
import {Account} from '../account/account.model';

export class User implements Deserializable {

  id: number;
  account_id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  password: string;
  auth_token: string;
  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  permission_ids: number[] = [];
  contract_ids: number[] = [];

  /********* Relationships ********/

  account: Account;
  role: Role;
  contracts: Contract[];

  /*********** Getters ************/

  /**
   * return the users full name
   * @returns {string}
   */
  getFullname () {
    return this.first_name + ' ' + this.last_name;
  }

  /********* Functions **********/
  deserialize (input: any) {
    Object.assign(this, input);
    return this;
  }
}
