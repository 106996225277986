import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { WorkInProgress } from '../../../../../shared/models/work-in-progress/work-in-progress.model';
import { AuthService } from '../../../../../shared/users/auth.service';

@Injectable()
export class WorkInProgressService {

  constructor(private http: HttpClient){}

  getWipDatatable(client_account_id: number, contract_id: number, department_id: number, team_id: number, job_type: string, date: string){

    client_account_id = !client_account_id ? 0 : client_account_id;
    contract_id       = !contract_id ? 0 : contract_id;
    department_id     = !department_id ? 0 : department_id;
    team_id           = !team_id ? 0 : team_id;
    job_type          = !job_type ? 'any' : job_type;

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString(),
        'contract_id': contract_id.toString(),
        'department_id': department_id.toString(),
        'team_id': team_id.toString(),
        'job_type': job_type,
        'date': date
      }
    };
    return this.http.get<WorkInProgress[]>(environment.base_url + '/work-instruction-progress/datatable', httpOptions);
  }
}
