
import { Deserializable } from '../deserializable.model';
import {ClientAccount} from '../client/client-account.model';

export class BillingCompany implements Deserializable {

  id: number;
  name: string;
  vat_reg_number: string;
  company_number: string;
  bank_account_number: string;
  sort_code: string;
  email: string;
  phone_number: string;
  address_1: string;
  address_2: string;
  address_3: string;
  address_4: string;
  postcode: string;
  logo: string;
  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  client_account_ids: number[];

  /************ Relationships *************/
  client_accounts: ClientAccount[];

  /*********** Functions *****************/
  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

}
