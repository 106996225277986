import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WorkInstructionService} from '../../../api/services/work-instruction.service';
import {WorkInstruction} from '../../../../../../shared/models/work-instruction/work-instruction.model';
import {saveAs} from 'file-saver';
import {DocumentsService} from '../../../api/services/documents.service';
import {Document} from '../../../../../../shared/models/documents/document.model';
import {DocumentType} from '../../../../../../shared/models/documents/document-type.model';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ToastrService} from 'ngx-toastr';
import {RequirementType} from '../../../../../../shared/models/work-instruction/work-instruction-requirement-type.model';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  @ViewChild('documentTypeSelect') type_select: NgSelectComponent;
  @ViewChild('requirementTypeSelect') requirement_type_select: NgSelectComponent;

  @Output() close = new EventEmitter<boolean>();
  @Output() updated = new EventEmitter<{documents: WorkInstruction['documents']}>();

  @Input() has_requirements                = false;
  @Input() requirements: RequirementType[] = [];
  @Input('workInstruction') public work_instruction: WorkInstruction;

  isCollapsed = true;

  _types: DocumentType[] = [];
  _selected_document_type: DocumentType;
  _selected_requirement_type:RequirementType;

  _documents: Document[] = [];

  _is_required = false;
  _is_actioning = false;

  formGroup: FormGroup;

  mimes = {
    pdf   : 'application/pdf',
    doc   : 'application/msword',
    docx  : 'application/msword',
    docm  : 'application/msword',
    xls   : 'application/vnd.ms-excel',
    xlsx  : 'application/vnd.ms-excel',
    xlsm  : 'application/vnd.ms-excel',
    ppt   : 'application/vnd.ms-powerpoint',
    pptx  : 'application/vnd.ms-powerpoint',
    pps   : 'application/vnd.ms-powerpoint',
    ppsx  : 'application/vnd.ms-powerpoint',
    txt   : 'text/plain',
    csv   : 'text/csv',
    png   : 'image/png',
    jpeg  : 'image/jpg',
    jpg   : 'image/jpg',
    gif   : 'image/gif',
  };

  constructor (
    private work_instruction_service: WorkInstructionService,
    private doc_service: DocumentsService,
    private form_builder: FormBuilder,
    private toastr_service: ToastrService
  ){
    this.formGroup = this.form_builder.group({
      'type_select': [null, Validators.required],
      'is_required': [this._is_required],
      'requirement_type_id': [{value:null, disabled:true}],
      'file_input': []
    })
  }

  ngOnInit () {
    if(!this.work_instruction) {
      this.work_instruction_service.work_instruction$.subscribe(data => {
        this.work_instruction = data;
      });
    }

    this.getDocumentTypes();
  }

  boxChecked(){
    if (this.formGroup.get('is_required').value){
      this.formGroup.get('requirement_type_id').setValidators(Validators.required);
      this.formGroup.get('requirement_type_id').enable();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
    } else{
      this._selected_requirement_type = null;
      this.formGroup.get('requirement_type_id').reset();
      this.formGroup.get('requirement_type_id').clearValidators();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
      this.formGroup.get('requirement_type_id').disable();
    }
  }

  getDocumentTypes(){
    this.doc_service.getDocumentsTypes().subscribe(data => {
      this._types = data;
    });
  }

  selectedDocumentType(){
    if(this.type_select.selectedItems.length > 0){
      this._selected_document_type = this.type_select.selectedItems[0].value as DocumentType;
    }
  }

  selectedRequirementType(){
    if (this.requirement_type_select.selectedItems[0]){
      this._selected_requirement_type = this.requirement_type_select.selectedItems[0].value as RequirementType;
    } else{
      this.formGroup.patchValue({'is_required': false});
      this.boxChecked();
      this._selected_requirement_type = null;
    }
  }

  getDocument (id: number) {
    this.doc_service.getDocumentFile(id, 'application/octet-stream').subscribe(data => {
      const file_url = URL.createObjectURL(data);
      window.open(file_url);
    });
  }

  downloadDocument (document: Document) {
    this.doc_service.getDocumentFile(document.id, 'application/octet-stream').subscribe(response => this.downloadFile(document, response));
  }

  downloadFile (document: Document, response) {
    const doc_file_type = document.name.split('.').pop();
    const blob = new Blob([response], {type: this.getMimeType(doc_file_type)});
    saveAs(blob, document.name);
  }

  getMimeType(extension: string){
    return this.mimes[extension];
  }

  isPDF(document: Document): boolean {
    const doc_file_type = document.name.split('.').pop();
    if (doc_file_type === 'pdf') {
      return true;
    }

    return false;
  }

  handleFileInput(files: FileList) {
    Array.from(files).forEach(data => {
      if (data.size > 10000000) {
        this.toastr_service.error('FILE ' + data.name + ' EXCEEDS FILE SIZE LIMIT (10MB)');
      }else{
        const document = new Document();

        document.name = this.formGroup.get('is_required').value ? '(' + this._selected_requirement_type.name + ') ' + data.name : data.name;
        document.file = data;
        document.document_type          = this._selected_document_type;
        document.document_type_id       = this._selected_document_type.id;
        document.is_special_requirement = !!this.formGroup.get('is_required').value;
        document.requirement_type_id    = this._selected_requirement_type ? this._selected_requirement_type.id : null;
        document.requirement_type       = this._selected_requirement_type;

        this._documents.push(document);
      }
    });
  }

  removeDocument(index: number){
    this._documents.splice(index, 1);
  }

  onSubmit(){
    this._is_actioning = true;
    this.doc_service
      .addDocuments(this.work_instruction.id, this._documents)
      .subscribe(data => {
        this.work_instruction.documents = data.documents;
        this.reset();
        this.toastr_service.success('DOCUMENTS ADDED');
        this._is_actioning = false;
        
        this.updated.emit({documents: data.documents});
      }, error1 => this._is_actioning = false );
  }

  reset(){
    this.formGroup.reset();
    this.formGroup.patchValue({'is_required': false});
    this.boxChecked();
    this._documents = [];
    this.isCollapsed = true;
  }

  closeDocuments(){
    this.close.next(true);
    this.reset();
  }
}
