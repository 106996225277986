import { Deserializable } from '../deserializable.model';
import { User } from '../user/user.model';

export class WorkInstructionProgress implements Deserializable {

  id: number;
  value: number;
  total: number;
  work_instruction_net_value: number;
  created_by_user_id: number;
  date: string;
  created_at: string;
  updated_at: string;

  created_by_user: User;

  deserialize(input: any){
    Object.assign(this,input);
    return this;
  }

  /********* GETTERS AND SETTERS ***********/

  get createdByUser(): User{
    return new User().deserialize(this.created_by_user);
  }

}
