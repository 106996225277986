import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { WorkInstruction } from '../../../../../shared/models/work-instruction/work-instruction.model';
import { AuthService } from '../../../../../shared/users/auth.service';

@Injectable()
export class QuotesService {

  constructor (private httpClient: HttpClient) {
  }

  /******************************************* GET METHODS *****************************************/
  getQuotes (client_account_id: number, contract_id: number, department_id: number, stage_id: number, closed: any,
             from:number, page_limit:number, search_term:string) {

    client_account_id = !client_account_id ? 0 : client_account_id;
    contract_id       = !contract_id ? 0 : contract_id;
    stage_id          = !stage_id ? 0 : stage_id;
    department_id     = !department_id ? 0 : department_id;
    search_term = !search_term ? 'null': search_term;

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString(),
        'contract_id': contract_id.toString(),
        'department_id': department_id.toString(),
        'stage_id': stage_id.toString(),
        'closed': closed,
        'from': from.toString(),
        'page_limit': page_limit.toString(),
        'search_term': search_term
      }
    };

    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/quotes/get-quotes', httpOptions)
      .map((quotes: WorkInstruction[]) => quotes.map((quote) => new WorkInstruction().deserialize(quote)));
  }

  /******************************************* POST METHODS *****************************************/
  create (data: WorkInstruction) {
    const formData = new FormData();

    data.work_instruction_photos.forEach(item => {
      formData.append('photos[]', item.file);
    });

    data.documents.forEach(item => {
      formData.append('document_files[]', item.file);
    });

    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(environment.base_url + '/quotes/create-quote', formData, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  update (id: number, data: WorkInstruction) {
    const formData = new FormData();

    data.work_instruction_photos.forEach(item => {
      formData.append('photos[]', item.file);
    });

    data.documents.forEach(item => {
      formData.append('document_files[]', item.file);
    });

    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(environment.base_url + '/quotes/update-quote', formData, {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  updateStatus (id: number, data: object) {
    return this.httpClient.post(environment.base_url + '/quotes/update-quote-status', data, {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /******************************************** ARCHIVE METHODS ********************************************/
  archive (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/archive', ids, httpOptions);
  }

  activate (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/activate', ids, httpOptions);
  }

  /********************************************* MISC METHODS ***********************************************/

}
