import { 
  Component, 
  Input, 
  Output, 
  EventEmitter, 
  ViewChild,
  AfterViewInit
} from "@angular/core";

import { ModalDirective,  } from "ngx-bootstrap/modal";

@Component({
  selector: 'wkr-modal',
  templateUrl: './wkr-modal.component.html',
})
export class WkrModalComponent implements AfterViewInit {

  @Input() public title: string;
  @Input() public body: string;
  @Output() public result: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('modalReference') public modal: ModalDirective;

  constructor(){}

  ngAfterViewInit () {
    this.modal.show();
  }

}
