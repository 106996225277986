import {Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import {GpsDetails} from '../interfaces/sites.interface';
import {environment} from '../../../../../../environments/environment';
import {AuthService} from '../../../../../shared/users/auth.service';
import {Site} from '../../../../../shared/models/client/site.model';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';

@Injectable()
export class SitesService {

  /**
   * @type {BehaviorSubject<Site>}
   */
  private _site = new BehaviorSubject<Site>(new Site());

  /**
   * @type {Observable<Site>}
   */
  public _site$ = this._site.asObservable();

  /**
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient){}

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @returns {Observable<Site[]>}
   */
  getSitesDatatable (client_account_id: number, from:number, page_limit:number, search_term:string) {

    client_account_id = !client_account_id ? 0 : client_account_id;
    search_term = !search_term ? 'null': search_term;

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString(),
        'from': from.toString(),
        'page_limit': page_limit.toString(),
        'search_term': search_term
      }
    };

    return this.httpClient.get<Site[]>(environment.base_url + '/sites/sites-datatable', httpOptions)
      .map((sites: Site[]) => sites.map((site) => new Site().deserialize(site)));
  }

  /**
   *
   * @param {number} client_account_id
   * @returns {Observable<Site[]>}
   */
  getSiteList(@Optional() client_account_id: number = 0){
    return this.httpClient.get<Site[]>(environment.base_url + '/sites/get-site-list', {params: {'auth_token': AuthService.getAuthToken(), 'client_account_id': client_account_id.toString()}})
      .map((sites: Site[]) => sites.map((site) => new Site().deserialize(site)));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<Site[]>}
   */
  getSite (id: number) {
    return this.httpClient.get<Site>(environment.base_url + '/sites/get-site', {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}})
      .map((site) => new Site().deserialize(site));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<Site[]>}
   */
  getSiteWorkInstructions(id: number){
    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/sites/get-work-instructions', {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /**
   *
   * @param {string} postcode
   * @returns {Observable<GpsDetails>}
   */
  getGpsDetails (postcode: string) {
    return this.httpClient.get<GpsDetails>(environment.base_url + '/sites/get-gps-details', {params: {'auth_token': AuthService.getAuthToken(), 'postcode': postcode}});
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} site
   * @returns {Observable<Site>}
   */
  store (site: object) {
    return this.httpClient.post<Site>(environment.base_url + '/sites/create-site', site, {params: {'auth_token': AuthService.getAuthToken()}})
      .map((newsite) => new Site().deserialize(newsite));
  }

  /**
   *
   * @param {Site} site
   * @returns {Observable<Site>}
   */
  update (site: Site) {
    return this.httpClient.post<Site>(environment.base_url + '/sites/update-site', site, {params: {'auth_token': AuthService.getAuthToken(), 'id': site.id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ ARCHIVE METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archive (ids: object) {
    return this.httpClient.post(environment.base_url + '/sites/archive-sites', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activate (ids: object) {
    return this.httpClient.post(environment.base_url + '/sites/activate-sites', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {Site} site
   */
  setSite(site: Site){
    if (site === null){
      site = new Site();
    }

    this._site.next(site);
  }

  /**
   *
   * @param {string} field
   * @param {string} value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate (field: string, value: string, id: number) {
    return this.httpClient.post(
      environment.base_url + '/sites/check-for-duplicate',

      {field: field, value: value},

      {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}}
    );
  }

  /**
   *
   * @param {number} client_account_id
   * @param {Site} site
   * @returns {Observable<Object>}
   */
  checkForDuplicateSite (client_account_id: number, site: Site) {
    return this.httpClient.post<any>(
      environment.base_url + '/sites/check-for-duplicate',

      {
        client_account_id: client_account_id,
        name: site.name,
        address_1: site.address_1,
        postcode: site.postcode,
        latitude: site.latitude,
        longitude: site.longitude,
      },

      {params: {'auth_token': AuthService.getAuthToken()}}
    );
  }
}
