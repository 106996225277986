import {Deserializable} from "../deserializable.model";
import {AuthService} from "../../users/auth.service";
import {WorkInstruction} from "./work-instruction.model";
import {ClientAccount} from "../client/client-account.model";
import {CycleWeek} from "./cycle-week.model";

export class RouteModel implements Deserializable{

  id: number;
  name: string;
  client_account_id: number;
  cycle_week_id: number;
  created_by_user_id: number;
  updated_by_user_id: number;

  /**************** Relationships *************/

  work_instructions: WorkInstruction[];
  client_account: ClientAccount;
  cycle_week: CycleWeek;

  deserialize(input: any){
    Object.assign(this, input);
    return this;

  }

  constructor() {
    this.id = 0;
    this.name = '';
    this.created_by_user_id = AuthService.getAccountId();
    this.work_instructions = [];
  }
}
