import {Deserializable} from '../deserializable.model';
import {Team} from '../teams/team.model';
import {ClientAccount} from './client-account.model';
import {User} from '../user/user.model';
import {Department} from '../departments/department.model';
import {Batch} from '../work-instruction/batch.model';
import {Cycle} from '../work-instruction/cycle.model';
import {WorkInstruction} from '../work-instruction/work-instruction.model';

export class Contract implements Deserializable {

  /****** DELETE *****/
  client_account: ClientAccount;
  /****** DELETE END ******/

  id: number;
  name: string;
  code: string;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  client_account_ids: number[];
  team_ids: number[];
  user_ids: number[];
  updated_by_user_id: number;

  client_accounts: ClientAccount[];
  teams: Team[];
  users: User[];
  departments: Department[];

  active_batches: Batch[];
  active_batches_count: number;

  active_cycles: Cycle[];
  active_cycles_count: number;

  scheduled_teams: Team[];

  active_work_instructions: WorkInstruction[];
  active_work_instructions_count: number;

  scheduled_progress: number;

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id = 0;
    this.name = '';
    this.code = '';

    this.created_at = '';
    this.updated_at = '';
    this.deactivated_at = '';
    this.deleted_at = '';

    this.client_account_ids = [];
    this.team_ids = [];
    this.user_ids = [];

    // this.teams = [];
    // this.client_accounts = [];
  }
}


// batches: Batch[];
