import {Injectable} from '@angular/core';
import {AuthService} from '../../../../../shared/users/auth.service';
import {environment} from '../../../../../../environments/environment';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';
import {HttpClient} from '@angular/common/http';
import {CycleWeek} from '../../../../../shared/models/work-instruction/cycle-week.model';
import {Cycle} from '../../../../../shared/models/work-instruction/cycle.model';

@Injectable()
export class ScheduledWorkInstructionService {

  constructor (private httpClient: HttpClient) {}

  /**
   *
   * @param {number} client_account_id
   * @param {number} contract_id
   * @param {number} department_id
   * @param {number} team_id
   * @param {number} work_instruction_stage_id
   * @param {boolean} cancelled
   * @param {number} batch_id
   * @param {number} cycle_id
   * @param {number} from
   * @param {number} page_limit
   * @param {string} search_term
   * @returns {Observable<WorkInstruction[]>}
   */
  getScheduledWorkInstructionDatatable (client_account_id: number, contract_id: number, department_id: number, team_id: number, work_instruction_stage_id: number,
                                        cancelled:boolean, batch_id:number, cycle_id:number, from:number, page_limit:number, search_term:string) {

    client_account_id = !client_account_id ? 0 : client_account_id;
    contract_id       = !contract_id ? 0 : contract_id;
    department_id     = !department_id ? 0 : department_id;
    team_id           = !team_id ? 0 : team_id;
    batch_id          = !batch_id ? 0 : batch_id;
    cycle_id          = !cycle_id ? 0 : cycle_id;
    work_instruction_stage_id = !work_instruction_stage_id ? 0 : work_instruction_stage_id;
    search_term = !search_term ? 'null': search_term;

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString(),
        'contract_id': contract_id.toString(),
        'department_id': department_id.toString(),
        'team_id': team_id.toString(),
        'work_instruction_stage_id': work_instruction_stage_id.toString(),
        'cancelled': cancelled.toString(),
        'batch_id': batch_id.toString(),
        'cycle_id': cycle_id.toString(),
        'from': from.toString(),
        'page_limit': page_limit.toString(),
        'search_term': search_term
      }
    };

    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/scheduled-work-instructions/work-instructions', httpOptions)
      .map((instructions: WorkInstruction[]) => instructions.map((instruction) => new WorkInstruction().deserialize(instruction)));
  }

  getScheduledWorkInstruction (id: number) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'work_instruction_id': id.toString()
      }
    };

    return this.httpClient.get<WorkInstruction>(environment.base_url + '/scheduled-work-instructions/get-scheduled-work-instruction', httpOptions)
      .map((instruction: WorkInstruction) => new WorkInstruction().deserialize(instruction));
  }

  /**
   *
   * @param {string} cycle_week_id
   * @param {string} team_id
   * @returns {Observable<WorkInstruction[]>}
   */
  getTeamWeekRouting(cycle_week_id: string, team_id: string){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id,
        'cycle_week_id': cycle_week_id
      }
    };

    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/scheduled-work-instructions/get-team-week-routing', httpOptions)
      .map((instructions: WorkInstruction[]) => instructions.map((instruction) => new WorkInstruction().deserialize(instruction)));
  }

  isTeamWeekIssued(cycle_week_id: string, team_id: string){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id,
        'cycle_week_id': cycle_week_id
      }
    };

    return this.httpClient.get<boolean>(environment.base_url + '/scheduled-work-instructions/get-is-team-week-issued', httpOptions)
  }

  /**
   *
   * @param {string} cycle_week_id
   * @param {string} team_id
   * @returns {Observable<WorkInstruction[]>}
   */
  getTeamPreviousRoute(cycle_week_id: number, team_id: number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id.toString(),
        'cycle_week_id': cycle_week_id.toString()
      }
    };

    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/scheduled-work-instructions/get-team-previous-route', httpOptions)
      .map((instructions: WorkInstruction[]) => instructions.map((instruction) => new WorkInstruction().deserialize(instruction)));
  }

  saveWeekRoute(cycle_week_id: string, team_id: string, work_instructions: WorkInstruction[]){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id,
        'cycle_week_id': cycle_week_id
      }
    };

    return this.httpClient.post<WorkInstruction[]>(environment.base_url + '/scheduled-work-instructions/save-week-route', {work_instructions: work_instructions}, httpOptions)
      .map((instructions: WorkInstruction[]) => instructions.map((instruction) => new WorkInstruction().deserialize(instruction)));
  }

  /**
   *
   * @param {number} cycle_id
   * @returns {Observable<Cycle>}
   */
  issueCycle(cycle_id: number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'cycle_id': cycle_id.toString()
      }
    };

    return this.httpClient.get<Cycle>(environment.base_url + '/scheduled-work-instructions/issue-cycle', httpOptions)
      .map((cycle: Cycle) => new Cycle().deserialize(cycle));
  }

  /**
   *
   * @param {number} cycle_id
   * @param {number} team_id
   * @returns {Observable<Cycle>}
   */
  issueTeamCycle(cycle_id: number, team_id:number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'cycle_id': cycle_id.toString(),
        'team_id': team_id.toString()
      }
    };

    return this.httpClient.get<Cycle>(environment.base_url + '/scheduled-work-instructions/issue-team-cycle', httpOptions)
      .map((cycle: Cycle) => new Cycle().deserialize(cycle));
  }

  /**
   *
   * @param {number} team_id
   * @param {number} cycle_week_id
   * @returns {Observable<Object>}
   */
  issueCycleWeek(team_id:number, cycle_week_id:number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id.toString(),
        'cycle_week_id': cycle_week_id.toString()
      }
    };

    return this.httpClient.get<CycleWeek>(environment.base_url + '/scheduled-work-instructions/issue-cycle-week', httpOptions)
      .map((cycle_week: CycleWeek) => new CycleWeek().deserialize(cycle_week));
  }

  /**
   *
   * @param {number} team_id
   * @param {number} cycle_week_id
   * @returns {Observable<Object>}
   */
  issuePartialCycleWeek(team_id:number, cycle_week_id:number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id.toString(),
        'cycle_week_id': cycle_week_id.toString()
      }
    };

    return this.httpClient.get<CycleWeek>(environment.base_url + '/scheduled-work-instructions/issue-partial-cycle-week', httpOptions)
      .map((cycle_week: CycleWeek) => new CycleWeek().deserialize(cycle_week));
  }

  cancelCycleWeek(team_id:number, cycle_week_id:number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'team_id': team_id.toString(),
        'cycle_week_id': cycle_week_id.toString()
      }
    };

    return this.httpClient.get<CycleWeek>(environment.base_url + '/scheduled-work-instructions/cancel-cycle-week', httpOptions)
      .map((cycle_week: CycleWeek) => new CycleWeek().deserialize(cycle_week));}
}
