import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CategoryService} from '../../../../admin/api/services/category.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TeamsService} from '../../../../admin/api/services/teams.service';
import {TransitionStages} from '../../api/constants/work-instruction-statuses';
import {Department} from '../../../../../shared/models/departments/department.model';
import {Contract} from '../../../../../shared/models/client/contract.model';
import {Task} from '../../../../../shared/models/work-instruction/task.model';
import {VatRate} from '../../../../../shared/models/billing/vat-rate.model';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';

@Component({
  selector: 'app-task-creator',
  templateUrl: './task-creator.component.html',
  styleUrls: ['./task-creator.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TaskCreatorComponent implements OnInit {

  @Input() work_instruction: WorkInstruction;
  @Input() selected_contract: Contract;
  @Input() selected_department: Department;
  @Input() vat_rates: VatRate[];

  @Input() type: number;
  @Input() selected_team_id: number;
  @Input() due_date: string;
  @Input() vat_rate_id: number;

  task_form: FormGroup;
  task_form_array: FormArray;

  tasks = <Task[]>[];

  transition_stages = new TransitionStages();

  total_task_value = 0;

  constructor(private category_service: CategoryService,
              private team_service: TeamsService,
              private fb: FormBuilder) {

    this.setForm();
  }

  ngOnInit() {
    this.tasks = this.work_instruction.tasks;
  }

  addTask() {
    const task = new Task();

    task.category_list  = this.selected_department.task_categories;

    if(this.type === TransitionStages.WORK_INSTRUCTION){
      task.team_list      = this.selected_contract.teams;
      task.vat_rate_list  = this.vat_rates;

      task.team_id              = this.selected_team_id;
      task.due_date_holder      = this.due_date;
      task.vat_rate_id          = this.vat_rate_id;
    }

    this.task_form_array = this.task_form.get('task_form_array') as FormArray;
    this.task_form_array.push(this.createTaskFormGroup(task));

    this.tasks.push(task);
  }

  /* +++++++++++++++++++++++++++++++ SET METHODS ++++++++++++++++++++++++++++++++ */

  setTasks(work_instruction: WorkInstruction){
    this.tasks = [];

    work_instruction.tasks.forEach(task => {

      task = new Task().deserialize(task);

      task.category_list  = this.selected_department.task_categories;
      this.selectedCategory(task);

      if(this.type === TransitionStages.WORK_INSTRUCTION){
        task.team_list      = this.selected_contract.teams;
        task.vat_rate_list  = this.vat_rates;

        task.team_id            = this.selected_team_id;
        task.due_date_holder    = this.due_date;
        task.vat_rate_id        = this.vat_rate_id;

        this.selectedTeam(task);
        this.selectedVatRate(task);
      }

      task.isCollapsed = true;

      this.task_form_array = this.task_form.get('task_form_array') as FormArray;
      this.task_form_array.push(this.createTaskFormGroup(task));

      this.tasks.push(task);
    });

    this.updateTotal();
  }

  createTaskFormGroup(task: Task){
    const group = this.fb.group({
      'description':      [task.description,      Validators.required],
      'task_category_id': [task.task_category_id, Validators.required],
      'unit_value':       [task.unit_value,       Validators.required],
      'quantity':         [task.quantity,         Validators.required],
    });

    if (this.type === TransitionStages.WORK_INSTRUCTION){
      group.addControl('vat_rate_id',       new FormControl(task.vat_rate_id,       Validators.required));
      group.addControl('team_id',           new FormControl(task.team_id));
      group.addControl('due_date_holder',   new FormControl(task.due_date_holder));
    }

    group.valueChanges.subscribe( values => {
      task.deserialize(values);
    });

    return group;
  }

  setForm(){
    this.task_form_array = new FormArray([]);

    this.task_form = this.fb.group({
      task_form_array: this.fb.array([])
    });
  }

  /* +++++++++++++++++++++++++++++++ SELECTED METHODS ++++++++++++++++++++++++++++++++ */

  selectedCategory(task: Task) {
    task.category_list.forEach(category => {
      if (category.id === task.task_category_id){
        task.task_category = category;
      }
    })
  }

  selectedTeam(task: Task){
    task.team_list.forEach(team => {
      if (team.id === task.team_id){
        task.team = team;
      }
    })
  }

  selectedVatRate(task: Task){
    task.vat_rate_list.forEach(vat_rate => {
      if (vat_rate.id === task.vat_rate_id){
        task.vat_rate = vat_rate;
      }
    })
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  updateTotal() {
    this.total_task_value = 0;

    this.task_form_array.controls.forEach(task => {
      this.total_task_value = this.total_task_value + +(task.get('unit_value').value * task.get('quantity').value);
    });
  }

  removeTask(index: number) {
    this.tasks.splice(index, 1);
    this.task_form_array.removeAt(index);
    this.updateTotal();
  }

  reset(){
    this.tasks = [];
    this.setForm();
    this.total_task_value = 0;
  }
}
