import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../shared/users/auth.service';
import {ClientAccount} from '../../../../shared/models/client/client-account.model';

@Injectable()
export class ClientsService {

  /**
   * @type {BehaviorSubject<ClientAccount>}
   * @private
   */
  private _client = new BehaviorSubject<ClientAccount>(new ClientAccount());

  /**
   * @type {Observable<T>}
   * @private
   */
  public  _client$ = this._client.asObservable();

  /**
   * constructor
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient){}

  /***************** Behaviour Subjects ***************/

  /**
   * @param client_account
   */
  setClientAccount(client_account): void {
    this._client.next(client_account);
  }

  /***************** API CALLS *********************/

  /**
   * @returns {Observable<ClientAccount[]>}
   */
  getClients(){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.httpClient.get<ClientAccount[]>(environment.base_url + '/clients/get-clients', httpOptions)
      .map((client_accounts: ClientAccount[]) => client_accounts.map((client_account) => new ClientAccount().deserialize(client_account)));
  }

  /**
   * @returns {Observable<ClientAccount[]>}
   */
  getClientList(){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.get<ClientAccount[]>(environment.base_url + '/clients/get-client-list', httpOptions)
      .map((client_accounts: ClientAccount[]) => client_accounts.map((client_account) => new ClientAccount().deserialize(client_account)));
  }


  /**
   * @param {number} account_id
   * @returns {Observable<ClientAccount>}
   */
  getClient(account_id: number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'account_id': account_id.toString()
      }
    };
    return this.httpClient.get<ClientAccount>(environment.base_url + '/clients/get-client', httpOptions)
      .map((client_account) => new ClientAccount().deserialize(client_account));
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archive(ids: object){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/clients/archive-clients', ids, httpOptions);
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activate(ids: object){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/clients/activate-clients', ids, httpOptions);
  }

  /**
   * @param {ClientAccount} client
   * @returns {Observable<Object>}
   */
  update(client: ClientAccount){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/clients/' + client.id, client, httpOptions)
  }

  /**
   * @param {ClientAccount} client
   * @returns {Observable<Object>}
   */
  store(client: ClientAccount){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.httpClient.post(environment.base_url + '/clients', client, httpOptions);
  }

  /**
   * @param {string} field
   * @param value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate(field: string, value: any, id: number){

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    id = id || 0;

    return this.httpClient.post(
      environment.base_url + '/clients/check-for-duplicate',
      {field: field, value: value, id: id},
      httpOptions);
  }
}
