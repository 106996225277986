import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { WorkInstruction } from '../../../../../../shared/models/work-instruction/work-instruction.model';
import { WorkInstructionService } from '../../../api/services/work-instruction.service';

@Component({
  selector: 'app-work-instruction-sidebar-list',
  templateUrl: './work-instruction-sidebar-list.component.html',
  styleUrls: ['./work-instruction-sidebar-list.component.scss']
})
export class WorkInstructionSidebarListComponent implements OnInit, OnDestroy {

  work_instructions: WorkInstruction[];

  @Output() close = new EventEmitter<boolean>();

  private subscription: Subscription;

  constructor(private work_instruction_service: WorkInstructionService) { }

  ngOnInit() {

    this.subscription = this.work_instruction_service.work_instructions$.subscribe(data => {
      this.work_instructions = data;
    });
  }

  ngOnDestroy(){

    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}
