import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../shared/users/auth.service';
import {WorkInstructionNote} from '../../../../../shared/models/work-instruction/work-instruction-note.model';
import {RequirementType} from '../../../../../shared/models/work-instruction/work-instruction-requirement-type.model';
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({selector: 'app-note-upload', templateUrl: './note-upload.component.html', styleUrls: ['./note-upload.component.scss']})
export class NoteUploadComponent implements OnInit {
  @ViewChild('requirementTypeSelect') requirement_type_select: NgSelectComponent;
  @Input() has_requirements                = false;
  @Input() requirements: RequirementType[] = [];

  _selected_requirement_type:RequirementType;

  _exisiting_notes: WorkInstructionNote[] = [];
  _notes: WorkInstructionNote[] = [];
  _is_required = false;
  formGroup: FormGroup;

  constructor(private auth_service: AuthService, private form_builder: FormBuilder) {
    this.formGroup = this.form_builder.group({
      'note': [null, [Validators.maxLength(255), Validators.required]],
      'is_required': [this._is_required],
      'requirement_type_id': [{value:null, disabled:true}]
    })
  }

  ngOnInit() {}

  boxChecked(){
    if (this.formGroup.get('is_required').value){
      this.formGroup.get('requirement_type_id').setValidators(Validators.required);
      this.formGroup.get('requirement_type_id').enable();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
    } else{
      this._selected_requirement_type = null;
      this.formGroup.get('requirement_type_id').reset();
      this.formGroup.get('requirement_type_id').clearValidators();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
      this.formGroup.get('requirement_type_id').disable();
    }
  }

  addNote(){
    const note                  = new WorkInstructionNote();
    note.note                   = this.formGroup.get('is_required').value ? '(' + this._selected_requirement_type.name + ') ' + this.formGroup.get('note').value : this.formGroup.get('note').value;
    note.is_special_requirement = !!this.formGroup.get('is_required').value;
    note.requirement_type_id    = this._selected_requirement_type ? this._selected_requirement_type.id : null;
    note.requirement_type       = this._selected_requirement_type;
    this._notes.push(note);

    this.formGroup.reset();
    this.formGroup.patchValue({'is_required': false});
    this.boxChecked();
  }

  selectedRequirementType(){
    if (this.requirement_type_select.selectedItems[0]){
      this._selected_requirement_type = this.requirement_type_select.selectedItems[0].value as RequirementType;
    } else{
      this.formGroup.patchValue({'is_required': false});
      this.boxChecked();
      this._selected_requirement_type = null;
    }
  }

  removeNote(index: number){
    this._notes.splice(index, 1);
  }
}
