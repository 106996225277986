import { Deserializable } from '../deserializable.model';
import { DocumentType } from './document-type.model';
import {RequirementType} from '../work-instruction/work-instruction-requirement-type.model';

export class Document implements Deserializable {

  id: number;
  document_type_id: number;
  name: string;
  filepath: string;
  file: File;
  is_special_requirement: boolean;
  requirement_type_id: number;

  created_at: string;
  updated_at: string;
  deleted_at: string;
  deactivated_at: string;

  document_type: DocumentType;
  requirement_type: RequirementType;

  constructor(){
    this.id = 0;
    this.name = '';
    this.filepath = '';

    this.created_at = '';
    this.updated_at = '';
    this.deleted_at = '';

    this.is_special_requirement = false;
    this.requirement_type_id = null;
  }

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }
}
