import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {AuthService} from '../../../../../shared/users/auth.service';
import {DocumentType} from '../../../../../shared/models/documents/document-type.model';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';
import {Document} from '../../../../../shared/models/documents/document.model';

@Injectable()
export class DocumentsService {

  constructor (private httpClient: HttpClient, private auth_service: AuthService) {
  }

  /******************************************* GET METHODS *****************************************/
  getDocumentsTypes () {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };

    return this.httpClient.get<DocumentType[]>(environment.base_url + '/document-types/get-document-types', httpOptions);
  }


  /******************************************* POST METHODS *****************************************/
  addDocuments(work_instruction_id: number, data: Document[]){
    const formData = new FormData();

    data.forEach(item => {
      formData.append('document_files[]', item.file);
    });

    formData.append('data', JSON.stringify(data));

    return this.httpClient.post<WorkInstruction>(environment.base_url + '/documents/add-document-files', formData, {params: {'auth_token': AuthService.getAuthToken(), 'work_instruction_id': work_instruction_id.toString()}});

  }


  create (data: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };

    return this.httpClient.post(environment.base_url + '/quotes/create-quote', data, httpOptions);
  }

  update (id: number, data: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'id': id.toString()
      }
    };

    return this.httpClient.post(environment.base_url + '/quotes/update-quote', data, httpOptions);
  }

  /******************************************** ARCHIVE METHODS ********************************************/
  archive (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/archive', ids, httpOptions);
  }

  activate (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/activate', ids, httpOptions);
  }

  /********************************************* MISC METHODS ***********************************************/

  getDocumentFile (id: number, mime_type: string) {

    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': mime_type
        }
      ),
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'id': id.toString()
      },
      responseType: 'blob' as 'text'
    };
    return this.httpClient.get(environment.base_url + '/documents/get-document-file', httpOptions);
  }

  getDocumentFileForExport(){

  }
}
