import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class GAGuard implements CanActivate {

  constructor(private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(localStorage.getItem('auth_token') != null){
      let path = '';

      next.url.forEach(segment => {
        path += '/' + segment.path;
      });

      (<any>window).gtag('event', path , {event_category: 'Page Viewed', event_label: 'Routing', value: 0});

      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
