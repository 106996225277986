import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { Account } from '../../../../shared/models/account/account.model';
import { AuthService } from '../../../../shared/users/auth.service';
import {WorkInstructionNote} from '../../../../shared/models/work-instruction/work-instruction-note.model';
import {BehaviorSubject} from 'rxjs/index';

@Injectable()
export class AccountsService {

  /**
   * constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient){};

  /************ API CALLS **********/

  /**
   * get a list of accounts
   * @returns {Observable<Account[]>}
   */
  getAccounts(): Observable<Account[]>{

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.http.get<Account[]>(environment.base_url + '/accounts/get-accounts', httpOptions)
      .map((accounts: Account[]) => accounts.map((account) => new Account().deserialize(account)));
  }
}
