import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../../../shared/users/auth.service';
import {environment} from '../../../../../../environments/environment';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';
import {WorkInstructionPhoto} from '../../../../../shared/models/work-instruction/work-instruction-photo.model';
import {BehaviorSubject} from 'rxjs/index';

@Injectable()
export class WorkInstructionPhotosService {

  private work_instruction  = new BehaviorSubject<WorkInstruction>(new WorkInstruction());

  work_instruction$ = this.work_instruction.asObservable();

  constructor (private http: HttpClient) {}

  /**
   *
   * @param {number} work_instruction_id
   * @returns {Observable<WorkInstructionPhoto[]>}
   */
  getPhotos (work_instruction_id: number) {
    return this.http.get<WorkInstructionPhoto[]>(environment.base_url + '/work-instructions/' + work_instruction_id + '/get-work-instruction-photos', {params: {'auth_token': AuthService.getAuthToken(), 'work_instruction_id': work_instruction_id.toString()}});
  }

  /**
   *
   * @param {number} work_instruction_id
   * @param {WorkInstructionPhoto[]} data
   * @returns {Observable<Object>}
   */
  addPhotos(work_instruction_id: number, data: WorkInstructionPhoto[]){
    const formData = new FormData();

    data.forEach(item => {
      formData.append('photos[]', item.file);
    });

    formData.append('data', JSON.stringify(data));

    return this.http.post<WorkInstruction>(environment.base_url + '/work-instructions/add-work-instruction-photos', formData, {params: {'auth_token': AuthService.getAuthToken(), 'work_instruction_id': work_instruction_id.toString()}});
  }
}
