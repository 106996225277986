import {Injectable, Optional} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {RouteModel} from "../../../../../shared/models/work-instruction/route.model";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../../../shared/users/auth.service";
import {Cycle} from "../../../../../shared/models/work-instruction/cycle.model";
import {environment} from "../../../../../../environments/environment";
import {WorkInstruction} from "../../../../../shared/models/work-instruction/work-instruction.model";
import {Batch} from "../../../../../shared/models/work-instruction/batch.model";
import {Task} from "../../../../../shared/models/work-instruction/task.model";
import {InvoicedConfirmInterface} from "../../../../finance/api/interfaces/invoiced-confirm.interface";

@Injectable()
export class RouteService {

  /**
   * @type {BehaviorSubject<RouteModel>}
   */
  private _route = new BehaviorSubject<RouteModel>(new RouteModel());

  /**
   *  @type {Observable<RouteModel>}
   */
  public _route$ = this._route.asObservable();


  /**
   *
   * @param httpClient
   */
  constructor(private  httpClient: HttpClient) {}

  getRoutes(@Optional() client_account_id:number = 0) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString()
      }
    };

    return this.httpClient.get<RouteModel[]>(environment.base_url + '/routes/get-routes', httpOptions)
      .map((routes: RouteModel[]) => routes.map((route) => new RouteModel().deserialize(route)));
  }

  create(data: object){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/routes/create-route', data, httpOptions);
  }

  private setFormData(route: RouteModel){

    const form_data = new FormData();

    form_data.append('data', JSON.stringify(route));
    return form_data;
  }

  getRoute(route_id: number, cycle_week_id:number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'route_id': route_id.toString(),
        'cycle_week_id': cycle_week_id.toString()

      }
    };

    return this.httpClient.get<WorkInstruction[]>(environment.base_url + '/scheduled-work-instructions/get-route', httpOptions)
      .map((instructions: WorkInstruction[]) => instructions.map((instruction) => new WorkInstruction().deserialize(instruction)));
  }

  saveRouteChanges(route_id: number, cycle_week_id: number){
    return this.httpClient.post  (environment.base_url + '/routes/update-route', {route_id, cycle_week_id},
      {params: {'auth_token': AuthService.getAuthToken()}});
  }
}

