import { Deserializable } from '../deserializable.model';
import {TaskStatuses} from "../../constants/task-statuses";
import {RecurringTemplate} from "./recurring-template.model";
import {Team} from "../teams/team.model";
import {TaskCategory} from "../work-instruction/task-category.model";
import {VatRate} from "../billing/vat-rate.model";

export class RecurringTaskTemplate implements Deserializable {

  id: number;
  recurring_template_id: number;
  task_category_id: number;
  vat_rate_id: number;
  description: string;
  unit_value: number;
  quantity: number;
  team_id: number;
  due_date: string;
  completed_date: string;
  to_sync: boolean;
  isCollapsed = true;
  task_category: TaskCategory;
  team: Team;
  vat_rate: VatRate;
  team_list: Team[] = [];
  category_list: TaskCategory[] = [];
  vat_rate_list: VatRate[] = [];
  due_date_holder: string;

  recurring_template: RecurringTemplate;


  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id= 0;
    this.description = '';
    this.unit_value = 0;
    this.quantity = 0;
    this.due_date = '';
    this.completed_date = '';
    this.to_sync = false;

    // this.recurring_task_template = TaskStatuses.PENDING;
  }




}
