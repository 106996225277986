import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {RecurringTemplate} from '../../../../../../shared/models/recurring/recurring-template.model';
import {Contract} from '../../../../../../shared/models/client/contract.model';
import {Department} from '../../../../../../shared/models/departments/department.model';
import {VatRate} from '../../../../../../shared/models/billing/vat-rate.model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RecurringTaskTemplate} from '../../../../../../shared/models/recurring/recurring-task-template.model';
import {TransitionStages} from '../../../api/constants/work-instruction-statuses';
import {CategoryService} from '../../../../../admin/api/services/category.service';
import {TeamsService} from '../../../../../admin/api/services/teams.service';

@Component({selector: 'app-recurring-task-creator', templateUrl: './recurring-task-creator.component.html', styleUrls: ['./recurring-task-creator.component.scss'], encapsulation: ViewEncapsulation.Emulated,})
export class RecurringTaskCreatorComponent implements OnInit{
  public scrollbarOptions = {axis: 'y', theme: 'minimal-dark'};

  @Input() recurring_template: RecurringTemplate;
  @Input() selected_contract: Contract;
  @Input() selected_department: Department;
  @Input() vat_rates: VatRate[];
  @Input() selected_team_id: number = null;
  @Input() due_date: string         = null;
  @Input() vat_rate_id: number;

  recurring_task_form: FormGroup;
  recurring_task_form_array: FormArray;

  recurring_tasks = <RecurringTaskTemplate[]>[];
  total_recurring_task_value = 0;

  constructor(private category_service: CategoryService,
              private team_service: TeamsService,
              private fb: FormBuilder,) {
    this.setRecurringTaskForm();
  }

  ngOnInit() { this.recurring_tasks = this.recurring_template.recurring_tasks }

  /* +++++++++++++++++++++++++ ADD RECURRING TASK ++++++++++++++++++ */

  addRecurringTask() {
    const recurring_task = new RecurringTaskTemplate();

    recurring_task.category_list    = this.selected_department.task_categories;
    recurring_task.team_list        = this.selected_contract.teams;
    recurring_task.vat_rate_list    = this.vat_rates;
    recurring_task.team_id          = this.selected_team_id;
    recurring_task.due_date_holder  = this.due_date;
    recurring_task.vat_rate_id      = this.vat_rate_id;

    this.recurring_task_form_array  = this.recurring_task_form.get('recurring_task_form_array') as FormArray;
    this.recurring_task_form_array.push(this.createRecurringTaskFormGroup(recurring_task));
    this.recurring_tasks.push(recurring_task);
  }

  /* +++++++++++++++++++++++++ SET RECURRING TASKS ++++++++++++++++++ */

  setRecurringTasks(recurring_template: RecurringTemplate) {
    this.recurring_tasks = [];

    recurring_template.recurring_tasks.forEach(recurring_task => {
      recurring_task.category_list = this.selected_department.task_categories;

      this.selectedCategory(recurring_task);
      this.selectedTeam(recurring_task);
      this.selectedVatRate(recurring_task);

      recurring_task.team_list       = this.selected_contract.teams;
      recurring_task.vat_rate_list   = this.vat_rates;
      recurring_task.team_id         = this.selected_team_id;
      recurring_task.due_date_holder = this.due_date;
      recurring_task.vat_rate_id     = this.vat_rate_id;
      recurring_task.isCollapsed     = true;

      this.recurring_task_form_array = this.recurring_task_form.get('recurring_task_form_array') as FormArray;
      this.recurring_task_form_array.push(this.createRecurringTaskFormGroup(recurring_task));
      this.recurring_tasks.push(recurring_task);
    });
  }

  /* ++++++++++++++++ CREATE RECURRING TASK FORM GROUP +++++++++++++++ */

  createRecurringTaskFormGroup(recurring_task: RecurringTaskTemplate) {
    const group = this.fb.group({
      'description':      [recurring_task.description,      Validators.required],
      'task_category_id': [recurring_task.task_category_id, Validators.required],
      'unit_value':       [recurring_task.unit_value,       Validators.required],
      'quantity':         [recurring_task.quantity,         Validators.required],
      'vat_rate_id':      [recurring_task.vat_rate_id,      Validators.required],
      'team_id':          [recurring_task.team_id],
      'due_date_holder':  [recurring_task.due_date_holder]
    });

    group.valueChanges.subscribe(values => recurring_task.deserialize(values));
    return group;
  }

  /* ++++++++++++++ SET +++++++++++++ */

  setRecurringTaskForm() {
    this.recurring_task_form_array = new FormArray([]);

    this.recurring_task_form = this.fb.group({
      recurring_task_form_array: this.fb.array([])
    });
  }

  /* ++++++++++++ SELECTED METHODS +++++++++++++++ */

  selectedCategory(recurring_task: RecurringTaskTemplate) {
    recurring_task.category_list.forEach(category => {
      if (category.id === recurring_task.task_category_id) {
        recurring_task.task_category = category;
      }
    });
  }

  selectedTeam(recurring_task: RecurringTaskTemplate) {
    recurring_task.team_list.forEach(team => {
      if (team.id === recurring_task.team_id) {
        recurring_task.team = team;
      }
    });
  }

  selectedVatRate(recurring_task: RecurringTaskTemplate) {
    recurring_task.vat_rate_list.forEach(vat_rate => {
      if (vat_rate.id === recurring_task.vat_rate_id) {
        recurring_task.vat_rate = vat_rate;
      }
    });
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  updateTotal() {
    this.total_recurring_task_value = 0;

    this.recurring_task_form_array.controls.forEach(recurring_task => {
      this.total_recurring_task_value = this.total_recurring_task_value + +(recurring_task.get('unit_value').value * recurring_task.get('quantity').value);
    });
  }

  removeRecurringTask(index: number) {
    this.recurring_tasks.splice(index, 1);
    this.recurring_task_form_array.removeAt(index);
    this.updateTotal();
  }

  reset() {
    this.recurring_tasks = [];
    this.setRecurringTaskForm();
    this.total_recurring_task_value = 0;
  }
}
