import {AuthService} from '../../../../../shared/users/auth.service';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Task} from '../../../../../shared/models/work-instruction/task.model';
import {BehaviorSubject} from 'rxjs/index';

@Injectable()
export class TasksService {

  /**
   * @type {BehaviorSubject<Task>}
   */
  private _task = new BehaviorSubject<Task>(new Task());

  /**
   * @type {Observable<Task>}
   */
  public _task$ = this._task.asObservable();

  /**
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient) {
  }

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {number} work_instruction_id
   * @returns {Observable<Task[]>}
   */
  getTaskDatatable(work_instruction_id: number) {
    return this.httpClient.get<Task[]>(environment.base_url + '/tasks/get-task-datatable', {params: {'auth_token': AuthService.getAuthToken(), 'work_instruction_id': work_instruction_id.toString()}})
      .map((tasks: Task[]) => tasks.map((task) => new Task().deserialize(task)));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<Task>}
   */
  getTask(id: number) {
    return this.httpClient.get<Task>(environment.base_url + '/tasks/get-task', {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}})
      .map((task) => new Task().deserialize(task));
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {Task} data
   * @returns {Observable<Object>}
   */
  create(data: Task) {
    return this.httpClient.post(environment.base_url + '/tasks/create-task', data, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {number} id
   * @param {Task} data
   * @returns {Observable<Object>}
   */
  update(id: number, data: Task) {
    return this.httpClient.post(environment.base_url + '/tasks/update-task', data, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {number[]} ids
   * @param {string} completed_date
   * @param {number} status
   * @param {number} time_taken
   * @param {number} work_instruction_id
   * @param {string} note
   * @returns {Observable<Object>}
   */
  updateStatuses(ids: number[], completed_date: string, status: number, time_taken: number,  work_instruction_id: number, note: string) {
    const data = {
      ids: ids,
      completed_date: completed_date,
      status_id: status.toString(),
      work_instruction_id: work_instruction_id,
      note: note,
      time_taken: time_taken
    };

    return this.httpClient.post(environment.base_url + '/tasks/update_statuses', data, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  getExport(filters: any) {

    const headers = new HttpHeaders();
    headers.set('Accept', 'text/xlsx');
    const params = {
      'auth_token': AuthService.getAuthToken(),
    };

    return this.httpClient.post(environment.base_url + '/tasks/export', {filters: filters}, {
      responseType: 'blob',
      params: params,
      headers: headers
    });
  }

  setTask(task: Task) {
    if (task === null) {
      task = new Task();
    }

    this._task.next(task);
  }

  setTaskStatuses (ids: number[], task_status_id: number) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
      }
    };

    return this.httpClient.post(environment.base_url + '/tasks/set-task-statuses', {
      ids: ids,
      task_status_id: task_status_id
    }, httpOptions);
  }
}
