import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {InvoiceStatuses} from '../../../../../../../shared/constants/invoice-statuses';
import { WorkInstruction } from '../../../../../../../shared/models/work-instruction/work-instruction.model';
import {TaskStatuses, WorkInstructionStages} from '../../../../api/constants/work-instruction-statuses';
import {AuthService} from '../../../../../../../shared/users/auth.service';
import {WorkInstructionService} from '../../../../api/services/work-instruction.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-complete-work-instruction-modal',
  templateUrl: './complete-work-instruction-modal.component.html',
  styleUrls: ['./complete-work-instruction-modal.component.scss']
})
export class CompleteWorkInstructionModalComponent implements OnInit {
  @ViewChild('completeWorkInstructionModalComp') modal: ModalDirective;

  @Output() refreshData = new EventEmitter<boolean>();

  formGroup: FormGroup;

  account_id;

  completed_date_holder: Date;
  completed_date;

  work_instruction = new WorkInstruction();

  hours = 0;
  mins = 0;
  time_taken = 0;

  _is_actioning = false;

  constructor(private work_instruction_service: WorkInstructionService,
              private form_builder: FormBuilder,
              private auth_service: AuthService,
              private toastr_service: ToastrService) {
  }

  ngOnInit() {
    this.formGroup = this.form_builder.group({
      completed_date: ['', Validators.required],
      note: [],
      hours: [0, []],
      mins: [0, []],
    });
  }

  show(workInstruction: WorkInstruction) {
    this.work_instruction = workInstruction;
    this.completed_date_holder = new Date();
    this.modal.show();
  }

  hide() {
    this.work_instruction = new WorkInstruction();
    this.formGroup.reset();
    this.modal.hide();
  }

  accept() {
    this._is_actioning = true;

    this.work_instruction.work_instruction_stage_id = WorkInstructionStages.COMPLETED;
    this.work_instruction.updated_by_user_id = AuthService.getUserId();
    this.work_instruction.to_sync = true;

    this.completed_date = this.completed_date_holder.getFullYear() + '-' + (this.completed_date_holder.getMonth() + 1) + '-' + this.completed_date_holder.getDate();

    this.work_instruction.completed_at = this.completed_date.toString();

    if(this.work_instruction.invoice_status_id !== InvoiceStatuses.INVOICED && this.work_instruction.flagged_for_review !== 1){
      this.work_instruction.invoice_status_id = InvoiceStatuses.READY;
    } else
      if (this.work_instruction.invoice_status_id !== InvoiceStatuses.INVOICED && this.work_instruction.flagged_for_review === 1) {
        this.work_instruction.invoice_status_id = InvoiceStatuses.RELEASED;
      }

    this.work_instruction.tasks.forEach(task => {
      if (task.task_status_id === TaskStatuses.PENDING) {
        task.task_status_id = TaskStatuses.COMPLETED;
        task.completed_date = this.work_instruction.completed_at;
        task.time_taken = this.time_taken;
        task.to_sync = this.work_instruction.to_sync;
      }
    });

    this.work_instruction_service.update(this.work_instruction.id, this.work_instruction).subscribe( data => {
      if (data){
        this.refreshData.emit(true);
        this.toastr_service.success('UPDATED STATUS');
        this._is_actioning = false;
        this.hide();
      }
    }, error1 => this._is_actioning = false);
  }

  calculateTimeTaken(){
    let total = 0;

    total += this.hours * 60;
    total += this.mins;

    let task_count = 0;

    this.work_instruction.tasks.forEach(task =>{
      if (task.task_status_id === TaskStatuses.PENDING){
        task_count++;
      }
    });

    this.time_taken = total/task_count;
  }

}
