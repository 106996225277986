import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {RecurringTemplate} from '../../../../../../shared/models/recurring/recurring-template.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RecurringDateService} from '../../../api/services/recurring-date.service';
import {RecurringDate} from '../../../../../../shared/models/work-instruction/recurring_date.model';
import {DateHelper} from '../../../../../../utility/dates/date.helper';

@Component({selector: 'app-recurring-date-creator', templateUrl: './recurring-date-creator.component.html', styleUrls: ['./recurring-date-creator.component.scss'], encapsulation: ViewEncapsulation.Emulated})
export class RecurringDateCreatorComponent implements OnInit {

  public scrollbarOptions = {axis: 'y', theme: 'minimal-dark'};

  @Input() recurring_template: RecurringTemplate;
  @Input() due_date: string;
  @Output() formChanged = new EventEmitter<boolean>();

  recurring_date_form: FormGroup;
  recurring_date_form_array: FormArray;

  recurring_dates = <RecurringDate[]>[];

  constructor(private recurring_date_service: RecurringDateService, private fb:FormBuilder) {
    this.setRecurringDateForm();
    this.recurring_date_form.valueChanges.subscribe(data => this.formChanged.emit(true) )
  }

  ngOnInit(){ }

  /* ++++++++++ ADD RECURRING DATES +++++++++++++++ */

  addRecurringDate(){
    const recurring_date = new RecurringDate();

    recurring_date.due_date_holder = this.due_date;

    this.recurring_date_form_array = this.recurring_date_form.get('recurring_date_form_array') as FormArray;
    this.recurring_date_form_array.push(this.createDateFormGroup(recurring_date));

    this.recurring_dates.push(recurring_date);
  }

  createDateFormGroup(date: RecurringDate){
    const group = this.fb.group({
      'due_date': [date.due_date_holder,      Validators.required],
    });

    group.valueChanges.subscribe( values => { date.deserialize(values); });

    return group;
  }

  getRecurringDates(){
    const dates = [];

    this.recurring_date_form_array.controls.forEach(date => {
      dates.push( DateHelper.getFormattedDate( new Date(date.get('due_date').value) ).toString() );
    });

    return dates;
  }

  /* ++++++++++ SET RECURRING DATE ++++++++++ */

  setRecurringDateForm(){
    this.recurring_date_form_array = new FormArray([]);

    this.recurring_date_form = this.fb.group({
      recurring_date_form_array: this.fb.array([])
    });
  }

  /* ++++++++++++++++++++++ MISC METHODS +++++++++++++++++++ */

  removeRecurringDates(index: number) {
    this.recurring_dates.splice(index, 1);
    this.recurring_date_form_array.removeAt(index);
  }

  reset(){
    this.recurring_dates = [];
    this.setRecurringDateForm();
    this.recurring_date_form.valueChanges.subscribe(data => this.formChanged.emit(true) )
  }

}
