export const environment = {
  production: false,
  window_resize_timeout: 1000,
  agm_api_key: 'AIzaSyCjQKBm9pg08cb2AXHlM0MWPzWyvuKhi20',
  base_url: 'https://7l2dvi5ute.execute-api.eu-west-2.amazonaws.com/api',
  // base_url: 'http://asg-workrate-app.staging.s3-website-eu-west-1.amazonaws.com/api',
  //base_url: 'http://localhost:8000/api',
  bucket: 'https://s3-eu-west-1.amazonaws.com/asg-workrate.local/',
  ga_filter: 'local'
};
