import { Deserializable } from '../deserializable.model';
import { WorkInstruction } from './work-instruction.model';

export class WorkInstructionPriority implements Deserializable {

  id: number;
  name: string;
  icon: string;
  created_at: string;
  updated_at: string;

  work_instructions: WorkInstruction[];

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id = null;
    this.name = '';
    this.icon = '';
    this.created_at = '';
    this.updated_at = '';
  }
}
