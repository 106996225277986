import {Deserializable} from '../deserializable.model';
import {WorkInstruction} from './work-instruction.model';
import {WorkInstructionPhotoType} from './work-instruction-photo-type.model';
import {RequirementType} from './work-instruction-requirement-type.model';

export class WorkInstructionPhoto implements Deserializable {

  id: number;
  work_instruction_id: number;
  work_instruction_photo_type_id: number;
  name: string;
  filepath: string;
  is_special_requirement: boolean;
  requirement_type_id: number;

  created_at: string;
  updated_at: string;
  deleted_at: string;
  deactivated_at: string;

  work_instruction: WorkInstruction;
  work_instruction_photo_type: WorkInstructionPhotoType;
  requirement_type: RequirementType;

  file: File;

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id = 0;
    this.name = '';
    this.filepath = '';

    this.created_at = '';
    this.updated_at = '';
    this.deleted_at = '';

    this.work_instruction_photo_type = new WorkInstructionPhotoType();

    this.is_special_requirement = false;
    this.requirement_type_id = null;

    // this.site = new Site();
  }
}
