import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {AuthService} from '../../../../../shared/users/auth.service';

@Injectable()
export class NotesService {

  constructor(private httpClient: HttpClient, private auth_service: AuthService){}

  /******************************************* POST METHODS *****************************************/
  create(data: object){
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };

    return this.httpClient.post(environment.base_url + '/quotes/create-quote', data, httpOptions);
  }

  update(id: number, data: object){
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'id': id.toString()
      }
    };

    return this.httpClient.post(environment.base_url + '/quotes/update-quote', data, httpOptions);
  }

  /******************************************** ARCHIVE METHODS ********************************************/
  archive (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/archive', ids, httpOptions);
  }

  activate (ids: object) {
    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/quotes/activate', ids, httpOptions);
  }

  /********************************************* MISC METHODS ***********************************************/

}
