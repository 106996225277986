import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { Team } from '../../../../shared/models/teams/team.model';
import { AuthService } from '../../../../shared/users/auth.service';
import { ContractsService } from './contracts.service';

@Injectable()
export class TeamsService {

  /**
   * @type {BehaviorSubject<Team>}
   */
  private _team = new BehaviorSubject<Team>(new Team());

  /**
   * @type {Observable<Team>}
   */
  public _team$ = this._team.asObservable();

  private userContracts: number[];
  
  constructor(
    private httpClient: HttpClient,
    private _contractsService: ContractsService
  ){}


  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @returns {Observable<Team[]>}
   */
  getTeamsDatatable(){
    return this.httpClient.get<Team[]>(environment.base_url + '/teams/teams-datatable', {params: {'auth_token': AuthService.getAuthToken()}})
      .map((teams: Team[]) => teams.map((team) => new Team().deserialize(team)));
  }

  /**
   *
   * @param {number} contract_id
   * @returns {Observable<Team[]>}
   */
  // TODO: refactor, this should filter by contracts in the backend
  getTeamList(contract_id?: number[] | number ){
    contract_id = contract_id ? contract_id : [0];
    return this.httpClient.get<Team[]>(environment.base_url + '/teams/get-team-list', {params: {'auth_token': AuthService.getAuthToken(), 'contract_id': contract_id.toString()}})
      .map((teams: Team[]) => teams.map((team) => new Team().deserialize(team)));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<Team>}
   */
  getTeam(id: number){
    return this.httpClient.get<Team>(environment.base_url + '/teams/get-team', {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} data
   * @returns {Observable<Object>}
   */
  create(data: object){
    return this.httpClient.post(environment.base_url + '/teams/create-team', data, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {number} id
   * @param {object} data
   * @returns {Observable<Object>}
   */
  update(id: number, data: object){
    return this.httpClient.post(environment.base_url + '/teams/update-team', data, {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ ARCHIVE METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archive (ids: object) {
    return this.httpClient.post(environment.base_url + '/teams/archive', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activate (ids: object) {
    return this.httpClient.post(environment.base_url + '/teams/activate', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {Team} team
   */
  setTeam(team: Team){
    if (team === null){
      team = new Team();
    }

    this._team.next(team);
  }

  /**
   *
   * @param {string} field
   * @param {string} value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate (field: string, value: string, id: number) {

    return this.httpClient.post(

      environment.base_url + '/teams/check-for-duplicate',

      {field: field, value: value},

      {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}}
    );
  }

  generateUniquePin(){
    return this.httpClient.get<string>(environment.base_url + '/teams/generate-unique-pin', {params: {'auth_token': AuthService.getAuthToken()}});
  }
}
