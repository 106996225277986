import {Deserializable} from '../deserializable.model';
import {Team} from '../teams/team.model';

export class RecurringDate implements Deserializable {
  id: number;
  due_date: string;
  raise_on_date: string;
  created_at: string;
  raised_at: string;
  deleted_at: string;
  team_id: number;
  team: Team;
  raise_date_holder:string;

  // final_date: string;

  due_date_holder:string;
  isCollapsed: true;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  constructor() {
    this.id = 0;
    this.due_date = '';
    this.created_at = '';
    this.raise_on_date = '';
    this.team = new Team;
    this.team_id = 0;
    // this.final_date = '';
  }
}
