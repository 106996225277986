import {Deserializable} from '../deserializable.model';
import {Account} from '../account/account.model';
import {BillingCompany} from '../billing/billing-company.model';
import {Contract} from './contract.model';
import {Site} from './site.model';

export class ClientAccount implements Deserializable {

  id: number;
  account_id: number;
  sales_ledger_code: string;
  name: string;
  primary_contact_number: string;
  primary_contact_email: string;
  primary_contact_name: string;
  address_1: string;
  address_2: string;
  address_3: string;
  address_4: string;
  postcode: string;
  payment_terms: string;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  updated_by_user_id: number;

  billing_company_ids: number[];
  contract_ids: number[];

  /*********** Relationships ************/
  account: Account;
  billing_companies: BillingCompany[];
  contracts: Contract[];
  sites: Site[];

  /*********** Functions ************/
  deserialize (input: any) {
    Object.assign(this, input);
    return this;
  }

}
