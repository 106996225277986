import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../../../environments/environment';
import {WorkInstructionService} from '../../../api/services/work-instruction.service';
import {WorkInstruction} from '../../../../../../shared/models/work-instruction/work-instruction.model';
import {WorkInstructionPhotosService} from '../../../api/services/work-instruction-photos.service';
import {WorkInstructionPhoto} from '../../../../../../shared/models/work-instruction/work-instruction-photo.model';
import {WorkInstructionPhotoType} from '../../../../../../shared/models/work-instruction/work-instruction-photo-type.model';
import {PhotosService} from '../../../api/services/photos.service';
import {NgSelectComponent} from '@ng-select/ng-select';
import {Ng2ImgToolsService} from 'ng2-img-tools';
import {RequirementType} from '../../../../../../shared/models/work-instruction/work-instruction-requirement-type.model';

@Component({
  selector: 'app-photo-list',
  templateUrl: './photo-list.component.html',
  styleUrls: ['./photo-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PhotoListComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();
  @Output() updated = new EventEmitter<boolean>();
  @Output() showModal = new EventEmitter<WorkInstructionPhoto>();
  
  @ViewChild('photoTypeSelect') type_select: NgSelectComponent;
  @ViewChild('requirementTypeSelect') requirement_type_select: NgSelectComponent;

  @Input() has_requirements                = false;
  @Input() requirements: RequirementType[] = [];
  @Input('workInstruction') public work_instruction: WorkInstruction;

  _types: WorkInstructionPhotoType[] = [];
  _selected_photo_type: WorkInstructionPhotoType;
  _selected_requirement_type:RequirementType;
  _photos: WorkInstructionPhoto[] = [];
  _is_required = false;

  isCollapsed = true;
  bucket_url = environment.bucket;
  formGroup: FormGroup;
  loading_photos = false;
  _is_actioning = false;

  constructor(
    private photo_service: PhotosService,
    private form_builder: FormBuilder,
    private work_instruction_service: WorkInstructionService,
    private work_instruction_photo_service: WorkInstructionPhotosService,
    private toastr_service: ToastrService,
    private img_comper: Ng2ImgToolsService
  ){

    this.formGroup = this.form_builder.group({
      'type_select': [null, Validators.required],
      'is_required': [this._is_required],
      'requirement_type_id': [{value:null, disabled:true}],
      'image_input': []
    })
  }

  ngOnInit() {
    if(!this.work_instruction) {
      this.work_instruction_service.work_instruction$.subscribe(data => {
        this.work_instruction = data;
      });
    }
    
    this.getPhotoTypes();
  }

  getPhotoTypes(){
    this.photo_service.getPhotoTypes().subscribe(data => {
      this._types = data;
    });
  }

  selectedPhotoType() {
    if(this.type_select.selectedItems.length > 0){
      this._selected_photo_type = this.type_select.selectedItems[0].value as WorkInstructionPhotoType;
    }
  }

  selectedRequirementType(){
    if (this.requirement_type_select.selectedItems[0]){
      this._selected_requirement_type = this.requirement_type_select.selectedItems[0].value as RequirementType;
    } else{
      this.formGroup.patchValue({'is_required': false});
      this.boxChecked();
      this._selected_requirement_type = null;
    }
  }

  boxChecked(){
    if (this.formGroup.get('is_required').value){
      this.formGroup.get('requirement_type_id').setValidators(Validators.required);
      this.formGroup.get('requirement_type_id').enable();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
    } else{
      this._selected_requirement_type = null;
      this.formGroup.get('requirement_type_id').reset();
      this.formGroup.get('requirement_type_id').clearValidators();
      this.formGroup.get('requirement_type_id').updateValueAndValidity();
      this.formGroup.get('requirement_type_id').disable();
    }
  }

  handleFileInput(files: FileList) {
    if (this._photos.length >= 10){
      this.toastr_service.error('LIMIT EXCEEDED (MAX 10 IN ONE UPLOAD). UPLOAD AFTER')
    } else {
      Array.from(files).forEach(data => {
        if (data.size > 5000000) {
          this.toastr_service.error('FILE ' + data.name + ' EXCEEDS FILE SIZE LIMIT (5MB)');
        } else {
          this.loading_photos = true;

          this.img_comper.compressImage(data, 0.3).subscribe(result => {
            const photo                          = new WorkInstructionPhoto();
            photo.name                           = this.formGroup.get('is_required').value ? '(' + this._selected_requirement_type.name + ') ' + data.name : data.name;;
            photo.file                           = result;
            photo.work_instruction_photo_type    = this._selected_photo_type;
            photo.work_instruction_photo_type_id = this._selected_photo_type.id;
            photo.is_special_requirement         = this.formGroup.get('is_required').value;
            photo.requirement_type_id            = this._selected_requirement_type ? this._selected_requirement_type.id : null;
            photo.requirement_type               = this._selected_requirement_type;

            this._photos.push(photo);

            this.loading_photos = false;
          }, error => {
            this.loading_photos = false;
          });
        }
      });
    }
  }

  removePhoto(index: number){
    this._photos.splice(index, 1);
  }

  onSubmit(){
      this._is_actioning = true;
      this.work_instruction_photo_service.addPhotos(this.work_instruction.id, this._photos).subscribe(data => {
        this.work_instruction.work_instruction_photos = data.work_instruction_photos;
        this.reset();
        this.toastr_service.success('PHOTOS ADDED');
        this._is_actioning = false;

        this.updated.emit();
      }, error1 => this._is_actioning = false);
  }

  reset(){
    this.formGroup.reset();
    this.formGroup.patchValue({'is_required': false});
    this.boxChecked();
    this._photos = [];
    this.isCollapsed = true;
  }

  closePhotos(){
    this.close.next(true);
    this.reset();
  }
}
