import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import {AuthService} from '../../../../../shared/users/auth.service';
import {environment} from '../../../../../../environments/environment';
import {Batch} from '../../../../../shared/models/work-instruction/batch.model';

@Injectable()
export class BatchService {

  /**
   * @type {BehaviorSubject<Batch>}
   * @private
   */
  private _batch = new BehaviorSubject<Batch>(new Batch());

  /**
   * @type {Observable<Batch>}
   * @private
   */
  public _batch$ = this._batch.asObservable();

  /**
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient) {}

  /**
   * @param {Batch} batch
   */
  setBatch(batch: Batch): void {
    this._batch.next(batch);
  }

  /**
   *
   * @returns {Observable<Batch[]>}
   */
  getBatches(@Optional() client_account_id:number = 0){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString()
      }
    };
    return this.httpClient.get<Batch[]>(environment.base_url + '/batches/get-batches', httpOptions)
      .map((instructions: Batch[]) => instructions.map((instruction) => new Batch().deserialize(instruction)));
  }

  getClientBatches(@Optional() client_account_id: number = 0){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString()
      }
    };
    return this.httpClient.get<Batch[]>(environment.base_url + '/batches/get-client-batches', httpOptions)
      .map((instructions: Batch[]) => instructions.map((instruction) => new Batch().deserialize(instruction)));
  }

  /**
   *
   * @param batch_id
   */
  getContractBatch(batch_id: string){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'batch_id': batch_id,
      }
    };

    return this.httpClient.get<Batch>(environment.base_url + '/batches/get-contract-batch', httpOptions)
      .map((instruction) => new Batch().deserialize(instruction));
  }

  /**
   *
   * @param {string} contract_id
   * @returns {Observable<Batch[]>}
   */
  getContractBatches(contract_id: string){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'contract_id': contract_id,
      }
    };

    return this.httpClient.get<Batch[]>(environment.base_url + '/batches/get-contract-batches', httpOptions)
      .map((instructions: Batch[]) => instructions.map((instruction) => new Batch().deserialize(instruction)));
  }

  store(batch:Batch){

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/batches', this.setFormData(batch), httpOptions);
  }

  update(batch:Batch){

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(environment.base_url + '/batches/' + batch.id, this.setFormData(batch), httpOptions);
  }

  private setFormData(batch: Batch){

    const form_data = new FormData();
    
    if(batch.file) form_data.append('file', batch.file);
    form_data.append('data', JSON.stringify(batch));

    return form_data;
  }
}
