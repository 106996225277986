import { Deserializable } from '../deserializable.model';
import { AccountType } from './account-type.model';

export class Account implements Deserializable {

  id: number;
  name: string;
  account_type_id: number;

  account_type: AccountType;

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }
}
