import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  EventEmitter
} from '@angular/core';

import { WkrModalComponent } from './wkr-modal.component';
import { Observable, Subject } from 'rxjs';

interface IDeployModalOptions {
  component?: any;
  title?: string;
  body?: string;
  modalClasses?: any[];
  openedClasses?: any[];
  size?: string
}

@Injectable()
export class WkrModalService {

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  public result = new Subject<boolean>();

  public deploy(options: IDeployModalOptions): Observable<boolean> {
    // Create a component reference from the component
    const component = this.factoryResolver
      .resolveComponentFactory(WkrModalComponent)
      .create(this.injector);

    // Add inputs into the component
    component.instance.title = options.title;
    component.instance.body = options.body;
    // component.instance.modal.show();

    this.appRef.attachView(component.hostView);

    // Get Node from component (so we can inject it)
    const domElem = (component.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append component to the DOM
    document.body.appendChild(domElem);

    component.instance.result
      .subscribe( modalResponse => {
        component.instance.modal.hide()

        // Remove component from DOM
        this.appRef.detachView(component.hostView);
        component.destroy();

        if (modalResponse) this.result.next(modalResponse)
        else this.result.error(modalResponse);
      });

    return this.result.asObservable();
  }
  
}