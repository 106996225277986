import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { environment } from '../../../../../../environments/environment';
import { WorkInstruction } from '../../../../../shared/models/work-instruction/work-instruction.model';
import { AuthService } from '../../../../../shared/users/auth.service';
import { map } from 'rxjs/operators';

export class workInstructionsFilter {
  client_account_id?: string = '0';
  contract_id?: string = '0';
  department_id?: string = '0';
  team_id?: string = '0';
  work_instruction_stage_id?: string = '0';
  invoiced?: string = 'false';
  cancelled?: string = 'false';
  from?: string = '0';
  page_limit?: string = '20';
  search_term?: string = null;
  is_high_risk?: string = 'false';

  constructor(filters) {
    // Stringify everything
    for (let filter in filters) {
      if (!filters.hasOwnProperty(filter)) return;
      filters[filter] = filters[filter] ? filters[filter].toString() : 0;
    }
    return Object.assign(this, filters);
  }
}

@Injectable()
export class WorkInstructionService {

  /**
   * These are being used when sidebars are toggled, 
   * no point setting a "shared" workinstruction in 
   * the service every single time when you can just 
   * pass it into the component through bindings  
   * (See below)
   * */ 

  private work_instruction = new BehaviorSubject<WorkInstruction>(
    new WorkInstruction()
  );
  work_instruction$ = this.work_instruction.asObservable();

  private work_instructions = new BehaviorSubject<WorkInstruction[]>([]);
  work_instructions$ = this.work_instructions.asObservable();

  setWorkInstruction(work_instruction: WorkInstruction) {
    this.work_instruction.next(work_instruction);
  }

  setWorkInstructions(work_instructions: WorkInstruction[]) {
    this.work_instructions.next(work_instructions);
  }

  constructor(private httpClient: HttpClient) {}

  /******************************************* GET METHODS *****************************************/

  getWorkInstructionDatatable(
    filters?: workInstructionsFilter
  ): Observable<WorkInstruction[]> {
    // TODO: Do not require every single input when calling method
    const filter = new workInstructionsFilter(filters);
    const endpoint = environment.base_url + '/work-instructions/work-instructions-datatable';

    const httpOptions = {
      params: {
        auth_token: AuthService.getAuthToken(),
        ...filter
      }
    };
    return this.httpClient
      .get<WorkInstruction[]>(endpoint, httpOptions)
      .pipe( map( (instructions:WorkInstruction[]) => instructions.map( instruction => new WorkInstruction().deserialize(instruction) )  ) );

  }

  getWorkstackWorkInstructions(
    filters?: workInstructionsFilter
  ): Observable<WorkInstruction[]> {
    // TODO: Do not require every single input when calling method
    const filter = new workInstructionsFilter(filters);
    const endpoint = environment.base_url + '/work-instructions/workstack-work-instructions';

    const httpOptions = {
      params: {
        auth_token: AuthService.getAuthToken(),
        ...filter
      }
    };
    return this.httpClient
      .get<WorkInstruction[]>(endpoint, httpOptions)
      .pipe( map( (instructions:WorkInstruction[]) => instructions.map( instruction => new WorkInstruction().deserialize(instruction) )  ) );

  }

  setWorkInstructionStages(ids: number[], work_instruction_stage_id: number) {
    const httpOptions = {
      params: { auth_token: AuthService.getAuthToken() }
    };

    return this.httpClient.post(
      environment.base_url + '/work-instructions/set-work-instruction-stages',
      {
        ids: ids,
        work_instruction_stage_id: work_instruction_stage_id
      },
      httpOptions
    );
  }

  getWorkInstruction(id: number) {
    const httpOptions = {
      params: {
        auth_token: AuthService.getAuthToken(),
        id: id.toString()
      }
    };

    return this.httpClient
      .get<WorkInstruction>(
        environment.base_url + '/work-instructions/get-work-instruction',
        httpOptions
      )
      .map((instruction: WorkInstruction) =>
        new WorkInstruction().deserialize(instruction)
      );
  }

  getPrintablePdf(work_instruction_id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf'
      }),
      params: {
        auth_token: AuthService.getAuthToken(),
        work_instruction_id: work_instruction_id.toString()
      },
      responseType: 'blob' as 'text'
    };

    return this.httpClient.get(
      environment.base_url + '/work-instructions/printable-pdf',
      httpOptions
    );
  }

  /******************************************* POST METHODS *****************************************/
  create(data: WorkInstruction) {
    const formData = new FormData();

    data.work_instruction_photos.forEach(item => {
      formData.append('photos[]', item.file);
    });

    data.documents.forEach(item => {
      formData.append('document_files[]', item.file);
    });

    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(
      environment.base_url + '/work-instructions/create-work-instruction',
      formData,
      { params: { auth_token: AuthService.getAuthToken() } }
    );
  }

  update(id: number, data: any) {
    const formData = new FormData();

    if (data.work_instruction_photos) {
      data.work_instruction_photos.forEach(item => {
        formData.append('photos[]', item.file);
      });
    }

    if (data.documents) {
      data.documents.forEach(item => {
        formData.append('document_files[]', item.file);
      });
    }

    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(
      environment.base_url + '/work-instructions/update-work-instruction',
      formData,
      { params: { auth_token: AuthService.getAuthToken(), id: id.toString() } }
    );
  }

  close(id: number, note: string) {
    const httpOptions = {
      params: {
        auth_token: AuthService.getAuthToken()
      }
    };
    return this.httpClient.post(
      environment.base_url + '/work-instructions/close',
      { id: id.toString(), note: note },
      httpOptions
    );
  }

  /******************************************** ARCHIVE METHODS ********************************************/
  archive(ids: object) {
    return this.httpClient.post(
      environment.base_url + '/work-instructions/archive',
      ids,
      { params: { auth_token: AuthService.getAuthToken() } }
    );
  }

  activate(ids: object) {
    return this.httpClient.post(
      environment.base_url + '/work-instructions/activate',
      ids,
      { params: { auth_token: AuthService.getAuthToken() } }
    );
  }

  /********************************************* MISC METHODS ***********************************************/

  apply(id: number, data: WorkInstruction) {
    const formData = new FormData();

    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(
      environment.base_url + '/work-instructions/apply-work-instruction-changes',
      formData,
      { params: { auth_token: AuthService.getAuthToken(), id: id.toString() } }
    );
  }
}
