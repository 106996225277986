import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { ImportType } from '../../../../shared/models/documents/import-type.model';
import { AuthService } from '../../../../shared/users/auth.service';

@Injectable()
export class ImportTypeService {

  constructor(private httpClient: HttpClient){
  }

  /**
   * Return a listing of ImportTypes
   * @returns {Observable<ImportType[]>}
   */
  getImportTypes(): Observable<ImportType[]>{
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.httpClient.get<ImportType[]>(environment.base_url + '/documents/get-import-types', httpOptions)
      .map((import_types: ImportType[]) => import_types.map((import_type) => new ImportType().deserialize(import_type)));
  }
}
