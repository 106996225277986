import { Component, EventEmitter, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Department } from '../../../../../shared/models/departments/department.model';
import { TaskCategory } from '../../../../../shared/models/work-instruction/task-category.model';
import { CategoryService } from '../../../api/services/category.service';
import { ContractsService } from '../../../api/services/contracts.service';
import { DepartmentsService } from '../../../api/services/departments.service';

@Component({
  selector: 'app-department-wizard',
  templateUrl: './department-wizard.component.html',
  styleUrls: ['./department-wizard.component.scss']
})
export class DepartmentWizardComponent implements OnInit {
  public scrollbarOptions = {axis: 'y', theme: 'minimal-dark'};

  @Output() refreshTable = new EventEmitter<boolean>();

  @ViewChild('departmentWizardModal') department_modal: ModalDirective;
  @ViewChild('stepper') stepper: MatStepper;

  @ViewChild('contract_select') _contract_select: NgSelectComponent;

  _is_create = true;
  _is_deactivated = false;
  _is_contract_builder = false;

  _department_form: FormGroup;
  _department: Department;

  _contracts;
  _categories;

  _selected_categories: TaskCategory[] = [];

  /**
   *
   * @param {ContractsService} contract_service
   * @param {DepartmentsService} departments_service
   * @param {CategoryService} categories_service
   * @param {FormBuilder} form_builder
   * @param {ToastrService} toastr_service
   */
  constructor(private contract_service: ContractsService,
              private departments_service: DepartmentsService,
              private categories_service: CategoryService,
              private form_builder: FormBuilder,
              private toastr_service: ToastrService) {

    this._department          = new Department();
    this._department_form     = this.setForm();
  }

  ngOnInit() {
    this.departments_service._department$.subscribe( data => {
      this._department = data;
    })
  }

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  getContractList() {
    this.contract_service.getContractList().subscribe( data => {
      this._contracts = data;
    });
  }

  getCategoryList() {
    this.categories_service.getCategoryList(0).subscribe(data => {
      this._categories = data;
    })
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  createDepartment() {
    this.selectedCategories();

    this.departments_service.create(this._department).subscribe(data => {
      this.success('DEPARTMENT CREATED');
      this.hide();
    });
  }

  updateDepartment() {
    this.selectedCategories();

    this.departments_service.update(this._department.id, this._department).subscribe(data => {
      this.success('DEPARTMENT UPDATED');
      this.hide();
    });
  }

  /* +++++++++++++++++++++++++++++++ MODAL METHODS ++++++++++++++++++++++++++++++++ */

  show(is_deactivated: boolean, @Optional() is_contract_builder: boolean = false, @Optional() contract_id: number = null) {

    this._is_deactivated          = is_deactivated;
    this._is_contract_builder     = is_contract_builder;

    if(this._is_contract_builder){
      this._department.contract_id  = contract_id;
    }

    this._department_form         = this.setForm();
    this._selected_categories     = this._department.task_categories || [];

    this.getContractList();
    this.getCategoryList();

    if (this._department.id > 0){
      this._is_create = false;
    } else {
      this._is_create = true;
    }

    this.department_modal.show();

    this.onChanges();
  }

  hide() {
    this.reset();
    this.department_modal.hide();
  }

  reset() {
    this.refreshTable.emit(true);
    this._is_create           = true;
    this._selected_categories = [];

    if (!this._is_deactivated) {
      this.stepper.reset();
    }
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  checkForDuplicate(field: string){
    if (this._department_form.get('code').value) {
      this.departments_service.checkForDuplicate(field, this._department_form.get(field).value, this._department.id).subscribe(data => {
        if (data > 0) {
          this._department_form.get(field).setErrors({'notUnique': true});
        }
      });
    }
  }

  checkForDuplicateDepartment(){
    if(this._department_form.get('name').valid){
      this.departments_service.checkForDuplicateDepartment(this._department.name, this._department.contract_id).subscribe(data => {
        if(data){
          this._department_form.get('name').setErrors({'notUnique': true});
        }
      })
    }
  }

  selectedCategories(){
    this._department.task_category_ids = this._selected_categories.map(item => item.id)
  }

  setForm(){
    return this.form_builder.group({
      contract_id: [this._department.contract_id, Validators.required],
      name: [this._department.name, Validators.required],
      code: []
    });
  }

  onChanges(){
    this._department_form.valueChanges.subscribe( values => {
      this._department.deserialize(values);
    })
  }

  success(message: string) {
    this.toastr_service.success(message);
    this._is_create = true;
  }
}
