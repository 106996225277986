import {Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {Cycle} from '../../../../../shared/models/work-instruction/cycle.model';
import {CycleService} from '../../api/services/cycle.service';
import {WorkInstruction} from '../../../../../shared/models/work-instruction/work-instruction.model';
import {WorkInstructionStages} from '../../../reactive/api/constants/work-instruction-statuses';
import {CompleteWorkInstructionModalComponent} from '../../../reactive/work-instructions/work-instruction/modals/complete-work-instruction-modal/complete-work-instruction-modal.component';
import {UnableCompleteWorkInstructionModalComponent} from '../../../reactive/work-instructions/work-instruction/modals/unable-complete-work-instruction-modal/unable-complete-work-instruction-modal.component';
import {CancelWorkInstructionModalComponent} from '../../../reactive/work-instructions/work-instruction/modals/cancel-work-instruction-modal/cancel-work-instruction-modal.component';
import {AuthService} from '../../../../../shared/users/auth.service';

@Component({
  selector: 'app-cycle-job-list',
  templateUrl: './cycle-job-list.component.html',
  styleUrls: ['./cycle-job-list.component.scss']
})
export class CycleJobListComponent implements OnInit, OnChanges {
  @Output() close       = new EventEmitter<boolean>();
  @Input() type_showing = 0;
  @Input() cycle_id     = 0;
  @Input() cycle        = new Cycle();
  @Input() team_id      = 0;

  @Output() showAllModal = new EventEmitter<{status: number, work_instruction:WorkInstruction}>();

  type_string = '';

  _is_loading = false;
  account_id = AuthService.getAccountId();

  work_instructions: WorkInstruction[] = [];

  @ViewChild('completeWorkInstructionModal') complete_work_instruction_modal: CompleteWorkInstructionModalComponent;
  @ViewChild('unableCompleteWorkInstructionModal') unable_complete_work_instruction_modal: UnableCompleteWorkInstructionModalComponent;
  @ViewChild('cancelWorkInstructionModal') cancel_work_instruction_modal: CancelWorkInstructionModalComponent;


  work_instruction_stages = new WorkInstructionStages();

  constructor(private cycle_service: CycleService) {}

  ngOnInit() {}

  ngOnChanges(){
    switch (this.type_showing){
      case WorkInstructionStages.COMPLETED: this.type_string = 'COMPLETED'; break;
      case WorkInstructionStages.ISSUED: this.type_string = 'ISSUED'; break;
      case WorkInstructionStages.PENDING: this.type_string = 'PENDING'; break;
      case WorkInstructionStages.UNABLE_TO_COMPLETE: this.type_string = 'UTC'; break;
      case WorkInstructionStages.CANCELLED: this.type_string = 'CANCELLED'; break;
    }
    this.getData();
  }

  getData(){
    this._is_loading = true;
    this.cycle_service.getCycleJobList(this.cycle_id, this.type_showing, this.team_id).subscribe( data => {
      this.work_instructions = data;
      this._is_loading = false;
    })
  }

  closeJobList(){
    this.close.next(true);
    this.work_instructions = [];
  }

  refresh (refresh: boolean) {
    if (refresh) { this.getData(); }
  }

  actionWorkInstruction(status:number, work_instruction:WorkInstruction){
    this.showAllModal.next({status:status, work_instruction: work_instruction});
  }

  viewJob(id: number){
    localStorage.setItem('scheduled_work_instruction_id', id.toString());
  }
}
