import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { saveAs } from 'file-saver';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClientAccount } from '../../../../../shared/models/client/client-account.model';
import { Contract } from '../../../../../shared/models/client/contract.model';
import { StringHelper } from '../../../../../utility/helpers/string-helper';
import { ClientsService } from '../../../../admin/api/services/clients.service';
import { ContractsService } from '../../../../admin/api/services/contracts.service';
import { ImportsService } from '../../../../admin/api/services/imports.service';

@Component({
  selector: 'app-scheduling-export-wizard',
  templateUrl: './scheduling-export-wizard.component.html',
  styleUrls: ['./scheduling-export-wizard.component.scss']
})
export class SchedulingExportWizardComponent implements OnInit {

  @ViewChild('exportModal') modal: ModalDirective;
  @ViewChild('clientAccountSelect') client_account_select: NgSelectComponent;

  clients: ClientAccount[] = [];
  contracts: Contract[]    = [];

  form: FormGroup;

  constructor (private form_builder: FormBuilder,
               private client_service: ClientsService,
               private contract_service: ContractsService,
               private import_service: ImportsService,
               private toastr_service: ToastrService) {
  }

  ngOnInit () {
    this.form = this.setForm();
    this.client_service.getClients().subscribe(data => this.clients = data);
  }

  onClientSelect(){

    const client = this.form.get('client_account').value;

    this.form.patchValue({'contract' : null});

    if(client){
      this.contract_service.getContracts(client.account_id).subscribe(data => this.contracts = data);
    } else {
      this.contracts = [];
    }
  }

  show(){
    this.modal.show();
  }

  hide(){
    this.form.reset();
    this.modal.hide();
  }

  onExport(){

    const client = this.form.get('client_account').value;
    const contract = this.form.get('contract').value;

    this.hide();
    this.import_service.getSchedulingExport(client.account_id, contract.id).toPromise().then(response => {

      if(response.size > 2){
        this.download(response, client, contract);
      }
    });
  }

  private download(response, client: ClientAccount, contract: Contract){

    const date = new Date();
    const filename = 'scheduling-export-' + StringHelper.slugify(client.name) + '-' + StringHelper.slugify(contract.name)
                                          + '-'
                                          + date.toLocaleDateString() + '-' + date.toLocaleTimeString();

    const blob = new Blob([response], {type: 'text/xlsx'});
    saveAs(blob, filename + '.xlsx');
    this.toastr_service.success(filename + ' was exported successfully');
  }

  private setForm(){
    return this.form_builder.group({
      client_account: [null, [Validators.required]],
      contract: [null, [Validators.required]]
    })
  }
}
