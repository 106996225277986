import {Component, EventEmitter, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Rx';
import { BillingCompany } from '../../../../shared/models/billing/billing-company.model';
import { Contract } from '../../../../shared/models/client/contract.model';
import { BillingCompanyService } from '../../api/services/billing-company.service';
import { ClientsService } from '../../api/services/clients.service';
import { ToastrHelperService } from '../../../../utility/helpers/toastr-helper.service';
import { MatStepper } from '@angular/material';
import { ModalDirective } from 'ngx-bootstrap';
import { InputMasks } from '../../../../utility/helpers/input-masks';
import { RegexPatterns } from '../../../../utility/helpers/regex-patterns';
import { ContractsService } from '../../api/services/contracts.service';
import {ClientAccount} from '../../../../shared/models/client/client-account.model';
import {AuthService} from "../../../../shared/users/auth.service";

@Component({
  selector: 'app-client-wizard',
  templateUrl: './client-wizard.component.html',
  styleUrls: ['./client-wizard.component.scss']
})
export class ClientWizardComponent implements OnInit, OnDestroy {

  public _scrollbarOptions        = {axis: 'y', theme: 'minimal-dark'};
  public _is_create               = false;
  public _is_deactivated: boolean = false;
  public _is_contract_builder: boolean = false;

  public _client: ClientAccount;
  public _form: FormGroup;
  public _contracts: Contract[]                = [];
  public _billing_companies: BillingCompany[]  = [];
  public _selected_contracts: Contract[]       = [];
  public _selected_companies: BillingCompany[] = [];

  private _subscription: Subscription;

  @ViewChild('clientModal') modal: ModalDirective;
  @ViewChild('stepper') stepper: MatStepper;

  @Output() refreshTable = new EventEmitter<boolean>();

  constructor (private _fb: FormBuilder,
               private _billing_company_service: BillingCompanyService,
               private _client_service: ClientsService,
               private _contract_service: ContractsService,
               private _toastr_helper: ToastrHelperService,
               public _input_masks: InputMasks,
               private _regex_patterns: RegexPatterns) {
    this._client = new ClientAccount();
    this._form   = this.setForm();
  }

  /******************** Lifecycle Hooks Start ******************/

  ngOnInit () {
    this._subscription = this._client_service._client$.subscribe(client => this._client = client);
  }

  ngOnDestroy () {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  /******************* Lifecycle Hooks End ***********************/


  /******************* Public Functions *************************/

  store () {
    this.setAssignmentIds();
    this._client_service.store(this._client).subscribe(data => this.onCreateOrUpdate(data));
  }

  update () {
    this.setAssignmentIds();
    this._client.updated_by_user_id = AuthService.getUserId();
    this._client_service.update(this._client).subscribe(data => this.onCreateOrUpdate(data));
  }

  /**
   * @param {string} field
   */
  checkForDuplicate (field: string) {

    if (this._form.get(field).valid) {

      this._client_service.checkForDuplicate(field, this._form.get(field).value, this._client.id).subscribe(data => {

        if (data > 0) {
          this._form.get(field).setErrors({'notUnique': true});
        }
      });
    }
  }

  /**
   *
   * @param {boolean} is_deactivated
   * @param {boolean} is_contract_builder
   */
  show (is_deactivated: boolean, @Optional() is_contract_builder: boolean = false) {

    this._is_deactivated = is_deactivated;
    this._is_contract_builder = is_contract_builder;
    this._form           = this.setForm();

    this._contract_service.getAllContracts('0').subscribe(contracts => this._contracts = contracts);

    this._billing_company_service.getBillingCompanySelectList().subscribe(companies => this._billing_companies = companies);

    this._is_create = typeof this._client.id === 'undefined';

    this.modal.show();
    this.onChanges();
  }

  hide () {
    this.reset();
    this.refreshTable.emit(true);
    this.modal.hide();
  }

  reset () {
    if (!this._is_deactivated) {
      this.stepper.reset();
    }
  }

  setAssignments (stepper: MatStepper) {

    this._selected_contracts = this._client.contracts || [];
    this._selected_companies = this._client.billing_companies || [];
    stepper.next();
  }

  /******************* Private Functions ***********************/

  /**
   * @param client
   */
  private onCreateOrUpdate (client: any) {

    this._toastr_helper.setCreateUpdateMessage(this._is_create, client.name);
    this.refreshTable.emit(true);
    this.hide();
  }

  /**
   * @returns {FormGroup}
   */
  private setForm (): FormGroup {
    return this._fb.group({
      name: [this._client.name, [Validators.required]],
      primary_contact_name: [this._client.primary_contact_name, [Validators.required]],
      primary_contact_email: [this._client.primary_contact_email, [Validators.required, Validators.pattern(this._regex_patterns.email)]],
      primary_contact_number: [this._client.primary_contact_number, [Validators.required, Validators.pattern(this._regex_patterns.phone_number)]],
      address_1: [this._client.address_1, Validators.required],
      address_2: [this._client.address_2],
      address_3: [this._client.address_3],
      address_4: [this._client.address_4],
      postcode: [this._client.postcode, [Validators.required, Validators.pattern(this._regex_patterns.uk_post_code)]],
      payment_terms: [this._client.payment_terms, Validators.pattern(this._regex_patterns.only_whole_numbers)],
      sales_ledger_code: [this._client.sales_ledger_code, [Validators.required]]
    });
  }

  /**
   * update the model on form value changes
   */
  private onChanges (): void {
    this._form.valueChanges.subscribe(values => this._client.deserialize(values));
  }

  private setAssignmentIds () {

    this._client.billing_company_ids = this._selected_companies.map(a => a.id);
    this._client.contract_ids        = this._selected_contracts.map(a => a.id);
  }
}
