import {AuthService} from '../../../../shared/users/auth.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RequirementType} from '../../../../shared/models/work-instruction/work-instruction-requirement-type.model';
import {BehaviorSubject} from 'rxjs/index';

@Injectable()
export class RequirementTypesService {

  /**
   * @type {BehaviorSubject<RequirementType>}
   */
  private _requirement_type = new BehaviorSubject<RequirementType>(new RequirementType());

  /**
   * @type {Observable<RequirementType>}
   * @private
   */
  public _requirement_type$ = this._requirement_type.asObservable();

  /**
   * constructor
   * @param {HttpClient} http
   */
  constructor (private http: HttpClient) {
  }

  /******************** Behaviour Subject **************/

  /**
   * @param {RequirementType} requirement_type
   */
  setRequirementType (requirement_type: RequirementType): void {
    this._requirement_type.next(requirement_type);
  }

  /******************** API CALLS **********************/

  /**
   * @param {boolean} with_deactivated
   * @returns {Observable<RequirementType[]>}
   */
  getRequirementTypes (with_deactivated: boolean) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'with_deactivated': with_deactivated.toString()
      }
    };

    return this.http.get<RequirementType[]>(environment.base_url + '/requirement-types/get-requirement-types', httpOptions)
      .map((requirement_types: RequirementType[]) => requirement_types.map((requirement_type) => new RequirementType().deserialize(requirement_type)));
  }

  /**
   * @param {object} requirement_type
   * @param {number} id
   * @returns {Observable<Object>}
   */
  update (requirement_type: object, id: number) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.http.post(environment.base_url + '/requirement-types/' + id, requirement_type, httpOptions);
  }

  /**
   * @param {object} requirement_type
   * @returns {Observable<Object>}
   */
  store (requirement_type: object) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };
    return this.http.post(environment.base_url + '/requirement-types', requirement_type, httpOptions);
  }

  /**
   * @param {string} field
   * @param value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate (field: string, value: any, id: number) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    id = id || 0;
    return this.http.post(
      environment.base_url + '/requirement-types/check-for-duplicate',
      {field: field, value: value, id: id},
      httpOptions);
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archive (ids: object) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.http.post(environment.base_url + '/requirement-types/archive-requirement-types', ids, httpOptions);
  }

  /**
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activate (ids: object) {

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken()
      }
    };

    return this.http.post(environment.base_url + '/requirement-types/activate-requirement-types', ids, httpOptions);
  }
}
