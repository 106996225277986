import {Deserializable} from '../deserializable.model';
import {Contract} from '../client/contract.model';
import { Task } from '../work-instruction/task.model';

export class Team implements Deserializable{

  id: number;
  name: string;
  pin: string;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  active_work_instructions = 0;

  contract_ids: number[];

  contracts: Contract[];

  tasks: Task[];

  scheduled_complete_count: number;
  scheduled_issued_count: number;
  scheduled_pending_count: number;
  scheduled_utc_count: number;
  scheduled_cancelled_count:number;
  scheduled_due_this_week_count: number;
  donut_data: {name: string, value: number};

  cycle_routed: boolean = false;
  cycle_issued: boolean = false;

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id = 0;
    this.name = '';
    this.pin = '';

    this.created_at = '';
    this.updated_at = '';
    this.deactivated_at = '';
    this.deleted_at = '';

    //this.contract_ids = [];

    //this.contracts = [];
  }
}
