import { VatRate } from '../billing/vat-rate.model';
import { Deserializable } from '../deserializable.model';
import { Team } from '../teams/team.model';
import { TaskCategory } from './task-category.model';
import { WorkInstruction } from './work-instruction.model';
import {TaskStatuses} from '../../constants/task-statuses';
import {RecurringTemplate} from "../recurring/recurring-template.model";

export class Task implements Deserializable {

  id: number;
  work_instruction_id: number;
  task_category_id: number;
  team_id: number;
  task_status_id: number;
  vat_rate_id: number;
  description: string;
  unit_value: number;
  quantity: number;
  time_taken: number;
  to_sync: boolean;

  due_date: string;
  completed_date: string;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  work_instruction: WorkInstruction;
  recurring_template: RecurringTemplate;
  task_category: TaskCategory;
  team: Team;
  vat_rate: VatRate;

  //For Task Creator
  isCollapsed = true;
  team_list: Team[] = [];
  category_list: TaskCategory[] = [];
  vat_rate_list: VatRate[] = [];
  due_date_holder: string;

  selected_team: Team;
  selected_category: TaskCategory;
  selected_vat_rate: VatRate;

  deserialize(input: any){
    Object.assign(
      this,
      input,
      );
    return this;
  }

  constructor(){
    this.id = 0;

    this.task_status_id = TaskStatuses.PENDING;

    this.task_category_id = null;
    this.team_id = null;
    this.vat_rate_id = null;

    this.description = '';
    this.unit_value = 0;
    this.quantity = 0;
    this.to_sync = false;

    this.due_date = '';
    this.completed_date = '';

    this.created_at = '';
    this.updated_at = '';
    this.deactivated_at = '';
    this.deleted_at = '';

    this.team = new Team();
    this.vat_rate = new VatRate();
    this.task_category = new TaskCategory();
  }
}
