import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UnauthorisedComponent implements OnInit {

  constructor (private router: Router) {
  }

  ngOnInit () {
  }

  go(){
    this.router.navigate(['/dashboards'])
  }
}
