import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WorkInstruction } from '../../../../../../shared/models/work-instruction/work-instruction.model';
import { WorkInstructionService } from '../../../api/services/work-instruction.service';
import { AgmMap } from '@agm/core';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-work-instruction-sidebar-details',
  templateUrl: './work-instruction-sidebar-details.component.html',
  styleUrls: ['./work-instruction-sidebar-details.component.scss']
})
export class WorkInstructionSidebarDetailsComponent implements OnInit, OnDestroy {
  work_instruction: WorkInstruction;

  @Output() close = new EventEmitter<boolean>();
  @ViewChild('locationMap') map: AgmMap;

  private subscription: Subscription;

  constructor (private work_instruction_service: WorkInstructionService) {}

  ngOnInit () {
    this.subscription = this.work_instruction_service.work_instruction$.subscribe(data => {
      this.work_instruction = data;
    });
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}
