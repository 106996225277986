import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {WorkInstruction} from "../../../../../shared/models/work-instruction/work-instruction.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {AuthService} from "../../../../../shared/users/auth.service";

@Injectable()
export class BulkUploadService {

  private bulk_upload = new BehaviorSubject<WorkInstruction>(new WorkInstruction());
  public $work_instruction = this.bulk_upload.asObservable();

  constructor (private httpClient: HttpClient) {}

  // ******************************* CREATION OF THE WORK INSTRUCTIONS **************************** //

  store(bulk_uplaod_object: BulkUploadObject){
  const httpOptions = {
    params: {
      'auth_token': AuthService.getAuthToken(),
    }
  };
  return this.httpClient.post(environment.base_url + '/imports', this.setFormData(bulk_uplaod_object), httpOptions)
  }
  /******************************************* PREVIEW METHOD *****************************************/
  generatePreview(file: File, client_account_id:number, contract_id:number){
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'client_account_id': client_account_id.toString(),
        'contract_id': contract_id.toString()
      }
    };

    const form_data = new FormData();
    form_data.append('file', file);

    return this.httpClient.post<PreviewObject>(environment.base_url + '/bulk-upload-wizard/generate-preview', form_data, httpOptions);
  }

  private setFormData(bulk_upload_object: BulkUploadObject){
    const form_data = new FormData();
    if(bulk_upload_object.file){
      form_data.append('file', bulk_upload_object.file);
    }
    form_data.append('data', JSON.stringify(bulk_upload_object));
    return form_data
  }
}

export class BulkUploadObject{
  client_account_id = 0;
  contract_id = 0;
  file: File  = null;
}

export class PreviewObject{
  job_count = 0;
  value = 0;
  job_data: JobDataPreview[] = []
}

export class JobDataPreview{
  description = '';
  site = '';
  tasks = 0;
  net_val = 0;
  team: '';
  department: '';
  task_categories: '';
  estimated_time: '';
}
