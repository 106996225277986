import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { User } from '../models/user/user.model';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {

  /**
   * @type {string}
   */
  private url = '/user/login';

  /**
   * @returns {number | null}
   */
  static getUserId(){
    const user = JSON.parse(localStorage.getItem('user'));
    return user.id;
  }

  /**
   * Get the logged in user auth token
   * @returns {string | null}
   */
  static getAuthToken(){
    return localStorage.getItem('auth_token');
  }

  /**
   *
   * @returns {any}
   */
  static getAccountId(){
    const user = JSON.parse(localStorage.getItem('user'));
    return user.account_id;
  }

  /**
   * @returns {string | null}
   */
  static getLoggedInUserFullName() {

    const user = JSON.parse(localStorage.getItem('user'));

    return user.first_name + ' ' + user.last_name;
  }

  /**
   * @returns {string | null}
   */
  static getLoggedUserName() {

    const user = JSON.parse(localStorage.getItem('user'));

    return user.username;
  }

  /**
   *
   * @returns {any}
   */
  static getLoggedInUserEmail(){
    const user = JSON.parse(localStorage.getItem('user'));

    return user.email;
  }

  /**
   *
   * @param {HttpClient} http
   * @param {Router} router
   */
  constructor(private http: HttpClient,
              private router: Router) {
  }

  /**
   *
   * @param {User} user
   * @returns {Observable<Object>}
   */
  authenticate(user: User) {
    return this.http.post(environment.base_url + this.url, {username: user.username, password: user.password});
  }

  /**
   * @returns {Promise<boolean> | Navigation | any | void}
   */
  logout() {
    localStorage.clear();
    return this.router.navigate(['/']);
  }

  /**
   * @deprecated
   * @returns {string | null}
   */
  getAuthToken(){
    return localStorage.getItem('auth_token');
  }

}
