export class InvoiceStatuses {

  public static readonly NOT_RELEASED = 1;
  public static readonly RELEASED     = 2;
  public static readonly READY        = 3;
  public static readonly INVOICED     = 4;

  getNotReleased(){
    return InvoiceStatuses.NOT_RELEASED;
  }

  getReleased(){
    return InvoiceStatuses.RELEASED;
  }

  getReady(){
    return InvoiceStatuses.READY;
  }

  getInvoiced(){
    return InvoiceStatuses.INVOICED;
  }

  getInvoiceStatusText(status: number){
    switch (status) {
      case InvoiceStatuses.NOT_RELEASED:
        return 'NOT RELEASED';
      case InvoiceStatuses.RELEASED:
        return 'REVIEW FOR INVOICING';
      case InvoiceStatuses.READY:
        return 'READY TO INVOICE';
      case InvoiceStatuses.INVOICED:
        return 'INVOICED';
    }
  }
}
