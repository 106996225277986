import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { WorkInstruction } from '../../../../../../../shared/models/work-instruction/work-instruction.model';
import {AuthService} from '../../../../../../../shared/users/auth.service';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {WorkInstructionService} from '../../../../api/services/work-instruction.service';
import {TaskStatuses} from '../../../../../../../shared/constants/task-statuses';
import {WorkInstructionStages} from '../../../../api/constants/work-instruction-statuses';

@Component({
  selector: 'app-unable-complete-work-instruction-modal',
  templateUrl: './unable-complete-work-instruction-modal.component.html',
  styleUrls: ['./unable-complete-work-instruction-modal.component.scss']
})
export class UnableCompleteWorkInstructionModalComponent implements OnInit {
  @ViewChild('unableToCompleteWorkInstructionModalComp') modal: ModalDirective;

  @Output() refreshData = new EventEmitter<boolean>();

  formGroup: FormGroup;

  account_id;

  completed_date_holder: Date;
  completed_date;

  work_instruction = new WorkInstruction();

  hours = 0;
  mins = 0;
  time_taken = 0;

  _is_actioning = false;

  constructor(private work_instruction_service: WorkInstructionService,
              private form_builder: FormBuilder,
              private auth_service: AuthService,
              private toastr_service: ToastrService) {
  }

  ngOnInit() {
    this.formGroup = this.form_builder.group({
      completed_date: ['', Validators.required],
      note: ['', Validators.required],
      hours: [0, []],
      mins: [0, []],
    });
  }

  show(workInstruction: WorkInstruction) {
    this.work_instruction = workInstruction;
    this.completed_date_holder = new Date();

    this.modal.show();
  }

  hide() {
    this.work_instruction = new WorkInstruction();
    this.formGroup.reset();
    this.modal.hide();
  }

  accept() {
    this._is_actioning = true;

    this.work_instruction.work_instruction_stage_id = WorkInstructionStages.UNABLE_TO_COMPLETE;
    this.work_instruction.updated_by_user_id = AuthService.getUserId();
    this.work_instruction.to_sync = true;

    this.completed_date = this.completed_date_holder.getFullYear() + '-' + (this.completed_date_holder.getMonth() + 1) + '-' + this.completed_date_holder.getDate();

    this.work_instruction.completed_at = this.completed_date.toString();

    this.work_instruction.tasks.forEach(task => {
        if (task.task_status_id === TaskStatuses.PENDING){
          task.task_status_id = TaskStatuses.UNABLE_TO_COMPLETE;
          task.completed_date = this.work_instruction.completed_at;
          task.time_taken     = this.time_taken;
          task.to_sync        = this.work_instruction.to_sync;
        }
    });

    this.work_instruction.note = 'UNABLE TO COMPLETE REASON: ' + this.work_instruction.note;

    this.work_instruction_service.update(this.work_instruction.id, this.work_instruction).subscribe( data => {
      if (data){
        this.refreshData.emit(true);
        this.toastr_service.success('UPDATED STATUS');
        this._is_actioning = false;
        this.hide();
      }
    }, error1 => this._is_actioning = false);
  }

  calculateTimeTaken(){
    let total = 0;

    total += this.hours * 60;
    total += this.mins;

    let task_count = 0;

    this.work_instruction.tasks.forEach(task =>{
      if (task.task_status_id === TaskStatuses.PENDING){
        task_count++;
      }
    });

    this.time_taken = total/task_count;
  }
}
