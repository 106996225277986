import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WorkInstruction } from 'app/shared/models/work-instruction/work-instruction.model';
import { WorkInstructionService } from '../../../../api/services/work-instruction.service';
import {WorkInstructionStages} from '../../../../api/constants/work-instruction-statuses';
import {TeamsService} from '../../../../../../admin/api/services/teams.service';
import {Team} from 'app/shared/models/teams/team.model';

@Component({
  selector: 'app-closed-modal',
  templateUrl: './closed-modal.component.html',
  styleUrls: ['./closed-modal.component.scss']
})
export class ClosedModalComponent implements OnInit {

  @ViewChild('closeInstructionModal') _modal: ModalDirective;

  @Output() refreshTable = new EventEmitter<boolean>();

  formGroup: FormGroup;
  work_instruction: WorkInstruction;
  reference_number = '';

  completed_date_holder: Date;

  teams: Team[] = [];

  reasons = [
    {reason:'TOO EXPENSIVE'},
    {reason:'JOB NOT PROGRESSING'},
    {reason:'DEADLINE MISSED'},
    {reason:'QUOTE INCORRECT'},
    {reason:'OTHER'},
  ];

  constructor(
    private _work_instruction_service: WorkInstructionService,
    private _fb: FormBuilder,
    private _toastr_service: ToastrService,
    private _team_service: TeamsService) {
  }

  ngOnInit() {
    this.formGroup = this.setForm();
  }

  selectedReason(){
    if (this.formGroup.get('note').value === 'OTHER'){
      this.formGroup.setControl('text', new FormControl('', [Validators.required]))
    }
  }

  getTeams(){
    this._team_service.getTeamList(this.work_instruction.contract_id).subscribe(data =>{
      this.teams = data;
    })
  }

  setForm(){
    return this._fb.group({
      note: ['', Validators.required],
      text: ['', []],
      completed_date: ['', []],
      team: ['', []],
    });
  }

  /**
   * @param {WorkInstruction} work_instruction
   */
  show(work_instruction: WorkInstruction){
    this.formGroup = this.setForm();
    this.work_instruction = work_instruction;
    this.reference_number = this.work_instruction.reference_number;
    this.completed_date_holder = new Date();
    this.getTeams();
    this._modal.show();
  }

  hide(){
    this.formGroup.reset();
    this.formGroup = this.setForm();
    this._modal.hide();
  }

  accept(){
    let full_note = 'CLOSED: ' + this.formGroup.get('note').value + ' ';

    if (this.formGroup.get('text').value){
      full_note += '(' + this.formGroup.get('text').value + ') ';
    }

    full_note += this.formGroup.get('team').value;

    const completed_date = this.completed_date_holder.getFullYear() + '-' + (this.completed_date_holder.getMonth() + 1) + '-' + this.completed_date_holder.getDate();

    this.work_instruction.completed_at = completed_date.toString();

    this._work_instruction_service.close(this.work_instruction.id, full_note).subscribe(instruction => {
      if(this.work_instruction.work_instruction_stage_id === WorkInstructionStages.ENQUIRY){
        (<any>window).gtag('event', 'Closed' , {event_category: 'Enquiry', event_label: this.formGroup.get('note').value});
        this._toastr_service.success('ENQUIRY CLOSED', 'Success');
      }else{
        (<any>window).gtag('event', 'Closed' , {event_category: 'Quote', event_label: this.formGroup.get('note').value});
        this._toastr_service.success('QUOTE CLOSED', 'Success');
      }

      this.refreshTable.emit(true);
      this.hide();
    });
  }
}
