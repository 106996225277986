import {Deserializable} from '../deserializable.model';
import {Batch} from './batch.model';
import {WorkInstruction} from './work-instruction.model';
import {Team} from '../teams/team.model';
import {CycleWeek} from './cycle-week.model';

export class Cycle implements Deserializable {

  id: number;
  batch_id: number;
  start_date: string;
  end_date: string;
  name: string;
  published: string;
  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;
  completed: boolean;
  routed: boolean;
  issued: boolean;

  batch: Batch;

  week_count: number;
  completed_jobs: WorkInstruction[];
  issued_jobs: WorkInstruction[];
  pending_jobs: WorkInstruction[];
  unable_jobs: WorkInstruction[];
  cancelled_jobs: WorkInstruction[];
  progress: number;

  teams: Team[];

  cycle_weeks: CycleWeek[];

  chart_data: {name: string, value: number}[];
  donut_data: {name: string, value: number}[];

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id = 0;
    this.name = '';
    this.start_date = '';
    this.end_date = '';
    this.created_at = '';
    this.updated_at = '';
    this.deactivated_at = '';
    this.deleted_at = '';
    this.completed = false;
    this.routed = false;

    this.completed_jobs = [];
    this.issued_jobs = [];
    this.pending_jobs = [];
    this.unable_jobs = [];
    this.cancelled_jobs = [];

    this.chart_data = [];
    this.donut_data = [];

    this.teams = [];
  }
}
