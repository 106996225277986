import {Deserializable} from '../deserializable.model';

export class RequirementType implements Deserializable {

  id: number;
  name: string;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id = 0;
    this.name = '';

    this.created_at = '';
    this.updated_at = '';
    this.deleted_at = '';
  }
}
