import {environment} from '../../../../../../environments/environment';
import {AuthService} from '../../../../../shared/users/auth.service';
import {RecurringTemplate} from '../../../../../shared/models/recurring/recurring-template.model';
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class RecurringTemplateService {

  constructor (private httpClient: HttpClient) {}

  /******************************************* GET METHODS *****************************************/
  getRecurringTemplateDatatable (client_account_id: number, contract_id: number, department_id: number, team_id:number, is_high_risk:any, cancelled: any, from:number, page_limit:number, search_term:string) {

    client_account_id = !client_account_id ? 0 : client_account_id;
    contract_id       = !contract_id ? 0 : contract_id;
    department_id     = !department_id ? 0 : department_id;
    team_id           = !team_id ? 0 : team_id;
    search_term       = !search_term ? 'null': search_term;

    const httpOptions = {
      params: {
        'auth_token':         AuthService.getAuthToken(),
        'client_account_id':  client_account_id.toString(),
        'contract_id':        contract_id.toString(),
        'department_id':      department_id.toString(),
        'team_id':            team_id.toString(),
        'is_high_risk':       is_high_risk,
        'cancelled':          cancelled,
        'from':               from.toString(),
        'page_limit':         page_limit.toString(),
        'search_term':        search_term,
      }
    };

    return this.httpClient.get<RecurringTemplate[]>(environment.base_url + '/recurring-templates/get-recurring-templates', httpOptions)
      .map((instructions: RecurringTemplate[]) => instructions.map((instruction) => new RecurringTemplate().deserialize(instruction)));
  }

  getRecurringTemplate (id: number) {
    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
        'id': id.toString()
      }
    };

    return this.httpClient.get<RecurringTemplate>(environment.base_url + '/recurring-templates/get-recurring-template', httpOptions)
      .map((instruction: RecurringTemplate) => new RecurringTemplate().deserialize(instruction));
  }

  generatePreview(start_date:string, end_date:string, recurring_type_id:number, time_range_id:number, time_range_value:number, raise_before: number, specific_dates: string[], day_of_month:number){
    const object = {
      start_date: start_date,
      end_date: end_date,
      recurring_type_id: recurring_type_id,
      time_range_id: time_range_id,
      time_range_value: time_range_value,
      raise_before: raise_before,
      specific_dates: specific_dates,
      day_of_month: day_of_month
    };

    return this.httpClient.post<{due_date:string, raise_date:string}[]>(environment.base_url + '/recurring-templates/generate-preview', object, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  generateWorkInstructionsFromTemplates(){
    return this.httpClient.get(environment.base_url + '/recurring-templates/generate-work-instructions-from-templates', {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /******************************************* POST METHODS *****************************************/
  create (data: RecurringTemplate) {
    const formData = new FormData();

    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(environment.base_url + '/recurring-templates/create-recurring-template', formData, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  update (id: number, data: RecurringTemplate) {
    const formData = new FormData();


    formData.append('data', JSON.stringify(data));

    return this.httpClient.post(environment.base_url + '/recurring-template/update-recurring-template', formData, {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  close(id: number, note: string){

    const httpOptions = {
      params: {
        'auth_token': AuthService.getAuthToken(),
      }
    };
    return this.httpClient.post(environment.base_url + '/recurring-template/close', {id: id.toString(), note: note}, httpOptions);
  }

  cancelTemplate(id: number){
    const httpOptions = {
      params: {
        'id': id.toString(),
        'auth_token': AuthService.getAuthToken(),
      }
    };

    return this.httpClient.get(environment.base_url + '/recurring-templates/cancel-template', httpOptions);
  }

  cancelDate(id: number){
    const httpOptions = {
      params: {
        'id': id.toString(),
        'auth_token': AuthService.getAuthToken(),
      }
    };

    return this.httpClient.get(environment.base_url + '/recurring-dates/cancel-date', httpOptions);
  }

  /******************************************** ARCHIVE METHODS ********************************************/
  archive (ids: object) {
    return this.httpClient.post(environment.base_url + '/recurring-template/archive', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  activate (ids: object) {
    return this.httpClient.post(environment.base_url + '/recurring-template/activate', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }


}
