import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Invoice } from '../../../../shared/models/billing/invoice.model';
import { AuthService } from '../../../../shared/users/auth.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class InvoiceService {

  constructor (private http: HttpClient, private auth_service: AuthService) {}

  getInvoicesDatatable (billing_company_id: number, client_account_id: number, contract_id: number, is_client:boolean) {

    billing_company_id = !billing_company_id ? 0 : billing_company_id;
    client_account_id  = !client_account_id ? 0 : client_account_id;
    contract_id        = !contract_id ? 0 : contract_id;

    const httpOptions = {
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'billing_company_id': billing_company_id.toString(),
        'client_account_id': client_account_id.toString(),
        'contract_id': contract_id.toString(),
        'is_client':is_client.toString()
      }
    };

    return this.http.get<Invoice[]>(environment.base_url + '/invoices', httpOptions)
      .map((invoices: Invoice[]) => invoices.map((invoice) => new Invoice().deserialize(invoice)));
  }

  getInvoiceDocument (id: number) {

    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/pdf'
        }
      ),
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'id': id.toString()
      },
      responseType: 'blob' as 'text'
    };

    return this.http.get(environment.base_url + '/invoices/get-document', httpOptions);
  }

  getSageExportDocument () {

    const headers = new HttpHeaders();
    headers.set('Accept', 'text/xlsx');
    headers.set('Content-type', 'text/xlsx');
    const params = {
      'auth_token': AuthService.getAuthToken()
    };

    return this.http.get(environment.base_url + '/invoices/sage-export', {
      responseType: 'blob',
      params: params,
      headers: headers
    });
  }

  consolidateInvoices(from_date: string, to_date: string){
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/pdf'
        }
      ),
      params: {
        'auth_token': this.auth_service.getAuthToken(),
        'from_date': from_date,
        'to_date': to_date,
      },
      responseType: 'blob' as 'text'
    };

    return this.http.get(environment.base_url + '/invoices/consolidate-invoices', httpOptions);
  }
}
