import {Component} from '@angular/core';

@Component({
  selector: 'app-number-card-chart',
  templateUrl: './number-card-chart.component.html',
  styleUrls: ['./number-card-chart.component.scss']
})
export class NumberCardChartComponent{

  data = [
    {
      name: "Germany",
      value: 8940000
    },
    {
      name: "USA",
      value: 5000000
    },
    {
      name: "Germany",
      value: 8940000
    },
    {
      name: "USA",
      value: 5000000
    }
  ];

  constructor(){

  }



}
