import {Deserializable} from '../deserializable.model';
import {Client} from '../../../pages/admin/api/interfaces/clients.interface';
import {SiteNote} from './site-note.model';

export class Site implements Deserializable {

  id: number;
  client_account_id: number;
  name: string;
  address_1: string;
  address_2: string;
  address_3: string;
  address_4: string;
  postcode: string;
  latitude: number;
  longitude: number;
  tenant_name: string;
  tenant_contact_number: string;
  tenant_contact_email: string;

  created_at: string;
  updated_at: string;
  deactivated_at: string;
  deleted_at: string;

  client_account: Client;
  site_notes: SiteNote[];

  //when creating a new site with note
  note: string;

  //work_instructions: WorkInstruction[];


  deserialize(input: any){
    Object.assign(this, input);
    return this;
  }

  constructor(){
    this.id = 0;
    this.name = '';
    this.address_1 = '';
    this.address_2 = '';
    this.address_3 = '';
    this.address_4 = '';
    this.postcode = '';
    this.latitude = null;
    this.longitude = null;
    this.tenant_name = '';
    this.tenant_contact_email = '';
    this.tenant_contact_number = null;

    this.created_at = '';
    this.updated_at = '';
    this.deactivated_at = '';
    this.deleted_at = '';

    this.note = '';

    // this.client_account = new ClientAccount();
    // this.site_notes = [];
  }
}
