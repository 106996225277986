import { Deserializable } from '../deserializable.model';

export class ImportType implements Deserializable {

  public static readonly LOCATION = 1;

  id: number;
  name: string;
  slug: string;

  deserialize(input: any){
    Object.assign(this,input);
    return this;
  }
}
