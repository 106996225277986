import { Deserializable } from '../deserializable.model';
import { User } from '../user/user.model';
import { Document } from './document.model';
import { ImportType } from './import-type.model';

export class Import implements Deserializable {

  id: number;
  filename: string;

  imported_by_user: User;
  documents: Document[];
  import_type: ImportType;

  deserialize(input:any){
    Object.assign(this, input);
    return this;
  }

  /**
   *
   * @returns {User}
   */
  get importedByUser(): User {
    return new User().deserialize(this.imported_by_user);
  }

  /**
   *
   * @returns {ImportType}
   */
  get importType(): ImportType {
    return new ImportType().deserialize(this.import_type);
  }

  /**
   *
   * @returns {Document[]}
   */
  get getDocuments(): Document[]{
    return this.documents.map((document: Document) => new Document().deserialize(document));
  }
}
