import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {WorkInstruction} from "../../../../../shared/models/work-instruction/work-instruction.model";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RecurringTemplate} from "../../../../../shared/models/recurring/recurring-template.model";
import {RecurringDate} from "../../../../../shared/models/work-instruction/recurring_date.model";

@Component({
  selector: 'app-date-creator',
  templateUrl: './date-creator.component.html',
  styleUrls: ['./date-creator.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})

export class DateCreatorComponent implements OnInit {

  public scrollbarOptions = {axis: 'y', theme: 'minimal-dark'};

  @Input() work_instruction: WorkInstruction;
  @Input() recurring_template: RecurringTemplate;

  @Input() due_date: string;

  date_form: FormGroup;
  date_form_array: FormArray;

  dates = <RecurringDate[]>[];

  constructor(
    private fb: FormBuilder,){

    this.setForm()
  }

  ngOnInit() {
    // this.dates = this.work_instruction.dates;
    this.dates = this.recurring_template.recurring_dates
  }

  // +++++++++++++++++ SET DATE ++++++++++++++++++++ //

  setForm(){
    this.date_form_array = new FormArray([]);

    this.date_form = this.fb.group({
      date_form_array: this.fb.array([])
    });
  }

  // ++++++++++++++++ ADD DATE +++++++++++++++++ //

  addDate(){
    // const date = new Date ();

    const date = new RecurringDate();
    this.date_form_array = this.date_form.get('date_form_array') as FormArray;
    this.date_form_array.push(this.createDateFormGroup(date));

    this.dates.push(date);
  }


  // +++++++++++++++++++++++++++++++++ CREATE METHODS +++++++++++++++++++++++++++++ //

  createDateFormGroup(date: RecurringDate){
    const group = this.fb.group({
      'due date': [date.due_date],
      'raise_on_date': [date.raise_on_date],
    });

    {
      group.addControl('due_date_holder', new FormControl(date.due_date_holder, Validators.required));
    }

    group.valueChanges.subscribe( values => {
      date.deserialize(values);
    });

    return group;
  }

  removeDate(index: number) {
    this.dates.splice(index, 1);
    this.date_form_array.removeAt(index);
  }



  reset(){
    this.dates = [];
    this.setForm();
  }
}
