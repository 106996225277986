import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from '../../../../../environments/environment';
import { TaskCategory } from '../../../../shared/models/work-instruction/task-category.model';
import { AuthService } from '../../../../shared/users/auth.service';

@Injectable()
export class CategoryService {

  /**
   * @type {BehaviorSubject<TaskCategory>}
   */
  private _category = new BehaviorSubject<TaskCategory>(new TaskCategory());

  /**
   * @type {Observable<TaskCategory>}
   */
  public _category$ = this._category.asObservable();

  /**
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient){}

  /* +++++++++++++++++++++++++++++++ GET METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @returns {Observable<TaskCategory[]>}
   */
  getCategoriesDatatable(){
    return this.httpClient.get<TaskCategory[]>(environment.base_url + '/categories/categories-datatable', {params: {'auth_token': AuthService.getAuthToken()}})
      .map((categories: TaskCategory[]) => categories.map((category) => new TaskCategory().deserialize(category)));
  }

  /**
   *
   * @param {number} department_id
   * @returns {Observable<TaskCategory[]>}
   */
  getCategoryList(@Optional() department_id: number = 0){
    return this.httpClient.get<TaskCategory[]>(environment.base_url + '/categories/get-category-list', {params: {'auth_token': AuthService.getAuthToken(), 'department_id': department_id.toString()}})
      .map((categories: TaskCategory[]) => categories.map((category) => new TaskCategory().deserialize(category)));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<TaskCategory>}
   */
  getCategory(id: number){
    return this.httpClient.get<TaskCategory>(environment.base_url + '/categories/get-category', {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ POST METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} data
   * @returns {Observable<Object>}
   */
  create(data: object){
    return this.httpClient.post(environment.base_url + '/categories/create-category', data, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {number} id
   * @param {object} data
   * @returns {Observable<Object>}
   */
  update(id: number, data: object){
    return this.httpClient.post(environment.base_url + '/categories/update-category', data, {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}});
  }

  /* +++++++++++++++++++++++++++++++ ARCHIVE METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  archive (ids: object) {
    return this.httpClient.post(environment.base_url + '/categories/archive', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {object} ids
   * @returns {Observable<Object>}
   */
  activate (ids: object) {
    return this.httpClient.post(environment.base_url + '/categories/activate', ids, {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /* +++++++++++++++++++++++++++++++ MISC METHODS ++++++++++++++++++++++++++++++++ */

  /**
   *
   * @param {TaskCategory} category
   */
  setCategory(category: TaskCategory){
    if (category === null){
      category = new TaskCategory();
    }

    this._category.next(category);
  }

  /**
   *
   * @param {number[]} department_ids
   * @param {number[]} category_ids
   * @returns {Observable<Object>}
   */
  assignCategories(department_ids: number[], category_ids: number[]){
    return this.httpClient.post(
      environment.base_url + '/categories/assign-categories',
      {department_ids: department_ids, task_category_ids: category_ids},
      {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {number[]} department_ids
   * @param {number[]} category_ids
   * @returns {Observable<Object>}
   */
  deassignCategories(department_ids: number[], category_ids: number[]){
    return this.httpClient.post(
      environment.base_url + '/categories/deassign-categories',
      {department_ids: department_ids, task_category_ids: category_ids},
      {params: {'auth_token': AuthService.getAuthToken()}});
  }

  /**
   *
   * @param {string} field
   * @param {string} value
   * @param {number} id
   * @returns {Observable<Object>}
   */
  checkForDuplicate (field: string, value: string, id: number) {

    return this.httpClient.post(

      environment.base_url + '/categories/check-for-duplicate',

      {field: field, value: value},

      {params: {'auth_token': AuthService.getAuthToken(), 'id': id.toString()}}
    );
  }
}
